import { useRef } from "react";

import { dietPercentageToString } from "../../../../helpers/diet";
import TableTooltip from "../../../TableTooltip";

const DietTooltip = ({ diet, children }) => {
  const days = diet.number_of_days;

  return (
    <span className="progress__diet-tooltip">
      {days} {days === 1 ? "day" : "days"} {children}
    </span>
  );
};

const DietCalories = ({ diet }) => {
  const { calories } = diet;

  return <DietTooltip diet={diet}>{calories ?? "0"} cals</DietTooltip>;
};

const DietPercentage = ({ diet }) => {
  const { percentage } = diet;

  return (
    <DietTooltip diet={diet}>{dietPercentageToString(percentage)}</DietTooltip>
  );
};

const DoubleDietTooltips = ({ firstDiet, secondDiet, avg }) => {
  const caloriesTooltipRef = useRef();
  const precentageTooltipRef = useRef();
  return (
    <>
      <tr key={1} className="progress__tr">
        <td>
          <TableTooltip
            ref={caloriesTooltipRef}
            overlay={
              <span className="progress__diet-tooltip-wrapper">
                <DietCalories diet={firstDiet} />
                <br />
                <DietCalories diet={secondDiet} />
              </span>
            }
            content={`x̅ ${avg.calories_average ?? "0"}`}
          />
        </td>
      </tr>
      <tr key={2} className="progress__tr">
        <td>
          <TableTooltip
            ref={precentageTooltipRef}
            overlay={
              <span className="progress__diet-tooltip-wrapper">
                <DietPercentage diet={firstDiet} />
                <br />
                <DietPercentage diet={secondDiet} />
              </span>
            }
            content={`x̅ ${dietPercentageToString(avg.percentage_average)}`}
          />
        </td>
      </tr>
    </>
  );
};

export default DoubleDietTooltips;
