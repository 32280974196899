import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { Formik, Form, Field } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import "./PerformanceWidget.scss";
import { ReactComponent as NextPageArrow } from "../../../../../assets/svg/next-page-arrow.svg";
import { ReactComponent as PrevPageArrow } from "../../../../../assets/svg/prev-page-arrow.svg";
import Button from "../../../../../components/Button";
import Widget from "../../../../../components/widget/Widget";
import {
  MAX_MOBILE_SCREEN_WIDTH,
  UTC_FORMAT_TO_BACKEND,
} from "../../../../../constants/common";
import { coefficients } from "../../../../../constants/repCoefficients";
import {
  MAX_PB_COEFFICIENT,
  MIN_PB_COEFFICIENT,
  PB_DEFAULT_VALUE,
} from "../../../../../constants/training";
import { BasicHelper } from "../../../../../helpers";
import {
  stepCalculate,
  calculateNearestMultipleOfNumber,
} from "../../../../../helpers/training";
import useIsSmallScreen from "../../../../../hooks/useIsSmallScreen";
import { useNumbersOnlyField } from "../../../../../hooks/useNumbersOnlyField";
import {
  updateSet,
  setNextSet,
  setPrevSet,
  clearPreselectedValues,
  clearError,
} from "../../../../../redux/programsSlice";
import { setModal } from "../../../../../redux/utilsSlice";

import Drums from "./components/Drums";
import { useToastr } from "../../../../../hooks/useToastr";
import { TOASTR_TYPE } from "../../../../../constants/toastr";

const PerformanceWidget = ({ onHistoryButtonClick, hideWidget }) => {
  const dispatch = useDispatch();
  const isMobileVersion = useIsSmallScreen(
    MAX_MOBILE_SCREEN_WIDTH.MOBILE_VIEW_ON_TRAINING
  );

  const currentSet = useSelector((state) => state.programs.currentSet);
  const isFirstSet = useSelector((state) => state.programs.isFirstSet);
  const isLastSet = useSelector((state) => state.programs.isLastSet);
  const currentProgramIdx = useSelector(
    (state) => state.programs.currentProgramIdx
  );
  const numberOfPrograms = useSelector(
    (state) => state.programs.numberOfPrograms
  );
  const isSavingSet = useSelector((state) => state.programs.isSavingSet);
  const client = useSelector((state) => state.client.client);
  const loadingHistory = useSelector((state) => state.programs.loadingHistory);
  const error = useSelector((state) => state.programs.error);

  useToastr({
    messages: error,
    deps: error,
    type: TOASTR_TYPE.ERROR,
    cb: () => {
      dispatch(clearError());
    },
  });

  const needWaitHistory =
    loadingHistory && currentSet?.set?.number > 1 && !currentSet?.isCompleted;

  const match = useRouteMatch();

  const [drumsWeights, setDrumsWeights] = useState([]);
  const [drumsReps, setDrumsReps] = useState([]);
  const [isDrums, setIsDrums] = useState(false);

  const [otherReps, handleOtherRepsChange] = useNumbersOnlyField(0);

  const loadPrevPage = () => {
    if (!isFirstSet) dispatch(setPrevSet());
  };
  const loadNextPage = () => {
    if (!isLastSet) dispatch(setNextSet());
  };

  useEffect(() => {
    if (!currentSet) {
      return;
    }

    const { pb, repCalculator, other_reps } = currentSet;
    const { weight } = currentSet.values;
    const { weight: weightPercent } = currentSet.set;

    const minPb = (pb || PB_DEFAULT_VALUE) * MIN_PB_COEFFICIENT;
    const maxPb = (pb || PB_DEFAULT_VALUE) * MAX_PB_COEFFICIENT;

    const minWeightCondition = (weightPercent, weight, minPb) => {
      if (weight === 0 || minPb < weight) return minPb;
      else return weight;
    };
    const maxWeightCondition = (weightPercent, weight, maxPb) => {
      if (maxPb > weight) return maxPb;
      else return weight;
    };

    const minWeight = minWeightCondition(weightPercent, weight, minPb);
    const maxWeight = maxWeightCondition(weightPercent, weight, maxPb);

    const step = stepCalculate(pb);

    const newDrumsWeights = [];
    const newDrumsReps = [0];

    for (
      let i = calculateNearestMultipleOfNumber(minWeight, step);
      i <= maxWeight;
      i += step
    ) {
      newDrumsWeights.push(i);
    }

    for (let i = 1; i <= coefficients[repCalculator].length; i++) {
      newDrumsReps.push(i);
    }

    setDrumsWeights(newDrumsWeights);
    setDrumsReps(newDrumsReps);
    setIsDrums(true);
    handleOtherRepsChange(other_reps ?? 0);
  }, [currentSet]);

  const handleSubmit = (values, { resetForm }) => {
    const { day, workoutIndex, exerciseIndex, pb, pbIsCalculated, set } =
      currentSet;

    const { notes, otherRepsNotes, weight, reps, calculatedMax } = values;

    const isNewPb = calculatedMax > pb;

    const data = {
      indexes: {
        program: currentProgramIdx,
        day: day - 1,
        workout: workoutIndex - 1,
        exercise: exerciseIndex - 1,
        set: set.number - 1,
      },
      set: {
        weight,
        reps,
        notes,
        other_reps: otherReps,
        other_reps_notes: otherRepsNotes,
        is_completed: true,
        has_new_pb: isNewPb,
        date: moment().utc().format(UTC_FORMAT_TO_BACKEND),
      },
      calculated_max: calculatedMax,
    };

    const params = {
      id: match.params.id,
      body: data,
      isLast: currentProgramIdx + 1 === numberOfPrograms,
    };
    dispatch(updateSet(params))
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          resetForm();
          dispatch(setNextSet());

          if (pbIsCalculated) {
            dispatch(
              setModal(
                <>
                  <div className="training-page__modal-title">
                    Thanks, {client.first_name}
                  </div>
                  {calculatedMax} {client.massUnit} is your starting pb for "
                  {currentSet.exerciseName}". The weights that your program
                  recommends are now set to your strength level.
                </>
              )
            );
            return;
          }

          if (isNewPb) {
            dispatch(
              setModal(
                <>
                  <div className="training-page__modal-title">
                    Congratulations!
                  </div>
                  You just beat your personal best in "{currentSet.exerciseName}
                  " by {BasicHelper.roundTo(calculatedMax - pb, 1)}{" "}
                  {client.massUnit}! {calculatedMax} {client.massUnit} is your
                  new pb.
                </>
              )
            );
          }
        }
      })
      .finally(() => {
        if (isMobileVersion) dispatch(clearPreselectedValues());
      });
  };

  return (
    <Widget>
      {!isMobileVersion && (
        <Widget.Header>
          <Widget.Title>performance</Widget.Title>
          {currentSet && (
            <div className="performance-widget__arrow-wrapper">
              <PrevPageArrow
                width="40px"
                heigth="40px"
                className={cn("body-arrow", {
                  "body-arrow--disabled": isFirstSet || isSavingSet,
                })}
                onClick={loadPrevPage}
              />
              <NextPageArrow
                width="40px"
                heigth="40px"
                className={cn("body-arrow", {
                  "body-arrow--disabled": isLastSet || isSavingSet,
                })}
                onClick={loadNextPage}
              />
            </div>
          )}
        </Widget.Header>
      )}
      {currentSet ? (
        <div
          className={cn({
            "widget-body performance-widget__body": isMobileVersion,
          })}
        >
          <div className="performance-widget__subtitle">
            <span>{currentSet?.exerciseName?.toLowerCase()}</span>
            <div>
              <span>{`day ${currentSet?.day}: `}</span>
              <span>{`set ${currentSet?.set?.number}`}</span>
              {currentSet?.isCompleted && (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="performance-widget__check-icon"
                />
              )}
            </div>
            {isMobileVersion && (
              <Button className="font-size-small" onClick={hideWidget}>
                back
              </Button>
            )}
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              notes: currentSet?.notes || "",
              otherRepsNotes: currentSet?.other_reps_notes || "",
            }}
            onSubmit={handleSubmit}
          >
            <Form>
              <Widget.Body>
                {isDrums && (
                  <Drums
                    data={currentSet}
                    weights={drumsWeights}
                    reps={drumsReps}
                  />
                )}
              </Widget.Body>
              <div className="performance-widget__notes">
                <Field
                  name="notes"
                  className="notes-input"
                  placeholder="notes..."
                />
                <div className="performance-widget__notes-row">
                  <Field
                    name="otherReps"
                    className="notes-input notes-row__short-item"
                    placeholder="other reps"
                    value={otherReps}
                    onChange={(event) =>
                      handleOtherRepsChange(event.target.value)
                    }
                  />
                  <Field
                    name="otherRepsNotes"
                    className="notes-input"
                    placeholder="other reps notes..."
                  />
                </div>
              </div>
              <div className="performance-widget__button-wrapper">
                {isMobileVersion && (
                  <Button onClick={onHistoryButtonClick} type="button">
                    history
                  </Button>
                )}
                <Button disabled={isSavingSet || needWaitHistory} type="submit">
                  save
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      ) : (
        "there should be at least one set in the program to show this"
      )}
    </Widget>
  );
};

export default PerformanceWidget;
