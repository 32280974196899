import moment from "moment";
import React from "react";

import Button from "../../../../../../../components/Button";
import Widget from "../../../../../../../components/widget/Widget";
import { USER_TYPES } from "../../../../../../../constants/common";
import { getForDaysLabel } from "../../../../../../../helpers/diet";
import useIsUserType from "../../../../../../../hooks/useIsUserType";
import Food from "../../../DietMealsSideBar/Food";
import FoodTotal from "../../../FoodTotal";

const DietHistoryItem = ({ diets, start, isDoubleDiet, onRestore }) => {
  const isClient = useIsUserType(USER_TYPES.CLIENT);

  const preparedDiets = isDoubleDiet
    ? [
        { id: "1", ...diets },
        { id: "2", ...diets.second_diet },
      ]
    : [{ id: "1", ...diets }];

  return (
    <Widget.Body className="diet__history-body">
      <table className="diet-table">
        <thead>
          <tr className="diet-table__row diet-table__row--white">
            <td colSpan="5">diet from {moment(start).format("ll")}</td>
          </tr>
        </thead>
        <tbody>
          {preparedDiets.map((diet, index) => (
            <React.Fragment key={diet.id}>
              <tr className="diet-table__row diet-table__row--white diet-table__row--subheader">
                {isDoubleDiet && (
                  <td colSpan="2" className="white_tr diet-history-subheader">
                    diet {index + 1}:{" "}
                    {getForDaysLabel(
                      diet.for_days,
                      [diets, diets.second_diet],
                      index
                    )}
                  </td>
                )}
                {!isDoubleDiet && (
                  <>
                    <td>qty</td>
                    <td>food</td>
                  </>
                )}
                <td>p</td>
                <td>c</td>
                <td>f</td>
              </tr>
              {diet.foods.map((item) => (
                <Food
                  key={item.food.id}
                  food={item.food}
                  quantity={item.quantity}
                />
              ))}
              <FoodTotal foods={diet.foods} isHistory />
            </React.Fragment>
          ))}
          <tr>
            <td>
              <Button
                style={{
                  float: "left",
                  visibility: isClient ? "hidden" : null,
                }}
                onClick={onRestore}
              >
                restore
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </Widget.Body>
  );
};

DietHistoryItem.propTypes = {};

export default DietHistoryItem;
