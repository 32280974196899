import { CACHE_TIMEOUT } from "../constants/api";
import { sessionId } from "../constants/errorLocation";
import { baseUrl } from "../env/env";
import withCache from "../helpers/cache";
import { setErrorStatusCode } from "../redux/companiesSlice";

import myFetch, { emailHandlerError } from "./myFetch";

export const loadCompaniesPicker = () => {
  return myFetch(`${baseUrl}/api/companies/picker`);
};

const loadCompaniesForActivityWidget = ({ page = null, search = null }) => {
  const pageProp = page ? `page=${page}` : "";
  const searchProp = search ? `search=${search}` : "";

  const props = `${pageProp ? pageProp : ""}${
    searchProp && pageProp ? "&" + searchProp : searchProp ? searchProp : ""
  }`;

  return myFetch(`${baseUrl}/api/companies/page_list${props && "?" + props}`);
};

export const loadCompaniesForActivityWidgetWithCash = withCache(
  loadCompaniesForActivityWidget,
  {
    invalidateTimeout: CACHE_TIMEOUT,
  }
);

export const loadCompaniesPickerWithCache = withCache(loadCompaniesPicker, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const loadCompanies = () => {
  return myFetch(`${baseUrl}/api/companies`);
};

export const loadCompaniesWithCache = withCache(loadCompanies, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const addCompany = ({ body }) => {
  loadCompaniesPickerWithCache.invalidate();
  loadCompaniesWithCache.invalidate();
  loadCompaniesForActivityWidgetWithCash.invalidate();
  return myFetch(
    `${baseUrl}/api/companies`,
    { method: "POST", body: body },
    emailHandlerError
  );
};

export const loadCompanyById = ({ id }) => {
  return myFetch(`${baseUrl}/api/companies/${id}`);
};

export const loadCompanyByIdWithCache = withCache(loadCompanyById, {
  invalidateTimeout: CACHE_TIMEOUT,
});

export const deleteCompanyById = ({ id }) => {
  loadCompanyByIdWithCache.invalidateByArgs({ id });
  loadCompaniesPickerWithCache.invalidate();
  loadCompaniesWithCache.invalidate();
  return myFetch(`${baseUrl}/api/companies/${id}`, { method: "DELETE" });
};

export const editCompany = ({ id, body }) => {
  loadCompanyByIdWithCache.invalidateByArgs({ id });
  loadCompaniesPickerWithCache.invalidate();
  loadCompaniesWithCache.invalidate();
  return myFetch(
    `${baseUrl}/api/companies/${id}`,
    {
      method: "PATCH",
      body: body,
    },
    emailHandlerError
  );
};

export const addThemeCompany = ({ id, file }) => {
  loadCompanyByIdWithCache.invalidateByArgs({ id });
  const formData = new FormData();
  formData.append("file", file);
  return myFetch(`${baseUrl}/api/companies/${id}/upload_image`, {
    method: "POST",
    body: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deleteThemeCompany = ({ id }) => {
  loadCompanyByIdWithCache.invalidateByArgs({ id });
  return myFetch(`${baseUrl}/api/companies/${id}/delete_image`, {
    method: "DELETE",
  });
};

export const createPaymentAccount = ({ id, body }) => {
  return myFetch(`${baseUrl}/api/companies/${id}/payment_account`, {
    method: "POST",
    body,
  });
};

export const createStripePaymentAccount = ({ id, body }) => {
  return myFetch(`${baseUrl}/api/companies/${id}/stripe_payment_account`, {
    method: "POST",
    body,
  });
};

export const loadPaymentAccount = ({ id }) => {
  return myFetch(`${baseUrl}/api/companies/${id}/payment_account`);
};

export const loadStripePaymentAccount = ({ id }) => {
  return myFetch(`${baseUrl}/api/companies/${id}/stripe_payment_account`);
};

export const payCurrentDue = ({ id, debt }) => {
  return myFetch(`${baseUrl}/api/companies/${id}/pay_company_debt`, {
    method: "POST",
    body: {
      debt,
    },
  });
};

export const deletePaymentAccount = ({ id, forceDelete }) => {
  return myFetch(
    `${baseUrl}/api/companies/${id}/payment_account`,
    {
      method: "DELETE",
    },
    null,
    null,
    forceDelete
  );
};

export const deleteStripePaymentAccount = ({ id, forceDelete }) => {
  return myFetch(
    `${baseUrl}/api/companies/${id}/payment_account`,
    {
      method: "DELETE",
    },
    null,
    null,
    forceDelete
  );
};

export const verifyPaymentAccount = ({ id }) => {
  return myFetch(`${baseUrl}/api/companies/${id}/payment_account_verify`, {
    method: "POST",
    body: {},
  });
};

export const verifyStripePaymentAccount = ({ id }) => {
  return myFetch(
    `${baseUrl}/api/companies/${id}/stripe_payment_account_verify`,
    {
      method: "POST",
      body: {},
    }
  );
};

export const verifyStripeIntent = ({
  id,
  payment_intent_id,
  payment_intent_status,
}) => {
  return myFetch(
    `${baseUrl}/api/companies/${id}/stripe_payment_account_verify_intent`,
    {
      method: "POST",
      body: {
        payment_intent_id,
        payment_intent_status,
      },
    }
  );
};

export const checkSessionId = async (
  { id, cko_session_id },
  { rejectWithValue, dispatch }
) => {
  try {
    const response = await myFetch(
      `${baseUrl}/api/companies/${id}/payment_account_verify_session`,
      {
        method: "POST",
        body: {
          cko_session_id,
        },
      },
      null,
      sessionId
    );

    if (response.throwWithStatusCode) {
      throw new Error(response.message);
    }
    return response;
  } catch (error) {
    dispatch(setErrorStatusCode(400));
    return rejectWithValue(error.message);
  }
};
