export const HEADER_FOOD_TABLE = [
  {
    label: "",
    style: { width: "3%" },
  },
  {
    label: "food name",
    style: { width: "39%" },
  },
  {
    label: "unit",
    style: { width: "9%" },
  },
  {
    label: "protein",
    style: { width: "9%" },
  },
  {
    label: "carb",
    style: { width: "9%" },
  },
  {
    label: "fat",
    style: { width: "9%" },
  },
  {
    label: "calories",
    style: { width: "10%" },
  },
  {
    label: "owner",
    style: { width: "12%" },
  },
];

export const HEADER_FOOD_TABLE_MOBILE = [
  {
    label: "",
    style: { width: "3%" },
  },
  {
    label: "food name",
    style: { width: "33%" },
  },
  {
    label: "unit",
    style: { width: "10%" },
  },
  {
    label: "protein",
    style: { width: "10%" },
  },
  {
    label: "carb",
    style: { width: "10%" },
  },
  {
    label: "fat",
    style: { width: "10%" },
  },
  {
    label: "calories",
    style: { width: "11%" },
  },
  {
    label: "owner",
    style: { width: "13%" },
  },
];

export const PROTEINS_COEFFICIENT = 4;

export const CARBS_COEFFICIENT = 4;

export const FATS_COEFFICIENT = 9;

export const CUSTOM_OPTIONS = "custom";

export const FOODS_PREVIEW_COUNT = 20;

export const OPTIONS_LIST = [
  { value: "", label: "" },
  { value: "grams", label: "grams" },
  { value: "mls", label: "mls" },
  { value: "whole", label: "whole" },
  { value: "serves", label: "serves" },
  { value: "pieces", label: "pieces" },
  { value: CUSTOM_OPTIONS, label: CUSTOM_OPTIONS },
];

export const FOODS_SIDEBAR_NAMES = {
  FOOD_EDIT: "edit food",
  FOOD_ADD: "add food",
  FOOD_DELETE: "delete food",
};

export const FOOD_TYPES = {
  GLOBAL: "global",
};
