import React, { memo } from "react";

import useIsSmallScreen from "../../../../../../../hooks/useIsSmallScreen";

const DietTableTotalRows = ({ totalCalories, foodEnergy, isShowMeal }) => {
  const isSmallScreen = useIsSmallScreen();
  return (
    <>
      <td className="diet-table__td diet-table__td--noborder diet-table__td__total-rows">
        {totalCalories.toFixed(0)} calories
      </td>
      {(!isSmallScreen || !isShowMeal) && (
        <>
          <td className="diet-table__td diet-table__td__total-rows">
            {foodEnergy?.proteins.toFixed(0)} g
          </td>
          <td className="diet-table__td diet-table__td__total-rows">
            {foodEnergy?.carbs.toFixed(0)} g
          </td>
          <td className="diet-table__td diet-table__td__total-rows">
            {foodEnergy?.fats.toFixed(0)} g
          </td>
        </>
      )}
    </>
  );
};

export default memo(DietTableTotalRows);
