import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import authSlice, { logoutClient } from "./authSlice";
import withCleanup from "./cleanup";
import clientListSlice from "./clientListSlice";
import clientSlice from "./clientSlice";
import companiesSlice from "./companiesSlice";
import dietPageSlice from "./dietPageSlice";
import dietTemplatesSlice from "./dietTemplatesSlice";
import dietsSlice from "./dietsSlice";
import exercisesSlice from "./exercisesSlice";
import foodsSlice from "./foodsSlice";
import paymentSlice from "./paymentSlice";
import programTemplatesSlice from "./programTemplatesSlice";
import programsSlice from "./programsSlice";
import restorePasswordSlice from "./restorePasswordSlice";
import toastrSlice from "./toastrSlice";
import trainersSlice from "./trainersSlice";
import userSlice from "./userSlice";
import utilsSlice from "./utilsSlice";
import windowSlice from "./windowSlice";
import * as Sentry from "@sentry/react";
import { REDIRECT_URL } from "../env/env";
import { ACTION_TYPES, USER_REDIRECTED_MSG } from "../constants/auth";

const lastAction = (_ = null, action) => {
  return action;
};

const appReducer = combineReducers({
  lastAction,
  clientList: clientListSlice,
  toastr: toastrSlice,
  window: windowSlice,
  companies: companiesSlice,
  user: userSlice,
  trainers: trainersSlice,
  client: clientSlice,
  programTemplates: programTemplatesSlice,
  auth: authSlice,
  forgotPassword: restorePasswordSlice,
  exercises: exercisesSlice,
  dietPage: dietPageSlice,
  diets: dietsSlice,
  foods: foodsSlice,
  dietTemplates: dietTemplatesSlice,
  programs: programsSlice,
  payment: paymentSlice,
  utils: utilsSlice,
});

export const resetStore = () => {
  return {
    type: "Clear",
  };
};

const rootReducer = (state, action) => {
  if (action.type === "Clear") {
    state = undefined;
  }

  return appReducer(state, action);
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const errorMiddleware = (storeAPI) => (next) => async (action) => {
  const { dispatch } = storeAPI;

  try {
    if (action?.error?.message === USER_REDIRECTED_MSG) {
      throw new Error();
    }
  } catch (arror) {
    if (action?.type !== ACTION_TYPES.CLEAR && action?.type !== ACTION_TYPES.LOGOUT) {
      dispatch(resetStore());
      dispatch(logoutClient());
      window.location = REDIRECT_URL;
    };
    return;
  };

  return next(action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk, errorMiddleware],
  enhancers: [sentryReduxEnhancer],
});

withCleanup(store);
