import React, { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../../components/Button";
import List from "../../../../../components/list/List";
import Widget from "../../../../../components/widget/Widget";
import {
  EXCERCISES_PREVIEW_COUNT,
  EXERCISE_TYPES,
} from "../../../../../constants/exercises";
import { SIDEBAR_MODE } from "../../../../../constants/sidebar";
import useIsSmallScreen from "../../../../../hooks/useIsSmallScreen";
import useSearchHook from "../../../../../hooks/useSearchHook";
import { setSidebarMode } from "../../../../../redux/exercisesSlice";
import { setIsOpenDrawer } from "../../../../../redux/utilsSlice";

const header = [
  {
    label: "",
    style: { width: "3%" },
  },
  {
    label: "exercise name",
    style: { width: "53%" },
  },
  {
    style: { width: "22%" },
    label: "unit",
  },
  {
    style: { width: "22%" },
    label: "owner",
  },
];

const prepareList = (list) =>
  list
    ?.filter((item) => !item.is_deleted)
    .map((item) => ({
      id: item.id,
      name: item.name,
      unit: item.rep_calculator,
      owner: item.type === "global" ? "" : item.type,
    }));

const ExercisesList = ({ onExerciseClick, onExerciseDelete }) => {
  const dispatch = useDispatch();

  const isSmallScreen = useIsSmallScreen();
  const [isShowPreview, setIsShowPreview] = useState(true);
  // данные для показа до фильтрации
  const [list, setList] = useState([]);
  // данные для показа после фильтрации
  const [filteredList, setFilteredList] = useState([]);
  const loading = useSelector((state) => state.exercises.loading);
  const exercises = useSelector((state) => state.exercises.itemCurrentUser);
  const selectedExercisesId = useSelector(
    (state) => state.exercises.selectedExercises?.id
  );
  const sidebarMode = useSelector((state) => state.exercises.sidebarMode);
  const isOpenDrawer = useSelector((state) => state.utils.isOpenDrawer);

  useEffect(() => {
    if (exercises?.length) {
      const preparedList = prepareList(exercises);
      setList(preparedList);
      setFilteredList(preparedList);
      return;
    }
    setList([]);
    setFilteredList([]);
  }, [exercises]);

  const [searchValue, onSearchChange] = useSearchHook(list, setFilteredList);

  const itemClickHandler = (id) => {
    const exer = exercises.find((i) => i.id === id);
    onExerciseClick(exer);
  };

  const deleteItemHandler = (item) => {
    const exer = exercises.find((i) => i.id === item.id);
    onExerciseDelete(exer);
  };

  const getNonRemovableItems = useMemo(
    () => exercises?.filter((ex) => EXERCISE_TYPES.SYSTEM_REG.test(ex?.type)),
    [exercises]
  );

  return (
    <Widget className="exercises__">
      <Widget.Header className="pl-10">
        <Widget.Title>exercises</Widget.Title>
        <Button
          disabled={
            sidebarMode === SIDEBAR_MODE.ADD &&
            ((isSmallScreen && isOpenDrawer) || !isSmallScreen)
          }
          onClick={() => {
            dispatch(setIsOpenDrawer(true));
            dispatch(setSidebarMode(SIDEBAR_MODE.ADD));
          }}
        >
          add exercise
        </Button>
      </Widget.Header>
      <Widget.Body className="widget-body--with-mh">
        <List
          currentItem={selectedExercisesId}
          isEdit={sidebarMode === SIDEBAR_MODE.EDIT}
          isDelete={sidebarMode === SIDEBAR_MODE.DELETE}
          header={header}
          list={
            isShowPreview
              ? filteredList.slice(0, EXCERCISES_PREVIEW_COUNT)
              : filteredList
          }
          nonRemovableItems={getNonRemovableItems}
          deleteItemHandler={deleteItemHandler}
          itemClickHandler={itemClickHandler}
          searchValue={searchValue}
          onSearchChange={onSearchChange}
          isLoading={loading}
          isShowPagination={false}
        />
        {!loading && filteredList?.length > EXCERCISES_PREVIEW_COUNT && (
          <Button
            disabled={!isShowPreview}
            onClick={() => setIsShowPreview(false)}
          >
            show all
          </Button>
        )}
      </Widget.Body>
    </Widget>
  );
};

export default memo(ExercisesList);
