import "./WidgetInfo.scss";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormField from "../../../../../components/FormField/FormField";
import Widget from "../../../../../components/widget/Widget";
import WidgetBody from "../../../../../components/widget/WidgetBody";
import WidgetHeader from "../../../../../components/widget/WidgetHeader";
import WidgetTitle from "../../../../../components/widget/WidgetTitle";
import {
  WIDGET_BODY_HISTORY_STATE,
  WIDGET_DIET_HISTORY_STATE,
  WIDGET_HEALTH_STATE,
  WIDGET_INJURES_STATE,
  WIDGET_TRAINING_STATE,
  WIDGETS,
  WIDGETS_PROPERTY,
} from "../../../../../constants/clientInfo";
import {
  deleteTrashFieldForFormik,
  getDifferenceField,
  setNullInsteadOfBlank,
} from "../../../../../helpers";
import { useCallDebouncedCb } from "../../../../../hooks/useCallDebouncedCb";
import { editMainInfoClient } from "../../../../../redux/clientSlice";

const WidgetInfo = ({ widget, fields }) => {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.client.client);
  const loading = useSelector((state) => state.client.loading);
  const [values, setValues] = useState({});
  let widgetFieldState = {
    [WIDGETS.HEALTH]: client.general_health || WIDGET_HEALTH_STATE,
    [WIDGETS.BODY_HISTORY]:
      client.weight_training_exp || WIDGET_BODY_HISTORY_STATE,
    [WIDGETS.INJURES]: client.activity || WIDGET_INJURES_STATE,
    [WIDGETS.TRAINING]: client.training_environment || WIDGET_TRAINING_STATE,
    [WIDGETS.DIET_HISTORY]:
      client.dietary_practices || WIDGET_DIET_HISTORY_STATE,
  }[widget];

  widgetFieldState = deleteTrashFieldForFormik(widgetFieldState);

  useCallDebouncedCb(
    (id, body) => {
      if (Object.keys(body).length) {
        dispatch(editMainInfoClient({ id, body }));
      }
    },
    [client.id, values],
    0,
    [values]
  );
  return (
    <Widget>
      <WidgetHeader>
        <WidgetTitle>{widget}</WidgetTitle>
      </WidgetHeader>
      <WidgetBody isScrollable={true}>
        <Formik
          initialValues={widgetFieldState}
          enableReinitialize
          validateOnChange={false}
          validateOnMount={false}
          onSubmit={(values) => {
            values = getDifferenceField(values, widgetFieldState);
            values = setNullInsteadOfBlank(values);
            setValues({ [WIDGETS_PROPERTY[widget]]: values });
          }}
        >
          {({ values, initialValues, handleSubmit }) => (
            <Form>
              {fields.map((field) => (
                <FormField
                  disabled={loading}
                  key={field.name}
                  label={field.label}
                  name={field.name}
                  inputClassDefault="form-input m-15"
                  component="textarea"
                  style={{ resize: "none" }}
                  rows={4}
                  submitOnBlur={
                    Object.keys(getDifferenceField(values, initialValues))
                      .length
                  }
                />
              ))}
            </Form>
          )}
        </Formik>
      </WidgetBody>
    </Widget>
  );
};

WidgetInfo.propTypes = {
  fields: PropTypes.array.isRequired,
  widget: PropTypes.string.isRequired,
};

export default WidgetInfo;
