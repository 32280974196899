import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  loadClientListWithCache,
  loadClientActivityWithCash,
} from "../api/clientApi";

export const loadClientListThunk = createAsyncThunk(
  "clientList/loadClientListThunk",
  (args) => loadClientListWithCache.call(args)
);

export const loadClientActivityThunk = createAsyncThunk(
  "clientList/loadClientActivityThunk",
  (args) => loadClientActivityWithCash.call(args)
);

const clientListSlice = createSlice({
  name: "clientList",
  initialState: {
    clientForDelete: null,
    clients: null,
    page: 0,
    totalCount: 0,
    filter: "",
    loading: false,
    success: false,
    error: "",
    name: "",
    clientsActivity: [],
    currentClient: null,
  },
  reducers: {
    setCCurrentClient(state, action) {
      state.currentClient = action.payload;
    },
    setForDeleteClient(state, action) {
      state.clientForDelete = action.payload;
    },
    clearClientForDelete(state) {
      state.clientForDelete = null;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    clearFilter(state) {
      state.filter = "";
    },
    clearClientList(state) {
      state.clients = null;
      state.page = 0;
      state.totalCount = 0;
    },
    clearError(state) {
      state.error = "";
    },
    setIsLoading(state) {
      state.loading = true;
    },
  },
  extraReducers: {
    [loadClientListThunk.pending]: (state) => {
      state.loading = true;
    },
    [loadClientListThunk.fulfilled]: (state, action) => {
      state.clients = action.payload.clients;
      state.success = true;
      state.error = "";
      state.name = action.payload.name;
      state.page = action.payload.page;
      state.totalCount = action.payload.total_count;
      state.loading = false;
    },
    [loadClientListThunk.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    [loadClientActivityThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = "";
      state.clientsActivity = action.payload.activity_logs;
      state.page = action.payload.page;
      state.totalCount = action.payload.total_count;
    },
    [loadClientActivityThunk.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  setFilter,
  setForDeleteClient,
  clearClientForDelete,
  clearFilter,
  clearClientList,
  clearError,
  setIsLoading,
  setCCurrentClient,
} = clientListSlice.actions;

export default clientListSlice.reducer;
