import { baseUrl } from "../env/env";

import myFetch, { emailHandlerError } from "./myFetch";

export const loadProfile = () => {
  return myFetch(`${baseUrl}/api/user`);
};

export const saveProfile = (body) => {
  return myFetch(
    `${baseUrl}/api/user`,
    { method: "PATCH", body: body },
    emailHandlerError
  ).then((res) => {
    return res;
  });
};
