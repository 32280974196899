import React from "react";

import { DIET_ITEM_DISABLED_COLOR } from "../../../../../constants/diet";
import { BasicHelper } from "../../../../../helpers";

const Food = ({ food, quantity }) => {
  const isDeleted = food?.is_deleted;
  const color = isDeleted ? DIET_ITEM_DISABLED_COLOR : null;
  return (
    <tr>
      <td style={{ width: "25%", color }}>
        {BasicHelper.roundTo(quantity, 1)} {food.measure}
      </td>
      <td style={{ width: "45%", color }}>{food.name}</td>
      <td style={{ width: "10%", color }}>
        {(food.proteins * quantity).toFixed(0)}
      </td>
      <td style={{ width: "10%", color }}>
        {(food.carbs * quantity).toFixed(0)}
      </td>
      <td style={{ width: "10%", color }}>
        {(food.fats * quantity).toFixed(0)}
      </td>
    </tr>
  );
};

Food.propTypes = {};

export default Food;
