import { useState, useEffect } from "react";

import { filterList } from "../helpers";

const useFilteredList = (data, searchValue) => {
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  useEffect(() => {
    if (data?.length) {
      setList(data);
      setFilteredList(data);
      return;
    }
    setList([]);
    setFilteredList([]);
  }, [data]);

  useEffect(() => {
    setFilteredList(filterList(list, searchValue));
  }, [searchValue, list]);

  return filteredList;
};

export default useFilteredList;
