import "./TableItem.scss";
import { CLIENT, TRAINERS } from "../../../../../constants/router";

import RedirectButton from "./components/RedirectButton";

const TableItem = ({
  name,
  id,
  onClientsClick,
  onTrainersClick,
  onCompanyClick,
}) => {
  return (
    <tr className="companies-table__item">
      <td
        onClick={() => {
          onCompanyClick(id);
        }}
        className="cell--name"
      >
        {name}
      </td>
      <td className="cell--trainers" onClick={onTrainersClick}>
        <RedirectButton to={`${TRAINERS.root}?companyId=${id}`}>
          coaches
        </RedirectButton>
      </td>
      <td className="cell--clients" onClick={onClientsClick}>
        <RedirectButton to={`${CLIENT.root}?companyId=${id}`}>
          clients
        </RedirectButton>
      </td>
    </tr>
  );
};

TableItem.propTypes = {};

export default TableItem;
