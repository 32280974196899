import { createSelector } from "@reduxjs/toolkit";

import { WIDGET_VIEW_TYPES } from "../constants/trainers";

export const sLoadingAllClients = (s) => s.trainers.loadingAllClients;
export const sLoadingActiveClients = (s) => s.trainers.loadingActiveClients;

export const sTrainersAllClients = (s) => s.trainers.trainersAllClients;
export const sTrainerActiveClients = (s) => s.trainers.trainerActiveClients;

export const sCurrentTrainer = (s) => s.trainers.currentTrainer;
export const sCurrentTrainerId = createSelector(
  [sCurrentTrainer],
  (currentTrainer) => currentTrainer.id
);
export const sTrainerClientByTitle = (s, { title }) => {
  switch (title) {
    case WIDGET_VIEW_TYPES.ALL_CLIENTS:
      return sTrainersAllClients(s);
    case WIDGET_VIEW_TYPES.ACTIVE_CLIENTS:
      return sTrainerActiveClients(s);
    default:
      return null;
  }
};
export const sClients = createSelector(
  [sTrainerClientByTitle, sCurrentTrainerId],
  (trainers, id) => trainers?.[id]
);
