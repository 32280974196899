import "./SetButton.scss";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  calculateMax,
  calculateGoalReps,
  composeSetValues,
  calculateWeight,
  getMergedId,
} from "../../../../../../../helpers/training";
import { setCurrentSet } from "../../../../../../../redux/programsSlice";
import {
  setIsOpenDrawer,
  setModal,
} from "../../../../../../../redux/utilsSlice";

const CompletedSet = ({ data, exerciseData, setIdx }) => {
  const massUnit = useSelector((state) => state.client.client.mass_unit);

  const { weight, reps, other_reps } = data;
  const { rep_calculator } = exerciseData;
  const maxWeight = calculateMax(weight, reps, rep_calculator);

  return (
    <>
      <div className="set-button__main">
        <span className="set-button__idx">{`set ${setIdx + 1}: `}</span>
        <span className="set-button__weight">{weight}</span>
        <span className="set-button__units"> {massUnit}</span>
        <span className="set-button__multiply">X</span>
        <span className="set-button__reps">{`${reps}${
          other_reps ? ` + ${other_reps}` : ""
        }`}</span>
      </div>
      <div className="set-button__addition">
        <span className="set-button__value">
          ({`${maxWeight} ${massUnit} max`})
        </span>
      </div>
      <div className="set-button__complete-icon">
        <FontAwesomeIcon icon={faCheck} />
      </div>
    </>
  );
};

const UncompletedSet = ({ data, inProgram, exerciseData, setIdx }) => {
  const massUnit = useSelector((state) => state.client.client.mass_unit);
  const history = useSelector((state) => state.programs.history);
  const loading = useSelector((state) => state.programs.loadingHistory);
  const { weight, reps } = data;
  const { rep_calculator, pb_value } = exerciseData;

  const setToBeat = useMemo(() => {
    const mergedId = getMergedId(exerciseData?.id, inProgram?.id);
    return !(loading || !setIdx || !history?.history)
      ? history?.sets[history?.history[mergedId]?.previous?.ranked]
      : null;
  }, [loading, history, inProgram, exerciseData, setIdx]);
  const toBeat = setToBeat?.calculated_max || pb_value;

  const calculatedWeight = calculateWeight(weight, pb_value) || "";
  // can't remember why we did this, let it be here just in case
  //   (inProgram?.weight
  //     ? inProgram.weight
  //     : calculateWeight(weight, pb_value)) || "";
  const calculatedWeightToDisplay = !exerciseData.pb_calculated
    ? calculatedWeight
    : "";
  const goalReps = toBeat
    ? calculateGoalReps(toBeat, calculatedWeight, rep_calculator)
    : 0;

  const GoalText = () => (
    <span className="set-button__value">
      {!(loading && setIdx) &&
        (reps
          ? `${reps > goalReps ? "total:" : "do"} ${reps} reps`
          : `(${setToBeat || !setIdx ? `goal: ${goalReps}+` : "max"} reps)`)}
    </span>
  );

  return (
    <>
      <div className="set-button__main">
        <span>{`set ${setIdx + 1}: `}</span>
        <span className="set-button__value set-button__value--big">
          {weight ? calculatedWeightToDisplay : reps}
        </span>
        <span className="set-button__units">
          {weight && calculatedWeightToDisplay
            ? ` ${massUnit}`
            : reps && inProgram?.reps
            ? " reps"
            : ""}
        </span>
      </div>
      {!!weight && (
        <div className="set-button__addition--small">
          <GoalText />
        </div>
      )}
    </>
  );
};

const SetButton = ({
  data,
  exerciseData,
  setIdx,
  workoutIdx,
  dayIdx,
  setRightWidgetPerformanceAndHistory,
  exerciseIdx,
}) => {
  const dispatch = useDispatch();

  const {
    currentSet,
    currentSetIdx,
    currentExerciseIdx,
    currentWorkoutIdx,
    currentDayIdx,
  } = useSelector((state) => state.programs);

  const template = useSelector((state) => state.programs?.currentProgram?.template);

  const setInProgram = useSelector(
    (state) =>
      state.programs?.currentProgram?.days[dayIdx]?.workouts[workoutIdx]?.exercises[
        exerciseIdx
      ]?.sets[setIdx]
  );

  const isOpenDrawer = useSelector((state) => state.utils.isOpenDrawer);
  const isSavingSet = useSelector((state) => state.programs.isSavingSet);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(
      currentDayIdx === dayIdx &&
        currentWorkoutIdx === workoutIdx &&
        currentExerciseIdx === exerciseIdx &&
        currentSetIdx === setIdx
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSet]);

  const dataWithTemplate = useCallback(
    () => ({
      ...data,
      ...template?.days[dayIdx]?.workouts[workoutIdx]?.exercises[exerciseIdx]?.sets[
        setIdx
      ],
    }),
    [data, dayIdx, exerciseIdx, setIdx, template.days, workoutIdx]
  )();

  const handleClick = (e) => {
    e.stopPropagation();
    const setValues = composeSetValues(
      dayIdx,
      workoutIdx,
      exerciseIdx,
      exerciseData,
      setIdx,
      data,
      exerciseData.pb_value,
      exerciseData.pb_calculated,
      template
    );
    if (
      !exerciseData.pb_value ||
      (exerciseData.pb_calculated && !data.is_completed) // just in case...
    )
      dispatch(
        setModal(
          <>
            <div className="training-page__modal-title">Note:</div>
            This is your first ever set of "{exerciseData.name}". It will
            establish your starting pb, and all of the weights your program
            recommends.
            <br />
            Please choose a moderately heavy weight, perform as many reps as
            possible and then "save".`
          </>
        )
      );

    dispatch(setCurrentSet(setValues));
    dispatch(setIsOpenDrawer(true));
    setRightWidgetPerformanceAndHistory();
  };

  return (
    <div
      className={cn("set-button", {
        "set-button--active": isActive && isOpenDrawer,
        "set-button--new-pb": data.has_new_pb,
        "set-button--disabled": isSavingSet,
      })}
      onClick={handleClick}
    >
      {data.is_completed ? (
        <CompletedSet data={data} exerciseData={exerciseData} setIdx={setIdx} />
      ) : (
        <UncompletedSet
          data={dataWithTemplate}
          inProgram={setInProgram}
          exerciseData={exerciseData}
          setIdx={setIdx}
        />
      )}
    </div>
  );
};

export default SetButton;
