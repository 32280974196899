import {
  REGEXP_CARD_HOLDER_NAME,
  REGEXP_CARD_HOLDER_NAME_TEST,
} from "../constants/regexp";
import { style } from "../pages/Profile/CompanyProfilePage/components/StripePaymentSidebar/components/styleObject";
/**
 * parse the currency field value (^$...)
 * @param {string} value
 * @return {number | null }
 **/
export const parseCurrencyFieldValue = (value) => {
  return value.length === 0 ? null : parseFloat(value.replace("$", "")) * 100;
};

export const setFormProp = (setFormState, value, prop) => {
  setFormState((existingValues) => ({
    ...existingValues,
    [prop]: value,
  }));
};

export const handleCardholderChange = (
  event,
  setFormProp,
  setFormState,
  getCurrentRegexp
) => {
  const value = event.target.value.toUpperCase();
  setFormProp(setFormState, value, "cardHolder");
  setFormProp(setFormState, getCurrentRegexp(value), "isCardHolderValid");
};

export const handleStripeElementChange = (
  event,
  element,
  setFormProp,
  setFormState,
  formProp
) => {
  if (event.error || event.empty) {
    setFormProp(setFormState, false, formProp);
  }

  if (event.complete) {
    element.update({ style });
    setFormProp(setFormState, true, formProp);
  }
};
