import { Modal } from "@material-ui/core";

import Button from "../../../../../components/Button";

const ModalWindow = ({ modal, close, confirm, isQuestion = false }) => (
  <Modal open={modal} className="training-page__modal">
    <div className="training-page__modal-window">
      {modal}
      {isQuestion && (
        <div className="d-flex">
          <Button onClick={confirm}>yes</Button>
          <Button onClick={close}>no</Button>
        </div>
      )}
      {!isQuestion && <Button onClick={close}>ok</Button>}
    </div>
  </Modal>
);

export default ModalWindow;
