import { Box } from "@material-ui/core";
import cn from "classnames";
import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { MAX_OF_STRENGTH_TEST } from "../../../constants/common";
import { STRENGTH_COEFFICIENTS } from "../../../constants/strengthTestForm";
import { KG } from "../../../constants/unit";
import { upperCaseFirst } from "../../../helpers";
import FormField from "../../FormField/FormField";
import FormLabel from "../../FormLabel";

const StrengthTestField = ({
  values,
  name,
  validate,
  unit,
  validateAmount,
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const repsName = `reps${upperCaseFirst(name)}`;
  const max = unit === KG ? MAX_OF_STRENGTH_TEST.KG : MAX_OF_STRENGTH_TEST.LB;

  const calculateStrength = () => {
    const maxVal = Math.round(
      values[name] * STRENGTH_COEFFICIENTS[values[repsName] - 1]
    );

    setFieldTouched(`${name}Max`);
    setFieldValue(`${name}Max`, maxVal || undefined);
  };

  const validateMax = (val) => {
    if (!val) return false; // always not required

    const error = validate(val);
    return error ? `max ${error}` : false;
  };

  useEffect(() => calculateStrength(), [values[name], values[repsName]]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-end"
      width="100%"
      margin="5px"
    >
      <FormLabel htmlFor="squat" className="w-30">
        {name}
      </FormLabel>
      <FormField
        name={name}
        inputDivClass="w-20"
        type="number"
        validate={validate}
        step="0.1"
        min="0.1"
        max={max}
        allowFloat={true}
      />
      <Box
        width="5%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        paddingBottom=".5em"
      >
        x
      </Box>
      <FormField
        name={repsName}
        inputDivClass="w-10"
        type="number"
        style={{ textAlign: "center" }}
        step="1"
        min="1"
        max="30"
        validate={validateAmount}
      />
      <Box
        width="5%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        paddingBottom=".5em"
      >
        =
      </Box>
      <Field
        name={`${name}Max`}
        disabled
        validate={validateMax}
        render={(val) => (
          <div
            className={cn("max-value", "w-20", {
              "max-value--error": val.meta.value && val.meta.error,
            })}
          >
            {!!val.meta.value && (
              <>
                {val.meta.value}
                <span className="max-unit">{unit}</span>
              </>
            )}
          </div>
        )}
      />
    </Box>
  );
};

StrengthTestField.propTypes = {
  values: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  validate: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
};

export default StrengthTestField;
