import "./assets/styles-old/app.scss";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CacheBuster from "./CacheBuster";

import GuardedRoute from "./components/GuardedRoute";
import MainLayout from "./components/MainLayout";
import Spinner from "./components/Spinner";
import { USER_TYPES } from "./constants/common";
import { USER_TYPE_COMPANY_ADMIN } from "./constants/payment";
import {
  AUTH,
  CLIENT,
  COMPANY,
  EXERCISES,
  PAYMENT,
  PROFILE,
  TRAINERS,
  PROGRAM_TEMPLATES,
  FOODS,
  HOME,
  TERMS,
  PRIVACY,
} from "./constants/router";
import { REDIRECT_URL } from "./env/env";
import { useSearchParam } from "./hooks/useSearchParam";
import Auth from "./pages/Auth";
import RestorePassword from "./pages/Auth/AuthPage/restorePassword";
import Client from "./pages/Clients";
import Company from "./pages/Companies";
import Exercises from "./pages/Exercises";
import Foods from "./pages/Foods";
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import Payment from "./pages/Payment";
import Privacy from "./pages/Privacy/Privacy";
import Profile from "./pages/Profile/Index";
import ProgramTemplates from "./pages/ProgramTemplates";
import Terms from "./pages/Terms/Index";
import Trainers from "./pages/Trainers";
import { logoutClient } from "./redux/authSlice";
import {
  loadCompanyById,
  loadPaymentAccount,
  deletePaymentAccount,
  setPaymentAccountLoadingAttempted,
} from "./redux/companiesSlice";
import { resetStore, store } from "./redux/index";
import { loadProfile } from "./redux/userSlice";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const currentCompany = useSelector(
    (state) => state.companies?.currentCompany
  );
  const isClient = user?.type === USER_TYPES.CLIENT;
  const isTrainer = user?.type === USER_TYPES.TRAINER;
  const isCompanyAdmin = user?.type === USER_TYPES.COMPANY_ADMIN;
  const isClientTransferred =
    user?.type !== USER_TYPES.SUPERUSER && user?.is_transferred_on_v3;

  useEffect(() => {
    if (isClientTransferred) {
      dispatch(resetStore());
      dispatch(logoutClient());
      window.location = REDIRECT_URL;
    }
  }, [dispatch, isClientTransferred, user]);


  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        };
        return (
          <Router>
            <MainLayout>
              <Switch>
                <Route path={AUTH.root}>
                  <Auth />
                </Route>
                <Route path={AUTH.newPassword}>
                  <RestorePassword />
                </Route>
                <Route path={TERMS}>
                  <Terms />
                </Route>
                <Route path={PRIVACY}>
                  <Privacy />
                </Route>
                <Route path={CLIENT.root}>
                  <NeedAuth key={1}>
                    <GuardedRoute isShow={user} to={AUTH.root}>
                      <Client />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={EXERCISES.root}>
                  <NeedAuth key={2}>
                    <GuardedRoute
                      isShow={user}
                      to={AUTH.root}
                      userTypeGuard={!isClient}
                    >
                      <Exercises />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={COMPANY.root}>
                  <NeedAuth key={3}>
                    <GuardedRoute
                      isShow={user}
                      to={AUTH.root}
                      userTypeGuard={!isClient && !isCompanyAdmin && !isTrainer}
                    >
                      <Company />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={TRAINERS.root}>
                  <NeedAuth key={4}>
                    <GuardedRoute
                      isShow={user}
                      to={AUTH.root}
                      userTypeGuard={
                        !isClient &&
                      !isTrainer &&
                      !(isCompanyAdmin && !currentCompany?.can_add_coaches)
                      }
                    >
                      <Trainers />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={PAYMENT.root}>
                  <NeedAuth key={5}>
                    <GuardedRoute
                      isShow={user}
                      to={AUTH.root}
                      userTypeGuard={!isClient && !isCompanyAdmin && !isTrainer}
                    >
                      <Payment />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={PROFILE.root}>
                  <NeedAuth key={7}>
                    <GuardedRoute isShow={user} to={AUTH.root}>
                      <Profile />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={PROGRAM_TEMPLATES.root}>
                  <NeedAuth key={8}>
                    <GuardedRoute
                      isShow={user}
                      to={AUTH.root}
                      userTypeGuard={!isClient}
                    >
                      <ProgramTemplates />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path={FOODS.root}>
                  <NeedAuth key={9}>
                    <GuardedRoute
                      isShow={user}
                      to={AUTH.root}
                      userTypeGuard={!isClient}
                    >
                      <Foods />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route exact path={HOME.root}>
                  <NeedAuth key={6}>
                    <GuardedRoute isShow={user} to={AUTH.root}>
                      <Home />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
                <Route path="">
                  <NeedAuth key={10}>
                    <GuardedRoute isShow={user} to={AUTH.root}>
                      <PageNotFound />
                    </GuardedRoute>
                  </NeedAuth>
                </Route>
              </Switch>
            </MainLayout>
          </Router>
        )
      }}
    </CacheBuster>
  );
}

const NeedAuth = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const isCardVerified = useSelector((state) => state.companies.isCardVerified);
  const paymentAccountLoadingAttempted = useSelector(
    (state) => state.companies.paymentAccountLoadingAttempted
  );
  const [isShowSpinner, setIShowSpinner] = useState(true);
  const [ckoSessionId, setCkoSessionId] = useSearchParam("cko-session-id");

  useEffect(() => {
    async function checkAuth() {
      if (!user) {
        const userResponse = await dispatch(loadProfile());
        if (typeof userResponse.payload !== "undefined") {
          const userCompany = userResponse.payload?.company;
          if (userCompany && userCompany !== currentCompany?.id) {
            await dispatch(loadCompanyById({ id: userCompany }));
          }
        }
      }
      setIShowSpinner(false);
    }

    checkAuth();
  }, []);

  useEffect(() => {
    async function checkPaymentAcc() {
      if (
        currentCompany !== null &&
        !ckoSessionId &&
        !isCardVerified &&
        !paymentAccountLoadingAttempted &&
        user.type === USER_TYPE_COMPANY_ADMIN
      ) {
        const paymentAcc = await dispatch(
          loadPaymentAccount({ id: currentCompany.id })
        );
        const { cko_verified, last4 } = paymentAcc.payload;
        store.dispatch(setPaymentAccountLoadingAttempted(true));
        if (cko_verified === false && last4 !== null) {
          await dispatch(
            deletePaymentAccount({ id: currentCompany.id, forceDelete: true })
          );
        }
      }
    }

    checkPaymentAcc();
  }, [currentCompany]);

  if (isShowSpinner) {
    return <Spinner />;
  }
  return children;
};

export default App;
