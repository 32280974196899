export const style = {
  base: {
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: "16px",
    fontFamily: '"Roboto", sans-serif',
    lineHeight: "32px",
    "::placeholder": {
      color: "rgba(255, 255, 255, 0.3)",
    },
  },
  invalid: {
    color: "#FF0000",
  },
};

export const errorStyle = {
  base: {
    color: "red",
    "::placeholder": {
      color: "red",
    },
  },
};
