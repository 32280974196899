import { Redirect } from "react-router-dom";

import { HOME } from "../../constants/router";

const GuardedRoute = ({ isShow, to, children, userTypeGuard }) => {
  if (!isShow) return <Redirect to={to} />;
  if (userTypeGuard === false) return <Redirect to={HOME.root} />;
  return children;
};

export default GuardedRoute;
