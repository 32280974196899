import { CLIENT_INFO_TYPES } from "../constants/clientApi";
import { baseUrl } from "../env/env";
import withCache from "../helpers/cache";

import myFetch, {
  emailHandlerError,
  activateClientError,
  sendBodyTestHandlerError,
} from "./myFetch";
import {
  loadActiveClientsWithCache,
  loadAllClientsWithCache,
  loadTrainersWithCache,
} from "./trainerApi";

export const loadClientList = ({ page = 1, search = "", companyId }) => {
  const params = `page=${page}${!!search ? `&search=${search}` : ""}${
    companyId ? `&company_id=${companyId}` : ""
  }`;
  return myFetch(`${baseUrl}/api/clients?${params}`);
};

export const loadClientById = ({ id, infoType }) =>
  myFetch(`${baseUrl}/api/clients/${id}${infoType ? `/${infoType}` : ""}`);

export const loadClientListWithCache = withCache(loadClientList);
export const loadClientByIdWithCache = withCache(loadClientById);

export const loadClientActivity = ({ id, page }) => {
  const pageParam = page ? `&page=${page}` : "";
  const idParam = `user_id=${id}`;
  const params = idParam + pageParam;
  return myFetch(`${baseUrl}/api/activity-logs${params && "?" + params}`);
};

export const loadClientActivityWithCash = withCache(loadClientActivity);

export const addClient = (body) => {
  loadClientListWithCache.invalidate();
  loadClientActivityWithCash.invalidate();
  return myFetch(
    `${baseUrl}/api/clients`,
    { method: "POST", body: body },
    emailHandlerError
  );
};

export const sendBodyTest = ({ body, id }) => {
  loadClientByIdWithCache.invalidateByArgs({
    id,
    infoType: CLIENT_INFO_TYPES.BODY,
  });
  return myFetch(
    `${baseUrl}/api/clients/${id}/body_test`,
    { method: "PUT", body: { last_body: body } },
    sendBodyTestHandlerError
  );
};

export const sendStrengthTest = ({ body, id }) => {
  loadClientByIdWithCache.invalidateByArgs({
    id,
    infoType: CLIENT_INFO_TYPES.BODY,
  });
  return myFetch(
    `${baseUrl}/api/clients/${id}/strength_test`,
    { method: "PUT", body: { last_strength: body } },
    sendBodyTestHandlerError
  );
};

export const sendDoubleStrengthTest = ({ actualData, estimateData, id }) => {
  loadClientByIdWithCache.invalidateByArgs({
    id,
    infoType: CLIENT_INFO_TYPES.BODY,
  });
  return myFetch(
    `${baseUrl}/api/clients/${id}/strength_test_double`,
    { method: "PUT", body: { strength_tests: [actualData, estimateData] } },
    sendBodyTestHandlerError
  );
};

export const editMainInfoClient = ({ id, body }) => {
  loadClientListWithCache.invalidate();
  loadClientByIdWithCache.invalidateByArgs({
    id,
    infoType: CLIENT_INFO_TYPES.MAIN,
  });
  loadClientByIdWithCache.invalidateByArgs({
    id,
    infoType: CLIENT_INFO_TYPES.BODYMAIN,
  });

  loadTrainersWithCache.invalidate();
  loadActiveClientsWithCache.invalidate();
  loadAllClientsWithCache.invalidate();
  return myFetch(
    `${baseUrl}/api/clients/${id}/main`,
    {
      method: "PATCH",
      body: body,
    },
    emailHandlerError
  );
};

export const updateClientActiveHistory = ({ id, body }) => {
  loadClientListWithCache.invalidate();
  loadClientByIdWithCache.invalidateByArgs({
    id,
    infoType: CLIENT_INFO_TYPES.MAIN,
  });
  loadClientByIdWithCache.invalidateByArgs({
    id,
    infoType: CLIENT_INFO_TYPES.BODY,
  });
  loadClientByIdWithCache.invalidateByArgs({
    id,
    infoType: CLIENT_INFO_TYPES.BODYMAIN,
  });

  loadTrainersWithCache.invalidate();
  loadActiveClientsWithCache.invalidate();
  loadAllClientsWithCache.invalidate();
  return myFetch(
    `${baseUrl}/api/clients/${id}`,
    {
      method: "PATCH",
      body: body,
    },
    activateClientError
  );
};

export const editMainInfoClientWithOutSave = ({ id, body }) =>
  myFetch(
    `${baseUrl}/api/clients/${id}/main`,
    {
      method: "PATCH",
      body: body,
    },
    emailHandlerError
  );

export const updateClientGoal = ({ id, goal }) => {
  loadClientByIdWithCache.invalidate();
  return myFetch(`${baseUrl}/api/clients/${id}/goal_value`, {
    method: "PUT",
    body: goal,
  });
};

export const updateClientGoalDate = ({ id, goal }) => {
  loadClientByIdWithCache.invalidateByArgs({ id });
  return myFetch(`${baseUrl}/api/clients/${id}/goal_value`, {
    method: "PATCH",
    body: goal,
  });
};

export const getClientProgress = (id) =>
  myFetch(`${baseUrl}/api/clients/${id}/progress`, {
    method: "GET",
  });

export const deleteClient = ({ id }) => {
  loadClientListWithCache.invalidate();
  loadClientByIdWithCache.invalidateByArgs({ id });
  loadClientActivityWithCash.invalidate();
  loadTrainersWithCache.invalidate();
  loadActiveClientsWithCache.invalidate();
  loadAllClientsWithCache.invalidate();
  return myFetch(`${baseUrl}/api/clients/${id}`, { method: "DELETE" });
};
