import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import PaymentPage from "./PaymentPage";

const Payment = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.url}`}>
        <PaymentPage />
      </Route>
    </Switch>
  );
};

export default Payment;
