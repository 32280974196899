import "./ClientBody.scss";
import cn from "classnames";
import { useContext, useEffect } from "react";
import { WIDGET_VIEW_TYPES } from "../../../../constants/activityTracking";
import Button from "../../../Button";
import { useDispatch, useSelector } from "react-redux";
import { WidgetContext } from "../../ActivityTracking";
import { loadClientActivityThunk } from "../../../../redux/clientListSlice";
import { ReactComponent as NextPageArrow } from "../../../../assets/svg/next-page-arrow.svg";
import { ReactComponent as PrevPageArrow } from "../../../../assets/svg/prev-page-arrow.svg";
import PageLabel from "../../../list/PageLabel/PageLabel";
import { isPrevOrNextPageExist } from "../../../../helpers";
import { ITEMS_PER_PAGE } from "../../../../constants/common";
import Spinner from "../../../Spinner";

export const ClientBody = () => {
  const dispatch = useDispatch();
  const clientsActivity = useSelector(
    (state) => state.clientList.clientsActivity
  );
  const page = useSelector((state) => state.clientList.page);
  const totalCount = useSelector((state) => state.clientList.totalCount);
  const currentClient = JSON.parse(localStorage.getItem("user"));
  const isLoading = useSelector((state) => state.clientList.loading);

  const {
    setWidgetParam,
    widgetParam,
    clientActivityPage,
    setActivityListPage,
  } = useContext(WidgetContext);

  useEffect(() => {
    if (widgetParam !== "client-body") {
      setWidgetParam("client-body", false);
    }
    dispatch(
      loadClientActivityThunk({
        id: currentClient?.id,
        page: clientActivityPage,
      })
    );
  }, []);

  const goBackHandler = ({ toCompanies, goBack }) => {
    if (toCompanies) {
      setWidgetParam(WIDGET_VIEW_TYPES.COMPANIES_BODY, true);
      localStorage.removeItem("current-company");
    } else if (goBack) {
      setWidgetParam(WIDGET_VIEW_TYPES.CLIENTS_BODY, true);
    }
    localStorage.removeItem("user");
  };

  const moveToAnothePage = (direction) => {
    if (direction === "next") {
      const nextPage = page + 1;
      dispatch(loadClientActivityThunk({ page: nextPage, id: currentClient }));
      setActivityListPage(nextPage, false);
    }
    if (direction === "prev") {
      const nextPage = page - 1;
      dispatch(loadClientActivityThunk({ page: nextPage, id: currentClient }));
      setActivityListPage(nextPage, false);
    }
  };

  return !isLoading ? (
    <>
      <div className="diet-side-ch">
        <table style={{ width: "100%" }} className="activityDropdown-list">
          <thead>
            <tr className="client-body__head-container">
              <td>{currentClient?.name}</td>
              <td>
                {
                  <>
                    <Button
                      onClick={() => goBackHandler({ toCompanies: true })}
                    >
                      to companies
                    </Button>
                    <Button onClick={() => goBackHandler({ goBack: true })}>
                      back
                    </Button>
                  </>
                }
              </td>
            </tr>
            <tr className="client-body__body-container">
              {clientsActivity?.map((timestamp, indx) => (
                <>
                  {timestamp.reactivated_at && (
                    <td
                      key={indx}
                      className="client-body__body-container_inner"
                    >
                      <p className="client-body__text">Activated</p>
                      <p className="client-body__text">
                        {timestamp.reactivated_at}
                      </p>
                    </td>
                  )}
                  <td key={indx} className="client-body__body-container_inner">
                    <p className="client-body__text">Deactivated</p>
                    <p className="client-body__text">
                      {timestamp.deactivated_at}
                    </p>
                  </td>
                </>
              ))}
            </tr>
            {totalCount > 50 && (
              <tr>
                <td colSpan={4}>
                  <div className="list-arrow-wrapper">
                    <PrevPageArrow
                      width="25px"
                      heigth="25px"
                      className={cn("body-arrow", {
                        "body-arrow--disabled": isPrevOrNextPageExist({
                          page,
                          totalCount,
                          prev: true,
                        }),
                      })}
                      onClick={() => moveToAnothePage("prev")}
                    />
                    <PageLabel
                      page={page}
                      itemsCount={clientsActivity?.length}
                      totalCount={totalCount}
                      perPage={ITEMS_PER_PAGE}
                    />
                    <NextPageArrow
                      width="25px"
                      heigth="25px"
                      className={cn("body-arrow", {
                        "body-arrow--disabled": isPrevOrNextPageExist({
                          page,
                          totalCount,
                          next: true,
                        }),
                      })}
                      onClick={() => moveToAnothePage("next")}
                    />
                  </div>
                </td>
              </tr>
            )}
          </thead>
        </table>
      </div>
    </>
  ) : (
    <div className={"spinner-wrapper"}>
      <Spinner />
    </div>
  );
};
