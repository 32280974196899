import { useSelector } from "react-redux";

import Widget from "../../../../../components/widget/Widget";
import "./NotesWidget.scss";
import { MAX_MOBILE_SCREEN_WIDTH } from "../../../../../constants/common";
import { prepareLocalFancyDateForFront } from "../../../../../helpers";
import useIsSmallScreen from "../../../../../hooks/useIsSmallScreen";
import Button from "../../../../../components/Button";

import React from "react";

const NotesWidget = ({ hideWidget }) => {
  const notes = useSelector((state) => state.programs.currentProgram.notes);
  const isMobileVersion = useIsSmallScreen(
    MAX_MOBILE_SCREEN_WIDTH.MOBILE_VIEW_ON_TRAINING
  );

  return (
    <Widget>
      <Widget.Header>
        <Widget.Title>all notes</Widget.Title>
        {isMobileVersion && (
          <Button
            type="button"
            className="trainings-notes-widget-button"
            onClick={hideWidget}
          >
            back
          </Button>
        )}
      </Widget.Header>
      {notes.length ? (
        notes.map((note) => (
          <div key={note.id} className="training-day__note">
            <p>
              {`${prepareLocalFancyDateForFront(note.date)} (day ${
                note.day_index + 1
              })`}
            </p>
            <span>{note.text}</span>
          </div>
        ))
      ) : (
        <h3>you haven't got any saved notes</h3>
      )}
    </Widget>
  );
};

NotesWidget.propTypes = {};

export default NotesWidget;
