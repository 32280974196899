import React from "react";
import { Link } from "react-router-dom";

import { COMPANY, PAYMENT, PROFILE, TRAINERS } from "../../../constants/router";
import ClientDropDown from "../ClientDropDown";
import { CLIENTS_ITEMS } from "../Menu.model";
import { TOOLS_ITEMS } from "../Menu.model";
import ToolsDropDown from "../ToolsDropDown";

const SuperUserMenu = ({
  closeMenuAndResetEditableClientId,
  closeMenu,
  refreshClick,
  isSmallScreen,
}) => {
  return (
    <>
      <div onClick={closeMenuAndResetEditableClientId} className="menu-item">
        <Link to={PROFILE.root} className="tools">
          my profile
        </Link>
      </div>
      <div onClick={closeMenuAndResetEditableClientId} className="menu-item">
        <Link to={PAYMENT.root} className="tools">
          payment schedule
        </Link>
      </div>
      <div onClick={closeMenuAndResetEditableClientId} className="menu-item">
        <Link to={COMPANY.root} className="tools">
          companies
        </Link>
      </div>
      <div onClick={closeMenuAndResetEditableClientId} className="menu-item">
        <Link to={TRAINERS.root} className="tools">
          coaches
        </Link>
      </div>
      <ClientDropDown dropdownItems={CLIENTS_ITEMS} onClick={closeMenu} />
      <ToolsDropDown
        onClick={closeMenuAndResetEditableClientId}
        dropdownItems={TOOLS_ITEMS}
        value="tools"
      />
      {isSmallScreen && (
        <div onClick={closeMenuAndResetEditableClientId} className="menu-item">
          <div onClick={refreshClick} className="tools">
            refresh
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(SuperUserMenu);
