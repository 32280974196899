import { capitalize } from "lodash/string";

import {
  MAX_CLIENT_WEIGHT_KG,
  MAX_CLIENT_WEIGHT_LB,
  MIN_CLIENT_WEIGHT_KG,
  MIN_CLIENT_WEIGHT_LB,
} from "../constants/body";
import { MAX_HEIGHT, MIN_HEIGHT } from "../constants/clientForm";
import {
  MAX_DEACTIVATION_PERIOD,
  MAX_OF_STRENGTH_TEST,
  MIN_DEACTIVATION_PERIOD,
  NUMBERS_OF_REPEAT_EXERCISE,
} from "../constants/common";
import { EXERCISES_MULTIPLIER } from "../constants/exercises";
import {
  REGEXP_ANY_FLOAT,
  REGEXP_DATE,
  REGEXP_EMAIL,
  REGEXP_PHONE,
} from "../constants/regexp";
import { KG, LB } from "../constants/unit";

export const validateRequired = (value, error = "error") => {
  if (!value) {
    return error;
  }
  return false;
};

export const validateRequiredString = (value) => {
  if (!value?.toString().trim()) {
    return "error";
  }
  return false;
};

export const validateExercisesMultiplier = (value) => {
  const re = new RegExp(REGEXP_ANY_FLOAT);
  if (!re.test(value)) {
    return "error";
  }

  const _value = String(value).replace(",", ".");

  if (_value < EXERCISES_MULTIPLIER.MIN || _value >= EXERCISES_MULTIPLIER.MAX) {
    return "error";
  }
};

export const validateDeactivationPeriodRequired = (value) => {
  if (validateRequired(value)) {
    return "error";
  }
  if (value < MIN_DEACTIVATION_PERIOD || value > MAX_DEACTIVATION_PERIOD) {
    return "error";
  }
};

export const validatePhoneNumber = (value, alternativeName = "Phone") => {
  const re = new RegExp(REGEXP_PHONE);
  if (value && !re.test(value)) {
    return `${alternativeName} number is invalid`;
  }
  return false;
};

export const validateDate = (value) => {
  const re = new RegExp(REGEXP_DATE);
  if (value && !re.test(value)) {
    return "error";
  }
  return false;
};

export const validateRequiredDate = (value) => {
  return validateRequired(value) || validateDate(value);
};

export const validateHeight = (value) => {
  if (value && (MIN_HEIGHT >= value || value >= MAX_HEIGHT)) {
    return "error";
  }
  return false;
};

export const validateRequiredHeight = (value) => {
  return validateRequired(value) || validateHeight(value);
};

export const validateFatDevice = (value) => {
  if (validateRequired(value)) {
    return "Please select device";
  }
};

export const validateBodyWeight = (value, unit) => {
  if (validateRequired(value)) {
    return "Please enter body weight";
  }
  const min = unit === LB ? MIN_CLIENT_WEIGHT_LB : MIN_CLIENT_WEIGHT_KG;
  const max = unit === LB ? MAX_CLIENT_WEIGHT_LB : MAX_CLIENT_WEIGHT_KG;
  if (value < min || value > max) {
    return `Body weight should be ≥ ${min + unit} and ≤ ${max + unit}`;
  }
};

/**
 * @param {string} part
 * @param {number} min
 * @param {number} max
 * @return {(value: string) => string | void}
 */
export const validateBodyPart =
  (part, min = 0, max = 100) =>
  (value) => {
    if (validateRequired(value)) {
      return `Please enter ${part} value`;
    }
    if (value <= min || value > max) {
      return `${capitalize(part)} should be > ${min}mm and ≤ ${max}mm`;
    }
  };

export const validateEmail = (value) => {
  if (validateRequired(value)) {
    return "Please enter email";
  }
  const re = new RegExp(REGEXP_EMAIL);
  if (value && !re.test(value)) {
    return "Email is invalid";
  }
};

export const validatePassword = (value) => {
  if (validateRequired(value)) {
    return "Please enter password";
  }
};

export const validateBodyFat = (value) => {
  if (validateRequired(value)) {
    return "Please enter body fat %";
  }
  if (value < 1 || value > 80) {
    return "Body fat should be >1% and <80%";
  }
};

const amountNotification = `Range of values ${NUMBERS_OF_REPEAT_EXERCISE.MIN} - ${NUMBERS_OF_REPEAT_EXERCISE.MAX}`;

export const validateAmount = (value) => {
  if (
    (value || value === 0) &&
    (+value > NUMBERS_OF_REPEAT_EXERCISE.MAX ||
      +value < NUMBERS_OF_REPEAT_EXERCISE.MIN)
  ) {
    return amountNotification;
  }
};

export const validateRequiredAmount = (value) => {
  if (validateRequired(value)) {
    return "Please enter number of repeats";
  }
  if (validateAmount(value)) {
    return amountNotification;
  }
};

export const validateBench = (value, unit) => {
  const max = unit === KG ? MAX_OF_STRENGTH_TEST.KG : MAX_OF_STRENGTH_TEST.LB;
  if ((value && value < 20) || value === 0) {
    return "bench is too small";
  }
  if (value && value > max) {
    return "bench is too big";
  }
};

export const validateRequiredBench = (value, unit) => {
  if (validateRequired(value)) {
    return "insert bench weight";
  }
  return validateBench(value, unit);
};

export const validateSquat = (value, unit) => {
  const max = unit === KG ? MAX_OF_STRENGTH_TEST.KG : MAX_OF_STRENGTH_TEST.LB;
  if ((value && value < 20) || value === 0) {
    return "squat is too small";
  }
  if (value && value > max) {
    return "squat is too big";
  }
};

export const validateRequiredSquat = (value, unit) => {
  if (validateRequired(value)) {
    return "insert squat weight";
  }
  return validateSquat(value, unit);
};

export const validateDeadlift = (value, unit) => {
  const max = unit === KG ? MAX_OF_STRENGTH_TEST.KG : MAX_OF_STRENGTH_TEST.LB;
  if ((value && value < 20) || value === 0) {
    return "deadlift is too small";
  }
  if (value && value > max) {
    return "deadlift is too big";
  }
};

export const validateRequiredDeadlift = (value, unit) => {
  if (validateRequired(value)) {
    return "insert deadlift weight";
  }
  return validateDeadlift(value, unit);
};
