import lodash from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  clearCurrentPayment,
  loadCompanyPaymentList,
} from "../redux/paymentSlice";

const useGetCompanyInvoice = () => {
  const dispatch = useDispatch();
  const listByCompany = useSelector((state) => state.payment.listByCompany);
  const currentCompany = useSelector((state) => state.companies.currentCompany);

  useEffect(() => {
    if (lodash.isEmpty(listByCompany?.[currentCompany.id])) {
      dispatch(loadCompanyPaymentList(currentCompany.id));
    }
    return () => dispatch(clearCurrentPayment());
  }, []);
};

export default useGetCompanyInvoice;
