import { useFormikContext, Field } from "formik";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ToolsBlock.scss";

import Button from "../../../../../components/Button";
import { useToastr } from "../../../../../hooks/useToastr";
import {
  addProgramTemplate,
  clearSuccessfullyAddTemplate,
} from "../../../../../redux/programTemplatesSlice";
import { Context } from "../../ProgramTemplateForm";

import DeleteBlock from "./blocks/DeleteBlock";
import DuplicateBlock from "./blocks/DuplicateBlock";
import ReplaceBlock from "./blocks/ReplaceBlock";

const ToolsBlock = () => {
  const dispatch = useDispatch();
  const { isValid } = useContext(Context);
  const { values } = useFormikContext();

  const successfullyAddTemplate = useSelector(
    (state) => state.programTemplates.successfullyAddTemplate
  );

  useToastr({
    messages: "The template has been saved",
    deps: successfullyAddTemplate,
    cb: () => dispatch(clearSuccessfullyAddTemplate()),
  });

  const handleClick = () => {
    const body = { ...values, name: values.saveas_name };
    dispatch(addProgramTemplate({ body }));
  };

  return (
    <div className="tools-block">
      <div className="tools-block__form">
        <div className="tools-block__form-title">program description</div>
        <Field
          as="textarea"
          className="form-input tools-block__textarea"
          placeholder="(empty)"
          id="description"
          name="description"
          type="text"
          value={values.description || ""}
        />
      </div>
      <div className="tools-block__form">
        <div className="tools-block__form-title">save as template</div>
        <Field
          id="saveas_name"
          name="saveas_name"
          type="text"
          className="tools-block__input"
        />
        <div className="tools-block__button-wrapper">
          <Button
            type="button"
            className="tools-block__button"
            disabled={!isValid || !values.saveas_name?.length}
            onClick={handleClick}
          >
            save as
          </Button>
        </div>
      </div>
      <DuplicateBlock />
      <ReplaceBlock />
      <DeleteBlock />
    </div>
  );
};

export default ToolsBlock;
