import _ from "lodash";
import moment from "moment";

import {
  DATE_FORMAT_ON_BACKEND,
  DATE_FORMAT_ON_FRONTEND,
  FULL_MONTH_DATE_FORMAT_ON_FRONTEND,
  SHORT_DATE_DOTS_FORMAT_ON_FRONTEND,
  SHORT_DATE_FORMAT_ON_FRONTEND,
  FANCY_DATE_FORMAT_ON_FRONTEND,
  USER_TYPES,
  LONG_DATE_DOTS_FORMAT_ON_FRONTEND,
  UTC_FORMAT_ON_BACKEND,
  UTC_FORMAT_TO_BACKEND,
} from "../constants/common";
import { REGEXP_NOT_NUM, REGEXP_NOT_INT } from "../constants/regexp";
import {
  EDITED_TYPES,
  PROGRAM_REPS_EDIT,
  PROGRAM_WEIGHT_PERCENT_EDIT,
} from "../constants/templates";
import { FEMALE } from "../constants/user";

export const getFullName = (data) => {
  if (!_.isObject(data)) {
    return "";
  }
  if ("first_name" in data && "last_name" in data) {
    return `${data.first_name || ""} ${data.last_name || ""}`.trim();
  }
  if ("firstName" in data && "lastName" in data) {
    return `${data.firstName || ""} ${data.lastName || ""}`.trim();
  }
  return "";
};

export const isFemale = (data) => data?.gender?.toLowerCase() === FEMALE;

export const upperCaseFirst = (text) => {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
};

export const getIdArray = (list) => list.map((item) => item.id);

// TODO: перестать юзать класс и вынести просто функцию.
export class BasicHelper {
  static roundTo(number, precision = 0) {
    let a = Math.pow(10, precision);
    return Math.round(number * a) / a;
  }
}

export const filterList = (list, filter, fieldname = "name") => {
  const search = filter.toLowerCase().split(" ").join("");
  return list.filter((item) =>
    item[fieldname].toLowerCase().split(" ").join("").includes(search)
  );
};

export const prepareDateForBack = (val) =>
  moment(val, DATE_FORMAT_ON_FRONTEND).format(DATE_FORMAT_ON_BACKEND);

export const prepareDateForBackUTC = (val) =>
  moment(val, DATE_FORMAT_ON_FRONTEND).utc().format(UTC_FORMAT_TO_BACKEND);

export const prepareDateForFront = (val, replaceInvalidDate = true) => {
  const dateFromBackend = moment(val, DATE_FORMAT_ON_BACKEND);
  const isValidValue = dateFromBackend.isValid();
  if (replaceInvalidDate && !isValidValue) return "";
  return dateFromBackend.format(DATE_FORMAT_ON_FRONTEND);
};

export const prepareShortDateForFront = (val) =>
  moment(val, DATE_FORMAT_ON_BACKEND).format(SHORT_DATE_FORMAT_ON_FRONTEND);

export const prepareShortLocalDateForFront = (val) =>
  moment
    .utc(val, UTC_FORMAT_ON_BACKEND)
    .local()
    .format(SHORT_DATE_FORMAT_ON_FRONTEND);

export const prepareFullMonthDateForFront = (val) =>
  moment(val, DATE_FORMAT_ON_BACKEND).format(
    FULL_MONTH_DATE_FORMAT_ON_FRONTEND
  );

export const prepareShortDateDotsForFront = (val) =>
  moment(val, DATE_FORMAT_ON_BACKEND).format(
    SHORT_DATE_DOTS_FORMAT_ON_FRONTEND
  );

export const prepareShortLocalDateDotsForFront = (val) =>
  moment(val, UTC_FORMAT_ON_BACKEND)
    .local()
    .format(SHORT_DATE_DOTS_FORMAT_ON_FRONTEND);

export const prepareFancyDateForFront = (val) =>
  moment(val, DATE_FORMAT_ON_BACKEND)
    .format(FANCY_DATE_FORMAT_ON_FRONTEND)
    .toLowerCase();

export const prepareLocalFancyDateForFront = (val) =>
  moment(val, UTC_FORMAT_ON_BACKEND)
    .local()
    .format(FANCY_DATE_FORMAT_ON_FRONTEND)
    .toLowerCase();

export const prepareShortDateDotsForFrontFromLong = (val) =>
  moment(val, LONG_DATE_DOTS_FORMAT_ON_FRONTEND).format(
    SHORT_DATE_DOTS_FORMAT_ON_FRONTEND
  );

// юзаем для подготовки данных сущности для вставки в инит значения формика
export const deleteTrashFieldForFormik = (data) => {
  data = _.cloneDeep(data);
  delete data?.created_at;
  delete data?.updated_at;
  delete data?.is_active;
  delete data?.is_deleted;
  delete data?.id;
  delete data?.body;
  data = setBlankInsteadOfNULL(data);
  data = numberToString(data);
  return data;
};

export const deleteTrashFieldForClientFormik = (data) => {
  data = _.cloneDeep(data);
  return {
    gender: data.gender,
    mass_unit: data.mass_unit,
    company: data.company,
    trainers: data.trainers,
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    birth: data.birth,
    height: data.height,
    phone: data.phone,
    password: data.password,
  };
};

export const prepareClientDataForSend = (sendData) => {
  if (sendData?.birth) {
    sendData.birth = prepareDateForBack(sendData.birth);
  }
  if (sendData?.company) {
    sendData.company = sendData.company.id;
  }
  if (sendData?.trainers) {
    sendData.trainers = getIdArray(sendData.trainers.filter(Boolean));
  }
  sendData = setNullInsteadOfBlank(sendData);
  return sendData;
};

export const prepareTrainersForSend = (sendData, values) => {
  if (!!sendData?.trainers) {
    sendData.trainers = values.trainers;
  } // нужно посылать весь список тренеров, не только измененных
  return sendData;
};

export const clearEmptyField = (data) => {
  Object.keys(data).map((key) => {
    if (
      !_.isBoolean(data[key]) &&
      (_.isNull(data[key]) || _.isUndefined(data[key]) || data[key] === "")
    ) {
      delete data[key];
    }
  });
  return data;
};

/**
 * checks if val is object
 *
 * @param val
 * @return {boolean}
 */
export const isObject = (val) =>
  ["object", "function"].includes(typeof val) && val !== null;

/**
 * checks if object's fields don't have any ""/null/undefined
 *
 * @param {Object} obj
 * @return {boolean}
 */
export const isObjectFulfilled = (obj) => {
  return !Object.values(obj).some(
    (val) => val === "" || _.isNull(val) || _.isUndefined(val)
  );
};

/**
 * returns true if field exists somewhere in obj
 * @param {object} obj
 * @param {string} field
 * @returns {boolean}
 */
export const checkNestedField = (obj, field) => {
  if (field in obj) {
    return true;
  }

  return Object.values(obj)
    .filter(isObject)
    .some((objVal) => checkNestedField(objVal, field));
};

export const setBlankInsteadOfNULL = (data) => {
  data = _.cloneDeep(data);
  Object.keys(data).map((key) => {
    if (_.isNull(data[key])) {
      data[key] = "";
    }
  });
  return data;
};

export const setNullInsteadOfBlank = (data) => {
  data = _.cloneDeep(data);
  Object.keys(data).map((key) => {
    if (data[key] === "") {
      data[key] = null;
    }
  });
  return data;
};

export const numberToString = (data) => {
  data = _.cloneDeep(data);
  Object.keys(data).map((key) => {
    if (typeof data[key] === "number") {
      data[key] = data[key].toString();
    }
  });
  return data;
};

// https://gist.github.com/Yimiprod/7ee176597fef230d1451
// сравнение двух объектов и возврат разницы между ними.
// юзаем когда изменяем сущность и надо слать на бек только те поля,
// что изменились
export const getDifferenceField = (object, base) => {
  const changes = (object, base) => {
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  };
  return changes(object, base);
};

/** @param {string} string */
export const onlyNumbers = (string) =>
  string.replace(new RegExp(REGEXP_NOT_NUM), "");

/** @param {string} string */
export const onlyIntNumbers = (string) =>
  string.replace(new RegExp(REGEXP_NOT_INT), "");

/**
 * @param {string} string
 * @param {number} minValue
 * @param {number} maxValue
 */
export const validateIntValue = (string, minValue, maxValue) => {
  const result = string.replace(new RegExp(REGEXP_NOT_INT), "");
  if (result !== "" && (+result < minValue || +result > maxValue)) return "";
  return result;
};

/**
 * @param {string} string
 * @param {string} type
 */
export const editProgramsValue = (string, type) => {
  const isWeight = type === EDITED_TYPES.WEIGHT;
  const minValue = isWeight
    ? PROGRAM_WEIGHT_PERCENT_EDIT.MIN
    : PROGRAM_REPS_EDIT.MIN;
  const maxValue = isWeight
    ? PROGRAM_WEIGHT_PERCENT_EDIT.MAX
    : PROGRAM_REPS_EDIT.MAX;
  return validateIntValue(string, minValue, maxValue);
};

/**
 * perms filter for items
 * @param {string} userType
 * @param {string} userId
 * @param {string} userCompanyId
 * @returns {(function(*): boolean)|{(): boolean, (): boolean}}
 */
export const permsFilter = (userType, userId, userCompanyId) =>
  userType === USER_TYPES.TRAINER
    ? (e) => e.owner_id === userId
    : userType === USER_TYPES.COMPANY_ADMIN
    ? (e) => e.company_id === userCompanyId
    : userType === USER_TYPES.SUPERUSER
    ? (e) => userId === e.owner_id
    : () => false;

export const createPermittedIdList = (
  list,
  userType,
  userId,
  userCompanyId
) => {
  if (!Array.isArray(list) || list.length === 0) return [];
  return list
    .filter(permsFilter(userType, userId, userCompanyId))
    .map((e) => e.id);
};

export const sortArrayOfObjectsByFieldValue = (arr, fieldName) => {
  return [...arr].sort((a, b) => a[fieldName] - b[fieldName]);
};

/**
 * @param {number} val
 * @param {number[]} arr
 * @returns {number}
 */
export const selectClosestInArray = (val, arr) => {
  return arr.reduce((prev, curr) => {
    return Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev;
  }, val);
};

/**
 * increment from startValue (-1 by default) until conditionCallback(val) is true
 * @param {(number) => boolean} conditionCallback
 * @param {number?} startValue
 * @returns {number}
 */
export const incToCondition = (conditionCallback, startValue, step = 1) => {
  let inc = startValue || -1;
  while (!conditionCallback(inc)) inc += step;
  return inc;
};

/**
 * @param {number} value
 * @param {number} cap
 * @returns {number}
 */
export const capBy = (value, cap) => {
  if (value > cap) {
    return cap;
  }
  return value;
};

/**
 * 1st, 2nd, 3rd, 4th, etc.
 * @param {number} num
 * @returns {string}
 */
export const ordinalSuffixOf = (num) => {
  const dec = num % 10;
  const cen = num % 100;

  if (dec === 1 && cen !== 11) {
    return `${num}st`;
  }
  if (dec === 2 && cen !== 12) {
    return `${num}nd`;
  }
  if (dec === 3 && cen !== 13) {
    return `${num}rd`;
  }
  return `${num}th`;
};

/**
 * best, 2nd best, 3rd best, 4th best, etc.
 * @param {number} num
 * @returns {string}
 */
export const ordinalRankOf = (num) =>
  `${num !== 1 ? `${ordinalSuffixOf(num)} ` : ""}best`;

export const exerciseSetErrorsSorting = (a, b) => {
  if (a.dayIdx !== b.dayIdx) return +a.dayIdx - +b.dayIdx;
  if (a.workoutIdx !== b.workoutIdx) return +a.workoutIdx - +b.workoutIdx;
  if (a.exerciseIdx !== b.exerciseIdx) return +a.exerciseIdx - +b.exerciseIdx;
  if (a.setIdx !== b.setIdx) return +a.setIdx - +b.setIdx;
  return 0;
};

/**
 * return number hash of a string
 * @param {string} str
 * @returns {number}
 */
export const getHash = (str) => {
  return str
    .split("")
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0
    );
};

/**
 * return hash of a string
 * @param {string} str
 * @returns {string}
 */
export const getHashString = (str) => {
  return String(getHash(str));
};

/**
 * get string of the updated URL search with new param value
 * @param {string} initialSearch
 * @param {string} paramName
 * @param {any} value
 * @returns {string}
 */
export const getUpdatedLocationSearch = (initialSearch, paramName, value) => {
  const params = new URLSearchParams(initialSearch);
  if (value === null || value === undefined || value === "") {
    params.delete(paramName);
  } else {
    params.set(paramName, value);
  }
  params.sort();
  return params.toString();
};

export const isPrevOrNextPageExist = ({ page, totalCount, next, prev }) => {
  if (prev) {
    if (page > 1) {
      return false;
    } else {
      return true;
    }
  }
  if (next) {
    return totalCount - page * 50 < 0;
  }
};
