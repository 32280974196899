import { useSelector } from "react-redux";

import { MAX_MOBILE_SCREEN_WIDTH } from "../constants/common";

const useIsSmallScreen = (
  size = MAX_MOBILE_SCREEN_WIDTH.SHOW_WIDGET_IN_DRAWER
) => {
  const screenWidth = useSelector((state) => state.window.size.screenWidth);
  return (screenWidth ?? window.innerWidth) <= size;
};

export default useIsSmallScreen;
