import lodash from "lodash";
import PropTypes from "prop-types";
import React from "react";
import "./InvoiceDetails.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { USER_TYPES } from "../../../../constants/common";
import { getFullName, prepareFullMonthDateForFront } from "../../../../helpers";
import convertCentsToDollars from "../../../../helpers/convertCentsToDollars";
import { loadPaymentInvoiceDays } from "../../../../redux/paymentSlice";
import Spinner from "../../../Spinner";
import Widget from "../../../widget/Widget";

const InvoiceDetails = ({ onClickBack }) => {
  const dispatch = useDispatch();
  const currentPayment = useSelector((state) => state.payment.currentPayment);
  const listByDays = useSelector((state) => state.payment.listByDays);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchMyAPI() {
      if (lodash.isEmpty(listByDays?.[currentPayment.id])) {
        await dispatch(loadPaymentInvoiceDays(currentPayment.id));
      }
      setLoading(false);
    }
    fetchMyAPI();
  }, []);
  useEffect(() => {
    if (!lodash.isEmpty(listByDays?.[currentPayment.id])) {
      setInvoiceDetails(listByDays?.[currentPayment.id]);
      return;
    }
    setInvoiceDetails(null);
  }, [listByDays, currentPayment]);
  return (
    <Widget>
      <Widget.Header className="invoice-details__header">
        <Widget.Title>
          {prepareFullMonthDateForFront(currentPayment.start_date)}
        </Widget.Title>
        <button className="btn btn-primary" onClick={onClickBack}>
          Back
        </button>
      </Widget.Header>
      <Widget.Body>
        {loading && <Spinner />}
        {!loading && (
          <table className="invoice-details__table invoice-details__table-invoice">
            <tbody>
              {currentPayment.user_type === USER_TYPES.CLIENT && (
                <tr>
                  <td>days account active</td>
                  <td>{currentPayment.active_days_total}</td>
                </tr>
              )}
              <tr>
                <td>invoice total</td>
                <td>$ {convertCentsToDollars(currentPayment.invoice_total)}</td>
              </tr>
              {!!currentPayment.discount && currentPayment.discount > 0 && (
                <tr>
                  <td className="pl-20">discount</td>
                  <td>$ {convertCentsToDollars(currentPayment.discount)}</td>
                </tr>
              )}
              <tr>
                <td className="pl-20">received</td>
                <td>$ {convertCentsToDollars(currentPayment.received)}</td>
              </tr>
              <tr>
                <td className="invoice-row-border">balance due</td>
                <td className="invoice-row-border">
                  $ {convertCentsToDollars(currentPayment.owing)}
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {!loading && currentPayment.user_type !== "Client" && (
          <table className="invoice-details__table invoice-details__table-trainers">
            <thead>
              <tr>
                <td>client name</td>
                <td>active days</td>
              </tr>
            </thead>
            <tbody>
              {!lodash.isEmpty(invoiceDetails.trainers) &&
                invoiceDetails.trainers.map((info) => (
                  <React.Fragment key={info.trainer.id}>
                    <tr>
                      <td colSpan="2" className="table-trainers__coach-name">
                        coach: {getFullName(info.trainer)}
                      </td>
                    </tr>
                    {info.clients.map((client) => (
                      <tr key={client.id}>
                        <td className="table-trainers__client-name">
                          {getFullName(client.client)}
                        </td>
                        <td className="table-trainers__client-active">
                          {client.active_days}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              {!lodash.isEmpty(invoiceDetails.no_trainer_clients) && (
                <React.Fragment>
                  <tr>
                    <td colSpan="2" className="table-trainers__coach-name">
                      No coach
                    </td>
                  </tr>
                  {invoiceDetails.no_trainer_clients.map((client) => (
                    <tr key={client.id}>
                      <td className="table-trainers__client-name">
                        {getFullName(client.client)}
                      </td>
                      <td className="table-trainers__client-active">
                        {client.active_days}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              )}
              <tr className="table-trainers__footer">
                <td>total days</td>
                <td>{currentPayment.active_days_total}</td>
              </tr>
            </tbody>
          </table>
        )}
      </Widget.Body>
    </Widget>
  );
};

InvoiceDetails.propTypes = {
  onClickBack: PropTypes.func.isRequired,
};

export default InvoiceDetails;
