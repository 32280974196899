import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../../../../components/Button";
import { CLIENT_PERM } from "../../../../../../../constants/clientInfo";
import { MAX_OF_PB } from "../../../../../../../constants/common";
import { KG } from "../../../../../../../constants/unit";
import useCheckClientPermission from "../../../../../../../hooks/useCheckClientPermission";
import { updatePb } from "../../../../../../../redux/programsSlice";
import "./PbInput.scss";

const PbInput = ({
  weight: realWeight,
  massUnit,
  onOpen,
  isOpen,
  clientId,
  exerciseId,
  isCalculated,
}) => {
  const weight = !isCalculated ? realWeight : null;

  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);
  const [isError, setError] = useState(false);
  const [pbValue, setPbValue] = useState(weight);

  const loading = useSelector((state) => state.programs.isSavingPb);

  const isCanChangePb = useCheckClientPermission(CLIENT_PERM.CAN_CHANGE_PB);

  useEffect(() => {
    if (!isOpen) {
      setEditing(false);
    }
  }, [isOpen]);

  useEffect(() => {
    setPbValue(weight);
  }, [weight]);

  const openEditing = (e) => {
    e.stopPropagation();
    if (!isCanChangePb) return;
    setEditing(true);
    onOpen();
  };

  const stopClosing = (e) => {
    e.stopPropagation();
  };

  const onChange = (e) => {
    const { value } = e.target;
    const max = massUnit === KG ? MAX_OF_PB.KG : MAX_OF_PB.LB;
    setError(value <= 0 || value > max);
    setPbValue(value);
  };

  const handleSubmit = () => {
    if (isError) return;
    setError(false);

    const body = {
      pb: [
        {
          exercise_id: exerciseId,
          pb_value: pbValue,
          is_calculate: false,
        },
      ],
    };
    dispatch(updatePb({ id: clientId, body })).then((res) => {
      switch (res.meta.requestStatus) {
        case "fulfilled":
          setEditing(false);
          break;
        case "rejected":
          setPbValue(weight);
          break;
        default:
          break;
      }
    });
  };

  return (
    <div className="training-day__exercise-row__pb">
      <div className="pb-input" onClick={stopClosing}>
        {editing && isOpen ? (
          <>
            <input
              className={cn("pb-input__input", isError && "pb-input__error")}
              type="number"
              value={pbValue}
              autoFocus={true}
              onChange={onChange}
            />
            <Button
              className="pb-input__button"
              onClick={handleSubmit}
              disabled={loading || isError}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </>
        ) : (
          <span className="pb-input__text" onClick={openEditing}>
            {weight !== null ? `${weight}${massUnit}` : "no pb"}
          </span>
        )}
      </div>
    </div>
  );
};

PbInput.propTypes = {};

export default PbInput;
