import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  AUTH,
  PROFILE,
  CLIENT,
  BODY_TEST_RESULT_PAGE_SLUG,
  DIET,
  TRAINING_PROGRAM_SLUG,
} from "../../../constants/router";
import { resetStore } from "../../../redux";
import { logoutClient } from "../../../redux/authSlice";

const ClientMenu = ({
  clientId,
  clickHandler,
  refreshClick,
  isSmallScreen,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logoutHandler = () => {
    dispatch(resetStore());
    dispatch(logoutClient());
    history.push(AUTH.root);
  };

  return (
    <>
      <div onClick={clickHandler} className="menu-item">
        <Link to={PROFILE.root} className="tools">
          my profile
        </Link>
      </div>
      <div onClick={clickHandler} className="menu-item">
        <Link
          to={CLIENT.root + clientId + BODY_TEST_RESULT_PAGE_SLUG}
          className="tools"
        >
          body
        </Link>
      </div>
      <div onClick={clickHandler} className="menu-item">
        <Link to={CLIENT.root + clientId + DIET} className="tools">
          diet
        </Link>
      </div>
      <div onClick={clickHandler} className="menu-item">
        <Link
          to={CLIENT.root + clientId + TRAINING_PROGRAM_SLUG}
          className="tools"
        >
          training
        </Link>
      </div>
      <div className="menu-item" onClick={logoutHandler}>
        logout
      </div>
      {isSmallScreen && (
        <div onClick={refreshClick} className="menu-item">
          refresh
        </div>
      )}
    </>
  );
};

export default React.memo(ClientMenu);
