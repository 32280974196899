import cn from "classnames";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import WidgetBody from "../../../../../../../components/widget/WidgetBody";
import { CLIENT_PERM } from "../../../../../../../constants/clientInfo";
import useCheckClientPermission from "../../../../../../../hooks/useCheckClientPermission";
import ExerciseRow from "../ExerciseRow/ExerciseRow";

const renderData = (data) => {
  return data.exercises.reduce(
    (acc, e) => `${acc} ${e.name} - ${e.sets.length} sets;`,
    ""
  );
};

const WORKOUT_SCROLL_OFFSET = -90;

const Workout = ({
  data,
  workoutIdx,
  dayIdx,
  isShowWorkoutPrefix,
  onToggleShow,
  setRightWidgetPerformanceAndHistory,
}) => {
  const currentProgramIdx = useSelector(
    (state) => state.programs.currentProgramIdx
  );
  const currentDayIdx = useSelector((state) => state.programs.currentDayIdx);
  const currentWorkoutIdx = useSelector(
    (state) => state.programs.currentWorkoutIdx
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isPbEditOpen, setIsPbEditOpen] = useState(false);
  const isCanChangePb = useCheckClientPermission(CLIENT_PERM.CAN_CHANGE_PB);

  // const isMobileVersion = useIsSmallScreen(MAX_MOBILE_SCREEN_WIDTH.MOBILE_VIEW_ON_TRAINING)

  const workoutRef = useRef(null);

  useEffect(() => {
    if (currentDayIdx === dayIdx && currentWorkoutIdx === workoutIdx) {
      // if (!isMobileVersion) {
      //   setIsOpen(true);
      //   onToggleShow(isOpen);
      // }
      workoutRef.current.scrollIntoView();
      window.scrollBy(0, WORKOUT_SCROLL_OFFSET);
    } else {
      setIsOpen(false);
    }
  }, [currentProgramIdx]);

  const handleOpenPbEdit = () => setIsPbEditOpen(true);
  const toggleOpen = () => {
    isPbEditOpen ? setIsPbEditOpen(false) : setIsOpen(!isOpen);
    onToggleShow(isOpen);
  };

  return (
    <div ref={workoutRef} className="training-day__body-wrapper">
      <WidgetBody
        className={cn("training-day__body", {
          "training-day__body--opened": isOpen,
          "training-day__body--disabled": !isCanChangePb,
        })}
        onClick={toggleOpen}
      >
        {!isOpen && (
          <span>{`${
            isShowWorkoutPrefix ? `Workout ${workoutIdx + 1}: ` : ""
          }${renderData(data)}`}</span>
        )}
        {isOpen && (
          <>
            {isShowWorkoutPrefix ? (
              <div className="training-day__workout-prefix">
                workout {workoutIdx + 1}
              </div>
            ) : null}
            <div className="training-day__body-header">
              <span>pb</span>
              <span>exercise</span>
              <span>sets</span>
            </div>
            <div className="training-day__exercise-rows">
              {data.exercises.map((exercise, idx) => (
                <ExerciseRow
                  setRightWidgetPerformanceAndHistory={
                    setRightWidgetPerformanceAndHistory
                  }
                  key={idx}
                  data={exercise}
                  dayIdx={dayIdx}
                  workoutIdx={workoutIdx}
                  exerciseIdx={idx}
                  isPbEditOpen={isPbEditOpen}
                  onPbEditOpen={handleOpenPbEdit}
                />
              ))}
            </div>
          </>
        )}
      </WidgetBody>
    </div>
  );
};

export default Workout;
