import { faClone, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import cn from "classnames";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  MAX_MOBILE_SCREEN_WIDTH,
  USER_TYPES,
} from "../../../../../../../constants/common";
import useIsSmallScreen from "../../../../../../../hooks/useIsSmallScreen";
import useIsUserType from "../../../../../../../hooks/useIsUserType";
import {
  onAddDiet,
  onChangeSelectIndex,
  onCopyDiet,
  onDeleteDiet,
} from "../../../../../../../redux/dietPageSlice";
import DietTableSelect from "../DietTableSelect";

const DietTabHeader = ({ dietIndex }) => {
  const dispatch = useDispatch();
  const isSmallScreen = useIsSmallScreen();
  const screenWidth = useSelector((state) => state.window.size.screenWidth);
  const selectedDietIndex = useSelector(
    (state) => state.dietPage.selectedDietIndex
  );
  const isDoubleDiet = useSelector((state) => state.dietPage.isDoubleDiet);

  const showAllSelects =
    screenWidth > MAX_MOBILE_SCREEN_WIDTH.SHOW_DIET_DAYS_SELECTOR;
  const showSelect = showAllSelects || dietIndex === selectedDietIndex;
  const isClient = useIsUserType(USER_TYPES.CLIENT);

  const changeCurrentDietHandler = () =>
    dispatch(onChangeSelectIndex(dietIndex));

  const removeDietHandler = (e) => {
    e.stopPropagation();
    dispatch(onDeleteDiet());
  };

  return (
    <div
      className={cn("diet-tab-header", {
        "firefox-right-padding":
          isSmallScreen && dietIndex === 1 && dietIndex === selectedDietIndex,
        "diet-tab-header--unactive": selectedDietIndex !== dietIndex,
        "diet-tab-header--mobile": isSmallScreen,
      })}
      onClick={changeCurrentDietHandler}
    >
      <div className="diet-tab-header__item">
        <span
          style={{
            color:
              selectedDietIndex === dietIndex ? "" : "rgba(255, 255, 255, 0.6)",
            fontSize: isSmallScreen && "1.2rem",
          }}
        >
          diet
          {isDoubleDiet
            ? ` ${dietIndex + 1}${showSelect ? ":" : ""}`
            : ": total daily intake"}
        </span>
      </div>
      <Box display="flex" alignItems="center">
        {isDoubleDiet && showSelect && (
          <DietTableSelect
            dietIndex={dietIndex}
            showAllSelects={showAllSelects}
          />
        )}
        {dietIndex !== 0 && (
          <div style={{ marginLeft: "5px" }}>
            <button
              onClick={removeDietHandler}
              className="remove-btn diet-remove-button"
              hidden={isClient}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        )}
      </Box>
    </div>
  );
};

const DietTableTabs = () => {
  const dispatch = useDispatch();
  const currentDiets = useSelector((state) => state.dietPage.diets);
  const isDoubleDiet = useSelector((state) => state.dietPage.isDoubleDiet);
  const isClient = useIsUserType(USER_TYPES.CLIENT);
  if (currentDiets.includes(null)) {
    return null;
  }
  const isEmptyDiet = !currentDiets[0].foods.length;
  return (
    <div className="d-flex">
      {currentDiets.map((diet, index) => (
        <DietTabHeader key={index} dietIndex={index} />
      ))}
      {!isClient && !isDoubleDiet && (
        <div className="diet-add-div">
          <div style={{ marginRight: "5px" }}>
            <button
              className="diet-add-button"
              onClick={() => {
                dispatch(onAddDiet());
              }}
              disabled={isEmptyDiet}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
          <div style={{ marginLeft: "5px" }}>
            <button
              className="diet-add-button"
              onClick={() => {
                dispatch(onCopyDiet());
              }}
              disabled={isEmptyDiet}
            >
              <FontAwesomeIcon className="diet-clone-icon" icon={faClone} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default memo(DietTableTabs);
