import "./CompanyForm.scss";
import { Box, Grid } from "@material-ui/core";
import { Formik, Form, ErrorMessage } from "formik";
import lodash from "lodash";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { USER_TYPES } from "../../constants/common";
import {
  ADD_COMPANY_CONTACT_FORM,
  ADD_COMPANY_FORM,
  INACTIVE_DAYS_TEXT,
} from "../../constants/company";
import { deleteTrashFieldForFormik } from "../../helpers";
import { validateDeactivationPeriodRequired } from "../../helpers/validators";
import useIsUserType from "../../hooks/useIsUserType";
import { editCompany } from "../../redux/companiesSlice";
import FormCheckbox from "../FormCheckbox";
import FormField from "../FormField/FormField";
import RequiredFieldsWarning from "../RequiredFieldsWarning";
import { parseCurrencyFieldValue } from "../../helpers/payment";
import convertCentsToDollars from "../../helpers/convertCentsToDollars";

const prepareDataForFormik = (value) => {
  value = lodash.cloneDeep(value);
  value = deleteTrashFieldForFormik(value);
  value.company_admin = deleteTrashFieldForFormik(value.company_admin);
  if (value.invoice_maximum) {
    value.invoice_maximum = convertCentsToDollars(value.invoice_maximum);
  }
  return value;
};

const CompanyForm = ({
  onSubmit,
  isEditPage,
  initialValues,
  children,
  isAutoSave,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.companies.loading);
  const company = useSelector((state) => state.companies.currentCompany);
  const isSuperuser = useIsUserType(USER_TYPES.SUPERUSER);
  initialValues = prepareDataForFormik(initialValues);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={isAutoSave}
      validate={(values) => {
        if (isEditPage && !values.company_admin.password) {
          return;
        }
        if (
          values.company_admin.password !== values.company_admin.repeat_password
        ) {
          return { company_admin: { repeat_password: "error" } };
        }
      }}
      onSubmit={(values) => {
        const data = lodash.cloneDeep(values);
        if (
          isSuperuser &&
          data.invoice_maximum !== initialValues.invoice_maximum
        ) {
          data.invoice_maximum = parseCurrencyFieldValue(data.invoice_maximum);
        }
        onSubmit && !isEditPage && onSubmit(data);
        onSubmit && isEditPage && onSubmit(data, initialValues);
      }}
    >
      {({ setFieldValue }) => {
        const onCheckboxChange = async (fieldName, value) => {
          const request = await dispatch(
            editCompany({ id: company.id, body: { [fieldName]: value } })
          );
          if (request.meta.requestStatus === "fulfilled") {
            setFieldValue(fieldName, value);
          }
        };
        return (
          <Form>
            <Grid container spacing={1}>
              {ADD_COMPANY_FORM.map((field) => (
                <Grid key={field.name} item xs={12} sm={6} className="mb-15">
                  <FormField
                    name={field.name}
                    validate={
                      isEditPage && field.type === "password"
                        ? null
                        : field?.validate
                    }
                    inputDivClass={field.inputDivClass}
                    label={
                      field.isRequired && !isEditPage
                        ? `${field.label} *`
                        : field.label
                    }
                    sublabel={field.sublabel}
                    type={field.type}
                    disabled={loading}
                    submitOnBlur={isAutoSave}
                  />
                  <ErrorMessage
                    component="div"
                    name={field.name}
                    className="auth-error"
                  />
                </Grid>
              ))}
              {isEditPage && (
                <Grid item sm={4}>
                  <Box display="flex" alignItems="flex-end">
                    <FormField
                      label="client auto-deactivation after"
                      name="auto_deactivation_period"
                      validate={validateDeactivationPeriodRequired}
                      inputDivClass="w-25 input-without-border"
                      type="number"
                      disabled={loading}
                      submitOnBlur={isAutoSave}
                    />{" "}
                    <span className="ml-5 mb-6">{INACTIVE_DAYS_TEXT}</span>
                  </Box>
                </Grid>
              )}
            </Grid>
            <h2>company contact</h2>
            <Grid container spacing={1}>
              {ADD_COMPANY_CONTACT_FORM.map((field) => (
                <Grid key={field.name} item sm={4} xs={6} className="mb-15">
                  <FormField
                    name={field.name}
                    pattern={field.pattern ? field.pattern : null}
                    inputDivClass={field.inputDivClass}
                    validate={field.validate}
                    label={
                      field.isRequired && !isEditPage
                        ? `${field.label} *`
                        : field.label
                    }
                    type={field.type}
                    disabled={loading}
                    submitOnBlur={isAutoSave}
                  />
                  <ErrorMessage
                    component="div"
                    name={field.name}
                    className="auth-error"
                  />
                </Grid>
              ))}
              <Grid className="mb-15" item md={12} xs={12}>
                <FormField
                  disabled={loading}
                  name="company_admin.notes"
                  label="notes"
                  component="textarea"
                  className="textarea-resize-vertical"
                  rows={3}
                  submitOnBlur={isAutoSave}
                />
              </Grid>
            </Grid>
            {isSuperuser && (
              <Box display="flex" justifyContent="space-between">
                <Grid item xs={12} sm={8} className="mb-15">
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    flexWrap={"wrap"}
                  >
                    {isEditPage && (
                      <FormCheckbox
                        disabled={loading}
                        isShowLabelFirst={true}
                        name={"has_manual_billing"}
                        label={"manual billing:"}
                        className={"company-form-checkbox"}
                        onChange={(e) =>
                          onCheckboxChange(
                            "has_manual_billing",
                            e.target.checked
                          )
                        }
                        submitOnBlur={isAutoSave}
                      />
                    )}
                    <FormCheckbox
                      disabled={loading}
                      isShowLabelFirst={true}
                      name={"can_add_coaches"}
                      label={"add coaches:"}
                      className={"company-form-checkbox"}
                      onChange={(e) => {
                        if (isEditPage) {
                          return onCheckboxChange(
                            "can_add_coaches",
                            e.target.checked
                          );
                        } else {
                          setFieldValue("can_add_coaches", e.target.checked);
                        }
                      }}
                      submitOnBlur={isAutoSave}
                    />
                    <FormField
                      disabled={loading}
                      name={"invoice_maximum"}
                      label={"maximum invoice:"}
                      type={"currency"}
                      inputDivClass={"invoice-maximum-field"}
                    />
                  </Box>
                </Grid>
                {!isEditPage && <RequiredFieldsWarning />}
              </Box>
            )}
            {children}
          </Form>
        );
      }}
    </Formik>
  );
};

CompanyForm.propTypes = {
  onSubmit: PropTypes.func,
  isEditPage: PropTypes.bool,
  initialValues: PropTypes.object,
  children: PropTypes.element,
  isAutoSave: PropTypes.bool,
  autoSaveFields: PropTypes.arrayOf(PropTypes.string),
};

CompanyForm.defaultProps = {
  isEditPage: false,
  isAutoSave: false,
  autoSaveFields: null,
  initialValues: {
    name: "",
    company_email: "",
    fax: "",
    work_phone: "",
    company_admin: {
      email: "",
      first_name: "",
      last_name: "",
      home: "",
      password: "",
      repeat_password: "",
      mobile: "",
      notes: "",
      phone: "",
    },
    invoice_maximum: "",
  },
};

export default CompanyForm;
