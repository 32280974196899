import { useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../../components/Spinner";
import Widget from "../../../../../components/widget/Widget";
import {
  PAYMENT_ACCOUNT_REQUEST_DELAY,
  PAYMENT_DESCRIPTION_INVOICES_1,
  PAYMENT_DESCRIPTION_INVOICES_2,
  PAYMENT_DESCRIPTION_NOCARD_1,
  PAYMENT_DESCRIPTION_NOCARD_2,
  USER_TYPE_COMPANY_ADMIN,
} from "../../../../../constants/payment";
import { useCallDebouncedCb } from "../../../../../hooks/useCallDebouncedCb";
import { store } from "../../../../../redux";
import {
  deleteStripePaymentAccount,
  loadStripePaymentAccount,
  setPaymentAccountLoadingAttempted,
  verifStripeIntent,
  verifyStripePaymentAccount,
  setStripeSuccessfullyAdded,
} from "../../../../../redux/companiesSlice";
import DeletePaymentAccount from "../DeletePaymentAccount";
import MonthlyChargesInfo from "../MonthlyChargesInfo";
import PayCurrentDue from "../PayCurrentDue";
import CheckoutForm from "./components/CheckoutForm";

const StripePaymentSidebar = () => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const user = useSelector((state) => state.user.user);
  const paymentAccountLoadingAttempted = useSelector(
    (state) => state.companies.paymentAccountLoadingAttempted
  );
  const last4 = useSelector((state) => state.companies.last4);
  const cardHolderName = useSelector((state) => state.companies.cardHolderName);
  const isPaymentRequestSuccessful = useSelector(
    (state) => state.companies.isPaymentRequestSuccessful
  );
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const isCardVerified = useSelector((state) => state.companies.isCardVerified);
  const companyId = currentCompany?.id;
  const error = useSelector((state) => state.companies.error);
  const isTaskFinished = useSelector((state) => state.companies.isTaskFinished);
  const debt = useSelector((state) => state.companies.debt);
  const stripeToken = useSelector((state) => state.companies.stripeToken);

  useEffect(() => {
    async function checkPaymentAcc() {
      if (
        currentCompany !== null &&
        !isCardVerified &&
        !paymentAccountLoadingAttempted &&
        user.type === USER_TYPE_COMPANY_ADMIN
      ) {
        const paymentAcc = await dispatch(
          loadStripePaymentAccount({ id: currentCompany.id })
        );
        const { payment_account_verified, last4 } = paymentAcc.payload;
        store.dispatch(setPaymentAccountLoadingAttempted(true));
        if (payment_account_verified === false && last4 !== null) {
          await dispatch(
            deleteStripePaymentAccount({
              id: currentCompany.id,
              forceDelete: true,
            })
          );
        }
      }
    }

    checkPaymentAcc();
  }, [currentCompany]);

  useEffect(() => {
    //init card load
    if (stripe && elements && stripeToken) { 
      dispatch(loadStripePaymentAccount({ id: currentCompany.id }));
      dispatch(setStripeSuccessfullyAdded(true));
    }
  }, [stripe, elements, stripeToken]);

  useCallDebouncedCb(
    (isTaskFinished, companyId) => {
      if (!isTaskFinished)
        dispatch(loadStripePaymentAccount({ id: companyId })).then();
    },
    [isTaskFinished, currentCompany.id],
    PAYMENT_ACCOUNT_REQUEST_DELAY,
    [isPaymentRequestSuccessful, isTaskFinished]
  );

  //checkout frames are in a race condition with react state
  const isHidingCreditCardForm = useMemo(
    () =>
      !(
        isPaymentRequestSuccessful &&
        isTaskFinished &&
        (last4 === null || cardHolderName === null)
      ),
    [isPaymentRequestSuccessful, isTaskFinished, last4, cardHolderName]
  );

  const isShowPayNowContent = debt > 0;

  return (
    <div className="widget-wrapper">
      <Widget>
        <Widget.Header>
          <Widget.Title>payment details</Widget.Title>
        </Widget.Header>
        <Widget.Body>
          {isPaymentRequestSuccessful && isTaskFinished && (
            <div className="payment-description__wrapper">
              {isHidingCreditCardForm ? (
                <span className="payment-description">
                  {PAYMENT_DESCRIPTION_INVOICES_1}
                  <span>{currentCompany.billing_day}th</span>
                  {PAYMENT_DESCRIPTION_INVOICES_2}
                </span>
              ) : (
                <span className="payment-description">
                  {PAYMENT_DESCRIPTION_NOCARD_1}
                  <span>{currentCompany.company_admin?.email}</span>
                  {PAYMENT_DESCRIPTION_NOCARD_2}
                </span>
              )}
            </div>
          )}
          <CheckoutForm isHidden={isHidingCreditCardForm} />
          {isPaymentRequestSuccessful && isTaskFinished ? (
            isHidingCreditCardForm && (
              <>
                {isShowPayNowContent && <PayCurrentDue isStripe />}
                <DeletePaymentAccount isStripe />
              </>
            )
          ) : (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          )}
        </Widget.Body>
      </Widget>
      <Widget>
        <Widget.Header>
          <Widget.Title>monthly charges</Widget.Title>
        </Widget.Header>
        <Widget.Body>
          <MonthlyChargesInfo />
        </Widget.Body>
      </Widget>
    </div>
  );
};

export default StripePaymentSidebar;
