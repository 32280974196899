import "../../../main.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import logo from "../../../assets/images/welcome.png";
import Copyright from "../../../components/CopyrightTermsPrivacy";
import { authClient } from "../../../redux/authSlice";
import {
  restorePassword,
  clearRestore,
} from "../../../redux/restorePasswordSlice";
import { setShowHeader, setShowMenu } from "../../../redux/windowSlice";

import AuthForm from "./AuthForm";
import ForgotPasswordForm from "./ForgotPasswordForm";

const AuthPage = () => {
  const [isForgotPassword, setForgotPassword] = useState(false);
  const [isAuthClicked, setAuthClicked] = useState(false);
  const switchForgotPassword = () => {
    dispatch(clearRestore());
    setForgotPassword(!isForgotPassword);
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const authStatus = useSelector((state) => state.auth.authStatus);

  useEffect(() => {
    if (authStatus && isAuthClicked) {
      dispatch(setShowHeader(true));
      dispatch(setShowMenu(false));
      history.push("/");
    }
  }, [authStatus]);

  useEffect(() => {
    dispatch(setShowHeader(false));
    dispatch(setShowMenu(false));
  }, []);

  const auth = (values) => {
    setAuthClicked(true);
    dispatch(authClient(values));
  };

  const forgotPassword = (values) => {
    dispatch(restorePassword(values));
  };

  return (
    <div>
      <div className="auth-main-block">
        <img alt="Logo" src={logo} className="page-auth-img" />
        {!isForgotPassword && (
          <AuthForm forgotPassword={switchForgotPassword} onSubmit={auth} />
        )}
        {isForgotPassword && (
          <ForgotPasswordForm
            back={switchForgotPassword}
            onSubmit={forgotPassword}
          />
        )}
      </div>
      <Copyright />
    </div>
  );
};

AuthPage.propTypes = {};

export default AuthPage;
