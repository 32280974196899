import {
  validatePhoneNumber,
  validateRequired,
  validateEmail,
} from "../helpers/validators";

export const ADD_COMPANY_FORM = [
  {
    key: "name",
    name: "name",
    isAdminField: false,
    label: "company name",
    isRequired: true,
    type: "text",
    validate: (value) => validateRequired(value, "Required"),
    inputDivClass: "w-100 input-without-border",
  },
  {
    key: "email",
    name: "company_admin.email",
    isAdminField: true,
    label: "email",
    sublabel: "(used for login)",
    isRequired: true,
    type: "email",
    validate: validateEmail,
    inputDivClass: "w-100 input-without-border",
  },
  {
    key: "password",
    name: "company_admin.password",
    isAdminField: true,
    label: "password",
    isRequired: true,
    type: "password",
    validate: (value) => validateRequired(value, "Required"),
    inputDivClass: "w-100 input-without-border",
  },
  {
    key: "repeat_password",
    name: "company_admin.repeat_password",
    isAdminField: true,
    label: "repeat password",
    isRequired: true,
    type: "password",
    validate: (value) => validateRequired(value, "Required"),
    inputDivClass: "w-100 input-without-border",
  },
  {
    key: "work_phone",
    name: "work_phone",
    isAdminField: false,
    isRequired: false,
    label: "work phone",
    type: "text",
    validate: (value) => validatePhoneNumber(value, "Work phone"),
    inputDivClass: "w-100 input-without-border",
  },
  {
    key: "fax",
    name: "fax",
    isAdminField: false,
    isRequired: false,
    label: "fax",
    type: "text",
    validate: validatePhoneNumber,
    inputDivClass: "w-100 input-without-border",
  },
];

export const ADD_COMPANY_CONTACT_FORM = [
  {
    key: "first_name",
    name: "company_admin.first_name",
    isAdminField: true,
    isRequired: false,
    label: "first name",
    type: "text",
    inputDivClass: "w-100 input-without-border",
  },
  {
    key: "last_name",
    name: "company_admin.last_name",
    isAdminField: true,
    isRequired: false,
    label: "last name",
    type: "text",
    inputDivClass: "w-100 input-without-border",
  },
  {
    key: "company_email",
    name: "company_email",
    isAdminField: false,
    isRequired: false,
    label: "email",
    type: "email",
    validate: validateEmail,
    inputDivClass: "w-100 input-without-border",
  },
  {
    key: "phone",
    name: "company_admin.phone",
    isAdminField: true,
    isRequired: false,
    label: "phone",
    type: "text",
    validate: (value) => validatePhoneNumber(value),
    inputDivClass: "w-100 input-without-border",
  },
  {
    key: "mobile",
    name: "company_admin.mobile",
    isAdminField: true,
    isRequired: false,
    label: "mobile",
    type: "text",
    validate: (value) => validatePhoneNumber(value, "Mobile"),
    inputDivClass: "w-100 input-without-border",
  },
  {
    key: "home",
    name: "company_admin.home",
    isAdminField: true,
    isRequired: false,
    label: "home",
    type: "text",
    validate: (value) => validatePhoneNumber(value, "Home"),
    inputDivClass: "w-100 input-without-border",
  },
];

export const COMPANY_TAB = {
  INFORMATION: "information",
  THEMES: "themes",
};

export const COMPANY_PROFILE_WIDGET = {
  INVOICES: "invoices",
  THEME: "theme",
  PAYMENT: "payment",
  STRIPE_PAYMENT: "stripe",
};

export const THEME_DIV_STYLE = {
  height: "175px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "center",
};

export const THEME_DROPZONE_PROPS = {
  maxFiles: 1,
  multiple: false,
  maxSize: 1048576,
  accept: ["image/jpeg", "image/png"],
};

export const COMPANY_IDS_WTIH_ACCESS_TO_STRIPE = [
  "61a622dca6070e25eb9cdbd6", // damon recomp
  "857f1f77bcf86cd799439011", // TEAM_INDOMITABLE
];

export const INACTIVE_DAYS_TEXT = "days of zero data request *";
