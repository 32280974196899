import { uniq } from "lodash";
import {
  ERROR_FIRST_IS_REQUIRED,
  ERROR_SECOND_IS_REQUIRED,
  ERROR_SECOND_MUST_BE_GREATER,
} from "../constants/templates";

/**
 * validate idx for range
 * @param {number} start
 * @param {number} end
 * @returns {string[]} error messages
 */
export const validateRangeIdx = (start, end) => {
  const errors = [];

  if (start < 1 || isNaN(start)) {
    errors.push(ERROR_FIRST_IS_REQUIRED);
  }
  if (end < 1 || isNaN(end)) {
    errors.push(ERROR_SECOND_IS_REQUIRED);
  } else if (start > end) {
    errors.push(ERROR_SECOND_MUST_BE_GREATER);
  }

  return errors;
};

export const notZero = (el) => el !== 0;

export const findSetByOldValue = (arr, value) =>
  arr.find(({ oldValue }) => oldValue === Number(value));

export const exercisesUniqValuesCreate = (list, filterCondition, type) => {
  return uniq(
    list
      .filter(filterCondition)
      .reduce(
        (acc, ex) => [
          ...acc,
          ...ex.sets.filter((set) => set[type]).map((set) => Number(set[type])),
        ],
        []
      )
  );
};

export const createValuesToReplaceUnsorted = (uniqValues, valuesToReplace) => {
  return uniqValues.reduce((acc, val) => {
    const alreadyAddItem = findSetByOldValue(valuesToReplace, val);
    if (
      alreadyAddItem &&
      +alreadyAddItem.oldValue === +alreadyAddItem.newValue
    ) {
      return [...acc, alreadyAddItem];
    }
    return [...acc, { oldValue: val, newValue: val }];
  }, []);
};
