import "./ActivityTracking.scss";
import { useState, createContext } from "react";

import { WIDGET_VIEW_TYPES } from "../../constants/activityTracking";
import Widget from "../widget/Widget";
import { useSelector } from "react-redux";
import { СlientsBody } from "./widgetBodies/СlientsBody/СlientsBody";
import { ClientBody } from "./widgetBodies/ClientBody/СlientBody";
import { CompaniesBody } from "./widgetBodies/СompaniesBody/СompaniesBody";
import { useSearchParam } from "../../hooks/useSearchParam";
import { useEffect } from "react";

const widgets = {
  [WIDGET_VIEW_TYPES.COMPANIES_BODY]: <CompaniesBody />,
  [WIDGET_VIEW_TYPES.CLIENTS_BODY]: <СlientsBody />,
  [WIDGET_VIEW_TYPES.CLIENT_BODY]: <ClientBody />,
};

export const WidgetContext = createContext(null);

const ActivityTracking = () => {
  const [widgetParam, setWidgetParam] = useSearchParam("widget");
  const [pageParam, setPageParam] = useSearchParam("page");
  const [clientListPage, setClientListPage] =
    useSearchParam("client-list-page");
  const [clientActivityPage, setActivityListPage] =
    useSearchParam("client-list-page");
  const [searchParam, setSearchParam] = useSearchParam("search");
  const [currentCompanyId, setCurrentCompanyId] = useSearchParam("company-id");
  const [currentClient, setCurrentClient] = useSearchParam("client");
  const [companyName, setCompanyName] = useSearchParam("company-name");

  const context = {
    pageParam,
    setPageParam,
    searchParam,
    setSearchParam,
    currentCompanyId,
    setCurrentCompanyId,
    setWidgetParam,
    widgetParam,
    currentClient,
    setCurrentClient,
    companyName,
    setCompanyName,
    clientListPage,
    setClientListPage,
    clientActivityPage,
    setActivityListPage,
  };

  useEffect(() => {
    if (widgetParam) {
      setWidgetParam(widgetParam, false);
    } else {
      setWidgetParam(WIDGET_VIEW_TYPES.COMPANIES_BODY, false);
    }
  }, []);

  return (
    <Widget>
      <Widget.Header>
        <Widget.Title>activity tracking</Widget.Title>
      </Widget.Header>
      <WidgetContext.Provider value={context}>
        <Widget.Body className="activity__content">
          {widgets[widgetParam]}
        </Widget.Body>
      </WidgetContext.Provider>
    </Widget>
  );
};

export default ActivityTracking;
