import cn from "classnames";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import VirtualList from "../../../../../../../components/list/VirtualList";
import { REDUCED_PROGRAMS_COUNT } from "../../../../../../../constants/training";
import {
  addProgram,
  loadHistory,
} from "../../../../../../../redux/programsSlice";

import AddProgramRow from "./AddProgramRow/AddProgramRow";

const AddProgramItem = ({ title, isUnderlined, data, clientId, perms }) => {
  const [isShowAll, setIsShowAll] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const handleTemplateClick = (id) => () => {
    const params = {
      id: match.params.id,
      body: {
        client_id: clientId,
        program_template_id: id,
      },
    };
    dispatch(addProgram(params)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(loadHistory({ id: clientId }));
      }
    });
  };

  const handleClick = () => setIsShowAll(!isShowAll);

  return (
    <div
      className={cn("add-program-item", {
        "add-program-item--underlined": isUnderlined,
      })}
    >
      <div className="add-program-item__title">{title}</div>
      {isShowAll ? (
        <VirtualList
          list={data}
          isDynamic
          row={(virtualRow) => {
            const item = data[virtualRow.index];
            return (
              <AddProgramRow
                key={item.id}
                data={item}
                onClick={handleTemplateClick(item.id)}
                isEditable={perms.includes(item.id)}
              />
            );
          }}
        />
      ) : (
        data
          .slice(0, REDUCED_PROGRAMS_COUNT)
          .map((item) => (
            <AddProgramRow
              key={item.id}
              data={item}
              onClick={handleTemplateClick(item.id)}
              isEditable={perms.includes(item.id)}
            />
          ))
      )}
      {data.length > REDUCED_PROGRAMS_COUNT && (
        <button
          type="button"
          onClick={handleClick}
          className="add-program-item__button"
        >
          show {isShowAll ? "less" : "all"}...
        </button>
      )}
    </div>
  );
};

export default AddProgramItem;
