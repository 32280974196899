import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setShowHeader, setShowMenu } from "../redux/windowSlice";

const useHideMenu = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  useLayoutEffect(() => {
    dispatch(setShowHeader(false));
    dispatch(setShowMenu(false));
    return () => {
      if (user) dispatch(setShowHeader(true));
    };
  }, []);
};

export default useHideMenu;
