import "./SaveDietSideBar.scss";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import Button from "../../../../../components/Button";
import Divider from "../../../../../components/Divider";
import FormField from "../../../../../components/FormField/FormField";
import Widget from "../../../../../components/widget/Widget";
import WidgetBody from "../../../../../components/widget/WidgetBody";
import {
  SIDEBAR_NAMES,
  TEMPLATE_ADDED_MESSAGE,
} from "../../../../../constants/diet";
import { prepareDietRequest } from "../../../../../helpers/diet";
import { useToastr } from "../../../../../hooks/useToastr";
import {
  addDietTemplates,
  clearSuccessfullyAddDietTemplate,
} from "../../../../../redux/dietTemplatesSlice";

const SaveDietSideBar = ({ setSideBar }) => {
  const dispatch = useDispatch();

  const diets = useSelector((state) => state.dietPage.diets);
  const isDoubleDiet = useSelector((state) => state.dietPage.isDoubleDiet);
  const successfullyDietTemplateAdded = useSelector(
    (state) => state.dietTemplates.successfullyDietTemplateAdded
  );

  useToastr({
    messages: TEMPLATE_ADDED_MESSAGE,
    deps: successfullyDietTemplateAdded,
    cb: () => dispatch(clearSuccessfullyAddDietTemplate()),
  });

  return (
    <Widget className="save-diet__block">
      <Widget.Header>
        <Widget.Title>add template</Widget.Title>
      </Widget.Header>
      <WidgetBody>
        <Formik
          initialValues={{
            template_name: "",
          }}
          onSubmit={async (values) => {
            await dispatch(
              addDietTemplates({
                name: values.template_name,
                ...prepareDietRequest(diets, isDoubleDiet),
              })
            );
            setSideBar(SIDEBAR_NAMES.TEMPLATES);
          }}
          validationSchema={Yup.object().shape({
            template_name: Yup.string().required(),
          })}
        >
          {() => (
            <Form>
              <FormField
                label="template name"
                name="template_name"
                type="text"
                isInline={false}
              />
              <Divider />
              <div className="save-diet__btn-wrapper">
                <Button type="submit" className="save-diet__btn">
                  save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </WidgetBody>
    </Widget>
  );
};

SaveDietSideBar.propTypes = {
  setSideBar: PropTypes.func,
};

export default SaveDietSideBar;
