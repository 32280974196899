import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { CLIENT } from "../../constants/router";
import { resetStore } from "../../redux";
import { logoutClient } from "../../redux/authSlice";

const DropDownElement = ({ item: { link, label }, onClick, id, disabled }) => {
  const dispatch = useDispatch();

  const pathname = id ? `${CLIENT.root}${id}${link}` : link;
  const click = () => {
    if (label === "logout") {
      dispatch(resetStore());
      dispatch(logoutClient());
    }
    onClick();
  };
  return disabled ? (
    <div key={link} className="dropdown-option--disabled">
      {label}
    </div>
  ) : (
    <Link
      key={link}
      onClick={click}
      to={(location) => ({ ...location, pathname })}
      className="dropdown-option"
    >
      {label}
    </Link>
  );
};

DropDownElement.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

DropDownElement.defaultProps = {
  disabled: false,
};

export default DropDownElement;
