import "./ClientsBody.scss";
import Button from "../../../Button";
import { useDispatch, useSelector } from "react-redux";
import { WidgetContext } from "../../ActivityTracking";
import { useContext } from "react";
import { WIDGET_VIEW_TYPES } from "../../../../constants/activityTracking";
import Search from "../../../Search/Search";
import { DELAY_FOR_SEARCH, ITEMS_PER_PAGE } from "../../../../constants/common";
import cn from "classnames";
import { useCallDebouncedCb } from "../../../../hooks/useCallDebouncedCb";
import { ReactComponent as NextPageArrow } from "../../../../assets/svg/next-page-arrow.svg";
import { ReactComponent as PrevPageArrow } from "../../../../assets/svg/prev-page-arrow.svg";
import PageLabel from "../../../list/PageLabel/PageLabel";
import { isPrevOrNextPageExist } from "../../../../helpers";
import {
  clearClientList,
  loadClientListThunk,
  setIsLoading,
} from "../../../../redux/clientListSlice";
import { useEffect } from "react";
import Spinner from "../../../../components/Spinner";

export const СlientsBody = () => {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clientList.clients);
  const isLoading = useSelector((state) => state.clientList.loading);
  const page = useSelector((state) => state.clientList.page);
  const totalCount = useSelector((state) => state.clientList.totalCount);
  const currentCompany = JSON.parse(localStorage.getItem("current-company"));
  const {
    searchParam,
    setSearchParam,
    setWidgetParam,
    widgetParam,
    clientListPage,
    setClientListPage,
    setPageParam,
  } = useContext(WidgetContext);

  useEffect(() => {
    if (widgetParam !== "clients-body") {
      setWidgetParam("clients-body", false);
    }
  }, []);

  const onClientClickHandler = async (e) => {
    e.stopPropagation();
    dispatch(setIsLoading());
    await localStorage.setItem(
      "user",
      JSON.stringify({
        id: e.currentTarget.id,
        name: e.currentTarget.textContent,
      })
    );
    await setPageParam(null, false);
    await setWidgetParam(WIDGET_VIEW_TYPES.CLIENT_BODY, true);
  };

  const setSearchValueHandler = async (e) => {
    setSearchParam(e.target.value, false);
  };

  useCallDebouncedCb(
    (search) => {
      dispatch(
        loadClientListThunk({
          companyId: currentCompany.id,
          search,
          page: clientListPage,
        })
      );
    },
    [searchParam, clientListPage],
    DELAY_FOR_SEARCH,
    [searchParam]
  );

  const moveToAnothePage = (direction) => {
    if (direction === "next") {
      dispatch(
        loadClientListThunk({
          companyId: currentCompany.id,
          page: page + 1,
          search: searchParam,
        })
      );
      setClientListPage(page + 1, false);
    }
    if (direction === "prev") {
      dispatch(
        loadClientListThunk({
          companyId: currentCompany.id,
          page: page - 1,
          search: searchParam,
        })
      );
      const newPage = page - 1 === 1 ? "" : page - 1;
      setClientListPage(newPage, false);
    }
  };

  const goBackHandler = () => {
    localStorage.removeItem("current-company");
    dispatch(clearClientList());
    setWidgetParam(WIDGET_VIEW_TYPES.COMPANIES_BODY, true);
  };

  return !isLoading ? (
    <>
      <div className="diet-side-ch">
        <table style={{ width: "100%" }} className="activityDropdown-list">
          <thead>
            <tr>
              <td colSpan="2" className="list-search-wrapper">
                <Search onChange={setSearchValueHandler} value={searchParam} />
              </td>
            </tr>
            <tr className="clients-body__head-container">
              <td>{currentCompany.name}</td>
              <td>{<Button onClick={goBackHandler}>back</Button>}</td>
            </tr>
            <tr className="clients-body__body-container">
              {clients?.length > 0 ? (
                clients.map((client) => (
                  <td
                    key={client.id}
                    className="clients-body__body-container_inner"
                    id={client.id}
                    onClick={onClientClickHandler}
                  >
                    <p className="clients-body__text">{`${client.first_name} ${client.last_name}`}</p>
                  </td>
                ))
              ) : (
                <td className="clients-body__body-container_inner">
                  <p className="clients-body__text">No results</p>
                </td>
              )}
            </tr>
            {totalCount > 50 && (
              <tr className="clients-body__nav-container">
                <td colSpan={4}>
                  <div className="list-arrow-wrapper">
                    <PrevPageArrow
                      width="25px"
                      heigth="25px"
                      id="backward"
                      className={cn("body-arrow", {
                        "body-arrow--disabled": isPrevOrNextPageExist({
                          page,
                          totalCount,
                          prev: true,
                        }),
                      })}
                      onClick={() => moveToAnothePage("prev")}
                    />
                    <PageLabel
                      page={page}
                      itemsCount={clients?.length}
                      totalCount={totalCount}
                      perPage={ITEMS_PER_PAGE}
                    />
                    <NextPageArrow
                      width="25px"
                      heigth="25px"
                      id="forward"
                      className={cn("body-arrow", {
                        "body-arrow--disabled": isPrevOrNextPageExist({
                          page,
                          totalCount,
                          next: true,
                        }),
                      })}
                      onClick={() => moveToAnothePage("next")}
                    />
                  </div>
                </td>
              </tr>
            )}
          </thead>
        </table>
      </div>
    </>
  ) : (
    <div className={"spinner-wrapper"}>
      <Spinner />
    </div>
  );
};
