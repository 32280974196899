import cn from "classnames";
import { useHistory } from "react-router";
import "./ClientProgressPage.scss";

import { ReactComponent as NextPageArrow } from "../../../assets/svg/next-page-arrow.svg";
import { ReactComponent as PrevPageArrow } from "../../../assets/svg/prev-page-arrow.svg";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";
import ProgressHeaders from "../../../components/progress/ProgressHeaders";
import ProgressValues from "../../../components/progress/ProgressValues";
import Widget from "../../../components/widget/Widget";

import { useDispatch, useSelector } from "react-redux";

import { BASE_COLUMNS_WIDTH, COLUMN_WIDTH } from "../../../constants/progress";

import { useCallback, useEffect, useRef, useState } from "react";

import { useContainerDimensions } from "../../../hooks/useContainerDimensions";
import { getClientProgress } from "../../../redux/clientSlice";
import { BODY_TEST_RESULT_PAGE_SLUG, CLIENT } from "../../../constants/router";

const ClientProgressPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const latest = useSelector((state) => state.client.latest);
  const progress = useSelector((state) => state.client.progress);
  const client = useSelector((state) => state.client.client);
  const [isReady, setReady] = useState(false);
  const [slicedProgress, setSlicedProgress] = useState([]);
  const [column, setColumn] = useState(1);
  const [maxColumn, setMaxColumn] = useState(1);
  const [prevPageExists, setPrevPageExists] = useState(false);
  const [nextPageExists, setNextPageExists] = useState(true);
  const name = client.first_name;
  const widgetRef = useRef(null);
  const { width: fullWidth } = useContainerDimensions(widgetRef);

  useEffect(() => {
    if (!latest || !progress) {
      dispatch(getClientProgress(client.id));
    }
  }, []);

  const changeTable = useCallback(() => {
    if (progress?.length - column < maxColumn) {
      setColumn(
        progress.length - maxColumn > 0 ? progress.length - maxColumn : 1
      );
    }

    setPrevPageExists(!(column === 1));
    setSlicedProgress(
      [...progress].reverse().slice(column, column + maxColumn)
    );
  }, [column, maxColumn, progress]);

  const calculateColumns = (width) => {
    return Math.floor((width - BASE_COLUMNS_WIDTH) / COLUMN_WIDTH);
  };

  useEffect(() => {
    if (Array.isArray(progress)) {
      const newMaxColumn = calculateColumns(fullWidth);
      if (maxColumn !== newMaxColumn) setMaxColumn(newMaxColumn);
      changeTable();
    }
    setReady(progress && latest);
  }, [progress, latest, changeTable, fullWidth]);

  useEffect(() => {
    if (Array.isArray(progress)) {
      setNextPageExists(progress.length - (column + maxColumn) > 0);
      changeTable();
    }
  }, [changeTable, column, isReady, maxColumn, progress]);

  const loadPrevPage = () => {
    if (prevPageExists) {
      setColumn(column - 1);
    }
  };
  const loadNextPage = () => {
    if (nextPageExists) {
      setColumn(column + 1);
    }
  };
  const onBackToBodyClick = () => {
    history.push(`${CLIENT.root}${client.id}${BODY_TEST_RESULT_PAGE_SLUG}`);
  };

  return (
    <div className="content-rhs content">
      <div ref={widgetRef} className="page-content d-flex w-100">
        <Widget>
          <Widget.Header className="client-progress-page__header">
            <Widget.Title>{name}'s progress</Widget.Title>
            <Button onClick={onBackToBodyClick}>back to body</Button>
          </Widget.Header>
          <div className="client-progress-page__arrow-wrapper">
            <PrevPageArrow
              width="40px"
              heigth="40px"
              className={cn("body-arrow", {
                "body-arrow--disabled": !prevPageExists,
              })}
              onClick={loadPrevPage}
            />
            <NextPageArrow
              width="40px"
              heigth="40px"
              className={cn("body-arrow", {
                "body-arrow--disabled": !nextPageExists,
              })}
              onClick={loadNextPage}
            />
          </div>
          {!isReady && <Spinner />}
          {isReady && (
            <div>
              <Widget.Body className="client-progress-table">
                <ProgressHeaders fullPage progress={latest} />
                <ProgressValues progress={latest} isLatest fullPage />
                {slicedProgress?.map((item, index) => (
                  <ProgressValues key={index} progress={item} fullPage />
                ))}
              </Widget.Body>
            </div>
          )}
        </Widget>
      </div>
    </div>
  );
};

ClientProgressPage.propTypes = {};

export default ClientProgressPage;
