import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import "./FooterButtons.scss";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../../../../components/Button";
import { COMPANY } from "../../../../../constants/router";
import Buttons from "../Buttons";

const FooterButtons = ({ onClickShowInvoices, onClickSave }) => {
  const history = useHistory();
  const successfullyCompanyDeleted = useSelector(
    (state) => state.companies.successfullyCompanyDeleted
  );
  const loading = useSelector((state) => state.companies.loading);
  const loadingTheme = useSelector((state) => state.companies.loadingTheme);

  useEffect(() => {
    if (successfullyCompanyDeleted) {
      history.push(COMPANY.root);
    }
  }, [successfullyCompanyDeleted]);

  return (
    <Box
      display="flex"
      className="mb-15 company-form__footer-buttons"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <Buttons onClickShowInvoices={onClickShowInvoices} />
      <Button
        disabled={loading || loadingTheme}
        className="default-btn btn--next-group"
        onClick={onClickSave}
        type={!onClickSave ? "submit" : ""}
      >
        save
      </Button>
    </Box>
  );
};
FooterButtons.propTypes = {
  onClickShowInvoices: PropTypes.func.isRequired,
  onClickSave: PropTypes.func,
};

export default FooterButtons;
