import "./PaymentPage.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MobileDrawer from "../../../components/MobileDrawer";
import List from "../../../components/list/List";
import Widget from "../../../components/widget/Widget";
import { ITEMS_PER_PAGE } from "../../../constants/common";
import { HEADER as header, PAYMENT_DURATION } from "../../../constants/payment";
import { filterPaymentList } from "../../../helpers/filterPaymentList";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import { useToastr } from "../../../hooks/useToastr";
import {
  loadPaymentList,
  clearCurrentPayment,
  setCurrentPayment,
  setSuccessUpdate,
} from "../../../redux/paymentSlice";

import FilteredDataView from "./components/FilteredDataView/FilteredDataView";
import SentInvoice from "./components/SentInvoice";
import ActivityTracking from "../../../components/ActivityTracking";
import {
  loadCompaniesPicker,
  loadCompaniesForActivityWidget,
} from "../../../redux/companiesSlice";

const PaymentPage = () => {
  const dispatch = useDispatch();
  const isSmallScreen = useIsSmallScreen();
  const [currentFilter, setCurrentFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const loading = useSelector((state) => state.payment.loading);
  const list = useSelector((state) => state.payment.list);
  const successUpdate = useSelector((state) => state.payment.successUpdate);
  useToastr({
    messages: "Saved invoice",
    deps: successUpdate,
    cb: () => {
      setOpenDrawer(false);
      dispatch(clearCurrentPayment());
      dispatch(setSuccessUpdate(null));
    },
  });

  useEffect(() => {
    dispatch(loadPaymentList());
    return () => {
      dispatch(clearCurrentPayment());
    };
  }, []);

  const [filteredList, viewListData] = filterPaymentList(
    list,
    currentPage,
    currentFilter
  );

  const btnClick = (e, el) => {
    e.preventDefault();
    dispatch(setCurrentPayment(el));
    if (!openDrawer) setOpenDrawer(true);
  };

  const changeFilter = (e) => {
    setCurrentFilter(e.target.value);
    setCurrentPage(0);
  };

  const loadPrevPage = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };

  const loadNextPage = () => {
    if (filteredList.length > (currentPage + 1) * ITEMS_PER_PAGE)
      setCurrentPage(currentPage + 1);
  };

  const closeMobileDrawer = () => {
    setOpenDrawer(false);
    setTimeout(() => dispatch(clearCurrentPayment()), PAYMENT_DURATION);
  };

  return (
    <div className="content-rhs content content-payment">
      <div className="payment-page__container page-content d-flex w-100">
        <div className="payment-page__body-main">
          <Widget className="payment-page__widget">
            <Widget.Header className="pr-10 pl-10">
              <Widget.Title>payment schedule</Widget.Title>
            </Widget.Header>
            <Widget.Body className="payment-page__content">
              <List
                header={header}
                customRows={
                  filteredList && (
                    <FilteredDataView
                      dataAr={viewListData}
                      btnClick={btnClick}
                    />
                  )
                }
                list={[]}
                page={currentPage + 1}
                totalCount={filteredList?.length}
                loadPrevPage={loadPrevPage}
                loadNextPage={loadNextPage}
                searchValue={currentFilter}
                onSearchChange={changeFilter}
                isShowPagination={true}
                rightCellsProps={null}
                isLoading={loading}
                customAmountPerPage={viewListData?.length}
              />
            </Widget.Body>
          </Widget>
        </div>
        <div className="payment-page__body-side body-side w-33%">
          {!isSmallScreen && !openDrawer && <ActivityTracking />}
          {isSmallScreen && (
            <MobileDrawer
              open={openDrawer}
              duration={PAYMENT_DURATION}
              onClose={() => closeMobileDrawer()}
            >
              <ActivityTracking />
            </MobileDrawer>
          )}
          {!isSmallScreen && openDrawer && <SentInvoice />}
          {isSmallScreen && (
            <MobileDrawer
              open={openDrawer}
              duration={PAYMENT_DURATION}
              onClose={() => closeMobileDrawer()}
            >
              <SentInvoice />
            </MobileDrawer>
          )}
        </div>
      </div>
    </div>
  );
};

PaymentPage.propTypes = {};

export default PaymentPage;
