export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || "";
export const ENV = process.env.REACT_APP_SENTRY_ENV || "";
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const TAG_MANAGER_ID = process.env.REACT_APP_TAG_MANAGER_ID || "";
export const CHECKOUT_PUBLIC_KEY =
  process.env.REACT_APP_CHECKOUT_PUBLIC_KEY || "";
export const PUBLIC_STRIPE_SECRET_KEY =
  process.env.REACT_APP_STRIPE_PUBLIC_KEY || "";
export const REDIRECT_URL =
  process.env.REACT_APP_REDIRECT_URL || "https://recomposer.com.au/";
