import "./СompaniesBody.scss";
import cn from "classnames";

import { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as NextPageArrow } from "../../../../assets/svg/next-page-arrow.svg";
import { ReactComponent as PrevPageArrow } from "../../../../assets/svg/prev-page-arrow.svg";
import { WIDGET_VIEW_TYPES } from "../../../../constants/activityTracking";
import { ITEMS_PER_PAGE } from "../../../../constants/common";
import PageLabel from "../../../list/PageLabel/PageLabel";
import { WidgetContext } from "../../ActivityTracking";
import {
  setCompanyForActivityCheck,
  loadCompaniesForActivityWidget,
} from "../../../../redux/companiesSlice";
import { isPrevOrNextPageExist } from "../../../../helpers";
import Search from "../../../Search/Search";
import { useCallDebouncedCb } from "../../../../hooks/useCallDebouncedCb";
import { DELAY_FOR_SEARCH } from "../../../../constants/common";
import { setIsLoading } from "../../../../redux/clientListSlice";

export const CompaniesBody = () => {
  const dispatch = useDispatch();
  const {
    pageParam,
    setPageParam,
    searchParam,
    setSearchParam,
    setWidgetParam,
    widgetParam,
  } = useContext(WidgetContext);

  const companiesForWidget = useSelector(
    (state) => state.companies.companiesForWidget
  );
  const totalCount = useSelector((state) => state.companies.totalCount);
  const page = useSelector((state) => state.companies.page);

  useEffect(() => {
    if (!Boolean(widgetParam)) {
      setWidgetParam("companies-body", true);
    }
  }, []);

  const onRowClickHandler = async (e) => {
    e.stopPropagation();
    localStorage.setItem(
      "current-company",
      JSON.stringify({
        name: e?.currentTarget.getAttribute("name"),
        id: e?.currentTarget?.id,
      })
    );
    dispatch(
      setCompanyForActivityCheck({
        name: e?.target?.textContent,
        id: e?.currentTarget?.id,
      })
    );
    await dispatch(setIsLoading());
    setWidgetParam(WIDGET_VIEW_TYPES.CLIENTS_BODY, true);
  };

  const setSearchValueHandler = async (e) => {
    setSearchParam(e.target.value, false);
    if (pageParam) {
      setPageParam(null, false);
    }
  };

  const moveToAnothePage = (direction) => {
    if (direction === "next") {
      const newPage = page + 1;
      dispatch(
        loadCompaniesForActivityWidget({ page: newPage, search: searchParam })
      );
      setPageParam(newPage, false);
    }
    if (direction === "prev") {
      const newPage = page - 1;
      dispatch(
        loadCompaniesForActivityWidget({ page: newPage, search: searchParam })
      );
      setPageParam(newPage, false);
    }
  };

  useCallDebouncedCb(
    (search, page) => {
      dispatch(loadCompaniesForActivityWidget({ search, page }));
    },
    [searchParam, pageParam],
    DELAY_FOR_SEARCH,
    [searchParam]
  );

  return (
    <div className="diet-side-ch">
      <table style={{ width: "100%" }} className="activityDropdown-list">
        <thead>
          <tr>
            <td colSpan="2" className="list-search-wrapper">
              <Search onChange={setSearchValueHandler} value={searchParam} />
            </td>
          </tr>
          <tr className="companies-body__head-container">
            <td style={{ float: "left", padding: "10px" }}>company</td>
            <td style={{ float: "left", padding: "10px" }}>billing day</td>
          </tr>
          <tr className="companies-body__body-container">
            {companiesForWidget?.map((company) => (
              <td
                key={company.id}
                className="companies-body__body-container_inner"
                name={company.name}
                id={company.id}
                onClick={onRowClickHandler}
              >
                <p className="companies-body__text">{company.name}</p>
                <p className="companies-body__text">{company.billing_day}</p>
              </td>
            ))}
          </tr>
          {totalCount > 50 && (
            <tr>
              <td colSpan={4}>
                <div className="list-arrow-wrapper list-arrow-wrapper_place_activity">
                  <PrevPageArrow
                    width="25px"
                    heigth="25px"
                    className={cn("body-arrow", {
                      "body-arrow--disabled": isPrevOrNextPageExist({
                        page,
                        totalCount,
                        prev: true,
                      }),
                    })}
                    onClick={() => moveToAnothePage("prev")}
                  />
                  <PageLabel
                    page={page}
                    itemsCount={companiesForWidget?.length}
                    totalCount={totalCount}
                    perPage={ITEMS_PER_PAGE}
                  />
                  <NextPageArrow
                    width="25px"
                    heigth="25px"
                    className={cn("body-arrow", {
                      "body-arrow--disabled": isPrevOrNextPageExist({
                        page,
                        totalCount,
                        next: true,
                      }),
                    })}
                    onClick={() => moveToAnothePage("next")}
                  />
                </div>
              </td>
            </tr>
          )}
        </thead>
      </table>
    </div>
  );
};
