import PropTypes from "prop-types";
import "./InvoicesPerMonth.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getFullName, prepareFullMonthDateForFront } from "../../../../helpers";
import Widget from "../../../widget/Widget";

import lodash from "lodash";

const InvoicesPerMonth = ({ onInvoiceSelect }) => {
  const paymentList = useSelector((state) => state.payment.listByCompany);
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    if (!lodash.isEmpty(paymentList?.[currentCompany.id])) {
      setCurrentList(paymentList?.[currentCompany.id]);
      return;
    }
    setCurrentList([]);
  }, [paymentList, currentCompany]);

  const createInvoiceItem = (startDate, user) => {
    const date = prepareFullMonthDateForFront(startDate);
    const fullName = getFullName(user) ? "- " + getFullName(user) : "";

    return `${date} ${fullName}`;
  };

  return (
    <Widget>
      <Widget.Header className="invoice-per-month__header">
        <Widget.Title>invoices</Widget.Title>
      </Widget.Header>
      <Widget.Body>
        {lodash.isEmpty(currentList) && <p>There are no invoices yet</p>}
        {!lodash.isEmpty(currentList) && (
          <table className="invoice-per-month__table">
            <thead>
              <tr>
                <td>month</td>
                <td>status</td>
              </tr>
            </thead>
            <tbody>
              {currentList.map((invoice) => (
                <tr key={invoice.id}>
                  <td
                    className="invoice-per-month__info"
                    onClick={() => onInvoiceSelect(invoice)}
                  >
                    {createInvoiceItem(invoice.end_date, invoice.user)}
                  </td>
                  <td className="invoice-per-month__status">
                    {invoice.status?.toLowerCase()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Widget.Body>
    </Widget>
  );
};

InvoicesPerMonth.propTypes = {
  onInvoiceSelect: PropTypes.func.isRequired,
};

export default InvoicesPerMonth;
