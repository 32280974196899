import "./ClientInfoPage.scss";
import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../components/Button";
import ClientsForm from "../../../components/ClientsForm";
import DeleteClient from "../../../components/DeleteClient";
import Divider from "../../../components/Divider";
import MobileDrawer from "../../../components/MobileDrawer/MobileDrawer";
import Spinner from "../../../components/Spinner";
import Widget from "../../../components/widget/Widget";
import WidgetBody from "../../../components/widget/WidgetBody";
import WidgetHeader from "../../../components/widget/WidgetHeader";
import WidgetTitle from "../../../components/widget/WidgetTitle";
import { WIDGET_FIELDS, WIDGETS } from "../../../constants/clientInfo";
import { DELAY_FOR_AUTO_SAVE } from "../../../constants/common";
import { USER_TYPES } from "../../../constants/common";
import {
  getDifferenceField,
  getFullName,
  prepareClientDataForSend,
  prepareTrainersForSend,
} from "../../../helpers";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import useSendUpdateClient from "../../../hooks/useSendUpdateClient";
import {
  clearClientForDelete,
  setForDeleteClient,
} from "../../../redux/clientListSlice";
import { updateClientActiveHistory } from "../../../redux/clientSlice";
import { loadCompaniesPicker } from "../../../redux/companiesSlice";

import useIsUserType from "./../../../hooks/useIsUserType";
import FooterButtons from "./components/FooterButtons";
import PermissionsForm from "./components/PermissionsForm";
import WidgetInfo from "./components/WidgetInfo";

const ClientInfoPage = () => {
  const dispatch = useDispatch();
  const [widget, setWidget] = useState(WIDGETS.HEALTH);
  const [formClientVal, setFormClientVal] = useState({});
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isShowDelete, setIsShowDelete] = useState(false);
  const isCompaniesLoaded = useSelector(
    (state) => state.companies.successfullyGettingCompanyPicker
  );
  const loading = useSelector((state) => state.companies.loading);
  const client = useSelector((state) => state.client.client);
  useDocumentTitle(`${client.first_name}'s info`);
  useSendUpdateClient(formClientVal, DELAY_FOR_AUTO_SAVE);
  const isSmallScreen = useIsSmallScreen();
  const isSuperUser = useIsUserType(USER_TYPES.SUPERUSER);

  useEffect(() => {
    if (!isCompaniesLoaded && isSuperUser) {
      dispatch(loadCompaniesPicker());
    }
  }, []);

  const onClickFooterBtn = (btn) => {
    onCloseDrawer();
    setWidget(btn.widget);
    setIsOpenDrawer(true);
  };

  const isDisableBtn = (btn) => {
    return (
      !isShowDelete &&
      ((isSmallScreen && isOpenDrawer && isShowDelete) || !isSmallScreen) &&
      btn.widget === widget
    );
  };

  const onCloseDrawer = () => {
    dispatch(clearClientForDelete());
    setIsShowDelete(false);
  };

  const onClickDelete = () => {
    dispatch(setForDeleteClient(client));
    setIsShowDelete(true);
  };

  return (
    <div className="content-rhs content">
      <div className="page-content d-flex w-100">
        <div className="body-main left-widget__wrapper">
          <Widget>
            <WidgetHeader>
              <WidgetTitle>{getFullName(client)}</WidgetTitle>
              <Box
                className="header-btn"
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  onClick={() => {
                    const body = { is_active: !client.is_active };
                    dispatch(
                      updateClientActiveHistory({ id: client.id, body })
                    );
                  }}
                >
                  {client.is_active ? "deactivate" : "activate"}
                </Button>
                <Button onClick={onClickDelete}>delete</Button>
              </Box>
            </WidgetHeader>
            <WidgetBody>
              {loading && <Spinner />}
              {!loading && (isCompaniesLoaded || !isSuperUser) && (
                <>
                  <ClientsForm
                    initialValues={client}
                    isEditPage={true}
                    isShowSubmit={false}
                    onSubmit={(values, initialValues) => {
                      let body = getDifferenceField(values, initialValues);
                      body = prepareTrainersForSend(body, values);
                      body = prepareClientDataForSend(body);
                      setFormClientVal(body);
                    }}
                  />
                  <Divider />
                  <PermissionsForm />
                  <Divider />
                  <FooterButtons
                    isDisableBtn={isDisableBtn}
                    onClickFooterBtn={onClickFooterBtn}
                  />
                </>
              )}
            </WidgetBody>
          </Widget>
        </div>
        <div className="body-side">
          {isSmallScreen && !isShowDelete && (
            <MobileDrawer
              open={isOpenDrawer}
              onClose={() => {
                setIsOpenDrawer(false);
              }}
            >
              <WidgetInfo widget={widget} fields={WIDGET_FIELDS[widget]} />
            </MobileDrawer>
          )}
          {!isSmallScreen && !isShowDelete && (
            <WidgetInfo widget={widget} fields={WIDGET_FIELDS[widget]} />
          )}
          {isSmallScreen && (
            <MobileDrawer onClose={onCloseDrawer} open={isShowDelete}>
              <DeleteClient
                closeWidget={onCloseDrawer}
                isNeedGoToListPage={true}
              />
            </MobileDrawer>
          )}
          {!isSmallScreen && isShowDelete && (
            <DeleteClient
              closeWidget={onCloseDrawer}
              isNeedGoToListPage={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ClientInfoPage.propTypes = {};

export default ClientInfoPage;
