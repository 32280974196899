import { useHistory, useLocation } from "react-router-dom";
import { getUpdatedLocationSearch } from "../helpers";

/**
 * get the URL search param and its setter
 * @param {string} paramName
 * @return {[string, (value: any, isDeletingOther: boolean = true) => void]} [currentValue, setter]
 */
export const useSearchParam = (paramName) => {
  const location = useLocation();
  const history = useHistory();
  const currentValue =
    new URLSearchParams(location.search).get(paramName) || undefined;
  const setParam = (value, isDeletingOther = true) => {
    const search = isDeletingOther ? "" : location.search;
    const updatedSearch = getUpdatedLocationSearch(search, paramName, value);
    history.replace({ search: updatedSearch });
  };
  return [currentValue, setParam];
};
