import cn from "classnames";
import "./HistoryWidget.scss";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../../components/Button";
import Spinner from "../../../../../components/Spinner";
import Widget from "../../../../../components/widget/Widget";
import { MAX_MOBILE_SCREEN_WIDTH } from "../../../../../constants/common";
import { REDUCED_HISTORY_COUNT } from "../../../../../constants/training";
import { HISTORY_ITEM_TYPE } from "../../../../../constants/training";
import {
  ordinalRankOf,
  prepareLocalFancyDateForFront,
} from "../../../../../helpers";
import { getMergedId, roundMax } from "../../../../../helpers/training";
import useIsSmallScreen from "../../../../../hooks/useIsSmallScreen";
import { setHistoryDetails } from "../../../../../redux/programsSlice";

import HistoryDetails from "./components/HistoryDetails/HistoryDetails";
import HistoryItem from "./components/HistoryItem";

const HistoryWidget = ({ setOnly }) => {
  const dispatch = useDispatch();

  const currentSet = useSelector((state) => state.programs.currentSet);
  const allHistory = useSelector((state) => state.programs.history);
  const loading = useSelector((state) => state.programs.loadingHistory);
  const massUnit = useSelector((state) => state.client.client.mass_unit);
  const details = useSelector((state) => state.programs.historyDetails);
  const isMobileVersion = useIsSmallScreen(
    MAX_MOBILE_SCREEN_WIDTH.MOBILE_VIEW_ON_TRAINING
  );

  const [history, setHistory] = useState(null);
  const [more, setMore] = useState(false);

  useEffect(() => {
    if (!allHistory?.history || loading) return;
    const mergedId = getMergedId(currentSet?.exerciseId, currentSet?.id);
    const current = allHistory?.history[mergedId];
    if (!current) return;

    const newHistory = {
      set: allHistory?.sets[currentSet?.id],
      best: current.best ? allHistory?.sets[current.best] : null,
      previous: {
        indexed: current.previous?.indexed
          ? allHistory?.sets[current.previous.indexed]
          : null,
        ranked: current.previous?.ranked
          ? current.previous?.ranked === current.previous?.indexed
            ? false
            : allHistory?.sets[current.previous.ranked]
          : null,
      },
      other: current.other?.map((item) => allHistory?.sets[item]) || [],
    };

    if (newHistory.best) {
      newHistory.best = {
        ...newHistory.best,
        title: `best ever set ${newHistory.best.indexes.set + 1}`,
      };
    }

    const idx = newHistory.set.indexes.set + 1;
    if (newHistory.previous?.indexed) {
      newHistory.previous.indexed = {
        ...newHistory.previous.indexed,
        title: `set ${idx}`,
      };
    } else {
      newHistory.previous.indexed = `no set ${idx} completed`;
    }
    if (newHistory.previous?.ranked) {
      newHistory.previous.ranked = {
        ...newHistory.previous.ranked,
        title: `${ordinalRankOf(idx)} (set ${
          newHistory.previous.ranked.indexes.set + 1
        })`,
      };
    }
    // else if (newHistory.previous?.ranked === null) {
    //   newHistory.previous.ranked = `no ${ordinalRankOf(idx)} set completed`;
    // }

    if (!_.isEmpty(newHistory.other)) {
      newHistory.other.sort((a, b) => moment(b.date).diff(moment(a.date)));
      newHistory.other = newHistory.other.map((set) => ({
        ...set,
        title: prepareLocalFancyDateForFront(set.date),
      }));
    }

    setHistory(newHistory);
  }, [setHistory, allHistory, currentSet]);

  const composeItem = (set) =>
    set && typeof set === "object"
      ? {
          ...set,
          otherReps: set.other_reps,
          max: roundMax(set.calculated_max),
          date: set.date ? moment(set.date).fromNow() : null,
          massUnit,
          detailed: () => toggleDetails(set),
        }
      : set;

  const titleByType = {
    [HISTORY_ITEM_TYPE.best]: null,
    [HISTORY_ITEM_TYPE.previous]: "previous workout",
    [HISTORY_ITEM_TYPE.other]: `full ${currentSet?.exerciseName} set ${currentSet?.set.number} history`,
  };

  const toggleDetails = (details) => {
    dispatch(setHistoryDetails(details));
    !isMobileVersion && setOnly(!!details);
  };

  const onBack = () => {
    if (details) {
      toggleDetails(null);
    } else {
      setOnly(false);
    }
  };

  return (
    <Widget
      className={cn("history-widget", {
        "history-widget-details": details,
      })}
    >
      <Widget.Header className="history-widget__header">
        <Widget.Title>exercise history</Widget.Title>
        {(details || isMobileVersion) && <Button onClick={onBack}>back</Button>}
      </Widget.Header>
      {!details && (
        <div className="history-widget__headings">
          <div className="history-widget__headings-title">workout</div>
          <div className="history-widget__headings-item">weight</div>
          <div className="history-widget__headings-item" />
          <div className="history-widget__headings-item">reps</div>
          <div className="history-widget__headings-item" />
          <div className="history-widget__headings-item">max</div>
        </div>
      )}
      <Widget.Body className="history-widget__body">
        {loading ? (
          <Spinner stickToTop />
        ) : details ? (
          <HistoryDetails set={details} />
        ) : (
          <>
            {history?.best && (
              <HistoryItem
                data={[composeItem(history.best)]}
                title={titleByType[HISTORY_ITEM_TYPE.best]}
              />
            )}
            {history?.previous && (
              <HistoryItem
                data={[
                  composeItem(history.previous.indexed),
                  composeItem(history.previous.ranked),
                ]}
                title={titleByType[HISTORY_ITEM_TYPE.previous]}
              />
            )}
            {!_.isEmpty(history?.other) && (
              <>
                <HistoryItem
                  data={
                    more
                      ? history.other.map((set) => composeItem(set))
                      : history.other
                          .map((set) => composeItem(set))
                          .slice(0, REDUCED_HISTORY_COUNT)
                  }
                  title={titleByType[HISTORY_ITEM_TYPE.other]}
                />
                {history.other.length > REDUCED_HISTORY_COUNT && (
                  <div className="history-widget__btn-wrapper">
                    <button
                      className="history-widget__btn"
                      onClick={() => setMore(!more)}
                    >
                      {more ? "less" : "more"}
                    </button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </Widget.Body>
    </Widget>
  );
};

HistoryWidget.propTypes = {
  setOnly: () => {},
};

export default HistoryWidget;
