import "./CompanyAddPage.scss";
import { Box } from "@material-ui/core";
import lodash from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../../components/Button";
import CompanyForm from "../../../components/CompanyForm";
import Widget from "../../../components/widget/Widget";
import { COMPANY, MANAGE_COMPANY_SLUG } from "../../../constants/router";
import { clearEmptyField } from "../../../helpers";
import { addCompany } from "../../../redux/companiesSlice";
import TabButton from "../components/TabButton";

const prepareDataForSending = (values) => {
  values = lodash.cloneDeep(values);
  delete values.company_admin.repeat_password;
  values = clearEmptyField(values);
  values.company_admin = clearEmptyField(values.company_admin);
  return values;
};

const CompanyAddPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const successfullyCompanyCreated = useSelector(
    (state) => state.companies.successfullyCompanyCreated
  );
  const loading = useSelector((state) => state.companies.loading);
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  useEffect(() => {
    if (successfullyCompanyCreated && currentCompany?.id) {
      history.push(`${COMPANY.root}${currentCompany.id}${MANAGE_COMPANY_SLUG}`);
    }
  }, [successfullyCompanyCreated, currentCompany]);

  const goToCompanyList = () => {
    history.push(COMPANY.root);
  };
  return (
    <div className="content-rhs content companies-page">
      <div className="companies-page__tab-buttons">
        <TabButton variant="left" onClick={goToCompanyList}>
          companies list
        </TabButton>
        <TabButton variant="right" active>
          add a company
        </TabButton>
      </div>
      <div className="page-content w-100">
        <div className="companies-page__header">
          <Widget.Title>add a company</Widget.Title>
        </div>
        <Widget.Body>
          <CompanyForm
            onSubmit={(body) => {
              body = prepareDataForSending(body);
              dispatch(addCompany({ body }));
            }}
            children={
              <Box
                display="flex"
                className="mb-15"
                justifyContent="flex-end"
                flexWrap="wrap"
              >
                <Button
                  disabled={loading}
                  className="default-btn"
                  type="submit"
                >
                  save
                </Button>
              </Box>
            }
          />
        </Widget.Body>
      </div>
    </div>
  );
};

export default CompanyAddPage;
