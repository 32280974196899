import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { getFullName } from "../../../../helpers";

import "./TrainersAutocomplete.scss";

function comparer(otherArray) {
  return function (current) {
    return (
      otherArray.filter((other) => {
        return other.id === current.id;
      }).length === 0
    );
  };
}

const TrainersAutocomplete = ({
  index,
  remove,
  replace,
  selectableTrainers,
  setSelectableTrainers,
  submitOnBlur,
}) => {
  const { trainers } = useSelector((state) => state.trainers);

  const { values, setFieldTouched, handleSubmit } = useFormikContext();
  useEffect(() => {
    const usdTrainers = values.trainers.filter(Boolean);
    setSelectableTrainers(trainers.filter(comparer(usdTrainers)));
  }, [trainers, values.trainers]);
  return (
    <>
      <Grid item md={2} xs={12}>
        <Box display="flex" alignItems="center" height="100%">
          <label htmlFor={`trainers.${index}`} className="form-label">
            coach #{index + 1}
          </label>
          <button
            className="remove-btn trainers__remove-btn"
            type="button"
            title="Remove coach"
            onClick={() => {
              remove(index);
              if (index === 0) {
                setSelectableTrainers(values.trainers);
              }
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Box>
      </Grid>
      <Grid item md={10} xs={12}>
        <Autocomplete
          name={`trainers.${index}`}
          className="form-input w-100"
          component={Autocomplete}
          options={selectableTrainers}
          value={values.trainers[index]}
          disabled={!values.company?.id}
          getOptionLabel={(option) => `${getFullName(option)}`}
          onBlur={() => {
            setFieldTouched(`trainers.${index}`);
          }}
          onChange={(e, newValue) => {
            replace(index, newValue);
            if (submitOnBlur) {
              handleSubmit();
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              value={values.id}
            />
          )}
        />
      </Grid>
    </>
  );
};

TrainersAutocomplete.propTypes = {
  index: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  selectableTrainers: PropTypes.array.isRequired,
  setSelectableTrainers: PropTypes.func.isRequired,
  submitOnBlur: PropTypes.bool,
};

TrainersAutocomplete.defaultProps = {
  submitOnBlur: false,
};

export default TrainersAutocomplete;
