import React, { memo, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import Button from "../../../../../components/Button";
import List from "../../../../../components/list/List";
import Widget from "../../../../../components/widget/Widget";
import { USER_TYPES } from "../../../../../constants/common";
import {
  HEADER_FOOD_TABLE,
  FOODS_SIDEBAR_NAMES,
  HEADER_FOOD_TABLE_MOBILE,
  FOODS_PREVIEW_COUNT,
  FOOD_TYPES,
} from "../../../../../constants/foods";
import { prepareFoodsList } from "../../../../../helpers/foods";
import useIsSmallScreen from "../../../../../hooks/useIsSmallScreen";
import useIsUserType from "../../../../../hooks/useIsUserType";
import useSearchHook from "../../../../../hooks/useSearchHook";

const FoodsList = ({
  onFoodClick,
  setSidebarMode,
  sidebarMode,
  isOpenDrawer,
  setIsOpenDrawer,
  onFoodDelete,
}) => {
  const [isShowPreview, setIsShowPreview] = useState(true);
  // данные для показа до фильтрации
  const [list, setList] = useState([]);
  // данные для показа после фильтрации
  const [filteredList, setFilteredList] = useState([]);
  const loading = useSelector((state) => state.foods.loading);
  const editableFoodItemId = useSelector(
    (state) => state.foods.editableFoodItem?.id
  );
  const foods = useSelector((state) => state.foods.itemCurrentUser);
  const isSuper = useIsUserType(USER_TYPES.SUPERUSER);
  const isSmallScreen = useIsSmallScreen();

  useLayoutEffect(() => {
    if (foods?.length) {
      const checkedList = isSuper
        ? foods.filter((food) => food.type === FOOD_TYPES.GLOBAL)
        : foods;
      const preparedList = prepareFoodsList(checkedList);
      setList(preparedList);
      setFilteredList(preparedList);
      return;
    }
    setList([]);
    setFilteredList([]);
  }, [foods]);

  const [searchValue, onSearchChange] = useSearchHook(list, setFilteredList);

  const itemClickHandler = (id) => {
    const food = foods.find((i) => i.id === id);
    onFoodClick(food);
  };

  const deleteItemHandler = (item) => {
    const food = foods.find((i) => i.id === item.id);
    onFoodDelete(food);
  };

  return (
    <Widget className="foods__">
      <Widget.Header className="pl-10">
        <Widget.Title>foods</Widget.Title>
        <Button
          disabled={
            sidebarMode === FOODS_SIDEBAR_NAMES.FOOD_ADD &&
            ((isSmallScreen && isOpenDrawer) || !isSmallScreen)
          }
          onClick={() => {
            setIsOpenDrawer(true);
            setSidebarMode(FOODS_SIDEBAR_NAMES.FOOD_ADD);
          }}
        >
          add food
        </Button>
      </Widget.Header>
      <Widget.Body className="widget-body--with-mh">
        <List
          currentItem={editableFoodItemId}
          isEdit={sidebarMode === FOODS_SIDEBAR_NAMES.FOOD_EDIT}
          isDelete={sidebarMode === FOODS_SIDEBAR_NAMES.FOOD_DELETE}
          header={isSmallScreen ? HEADER_FOOD_TABLE_MOBILE : HEADER_FOOD_TABLE}
          list={
            isShowPreview
              ? filteredList.slice(0, FOODS_PREVIEW_COUNT)
              : filteredList
          }
          deleteItemHandler={deleteItemHandler}
          itemClickHandler={itemClickHandler}
          searchValue={searchValue}
          onSearchChange={onSearchChange}
          isLoading={loading}
          isShowPagination={false}
          zeroRound={true}
        />
        {!loading && filteredList?.length > FOODS_PREVIEW_COUNT && (
          <Button
            disabled={!isShowPreview}
            onClick={() => setIsShowPreview(false)}
          >
            show all
          </Button>
        )}
      </Widget.Body>
    </Widget>
  );
};

export default memo(FoodsList);
