import "./ManageThemes.scss";
import PropTypes from "prop-types";

import CompanyDropZone from "../../../../../components/CompanyDropZone";

const ManageThemes = ({ onClickShowInvoices }) => {
  return <CompanyDropZone onClickShowInvoices={onClickShowInvoices} />;
};

ManageThemes.propTypes = {
  onClickShowInvoices: PropTypes.func.isRequired,
};

export default ManageThemes;
