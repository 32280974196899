import { Form, useFormikContext } from "formik";
import { cloneDeep, isEqual } from "lodash";
import React, { useMemo, useState } from "react";

import { LEAVE_MESSAGES } from "../../../constants/common";
import { SIDEBAR_TYPE } from "../../../constants/templates";
import { usePrompt } from "../../../hooks/usePrompt";

import ProgramTemplateMain from "./ProgramTemplateMain/ProgramTemplateMain";
import ProgramTemplateSide from "./ProgramTemplateSide/ProgramTemplateSide";
import PropTypes from "prop-types";
import { setModal } from "../../../redux/utilsSlice";
import ModalWindow from "../../Clients/ClientTrainingPage/components/ModalWindow";
import { useDispatch, useSelector } from "react-redux";
import {
  getEditExerciseModalMessage,
  hasSubsequentSimilarExercises,
} from "../../../helpers/training";

export const Context = React.createContext();

const ProgramTemplateForm = ({ canDelete, isEditingClientProgram }) => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.utils.modal);
  const [sideBarState, setSideBarState] = useState(SIDEBAR_TYPE.TOOLS);
  const [onExerciseClick, setOnExerciseClick] = useState(null);
  const [isAddExercise, setIsAddExercise] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [replaceExerciseParams, setReplaceExerciseParams] = useState(null);
  const { values, setValues, initialValues } = useFormikContext();

  /**
   * @param {Object} target
   * @param {string} target.id
   * @param {number} target.day
   * @param {number} target.workout
   * @param {number} target.exercise
   * @param {Object} newExercise
   * @param {string} newExercise.id
   * @param {string} newExercise.name
   */
  const replaceAllExercisesFrom = async (target, newExercise) => {
    const newValues = cloneDeep(values);
    if (
      !modal &&
      hasSubsequentSimilarExercises(newValues, {
        day_index: target.day,
        workout_index: target.workout,
        exercise_index: target.exercise,
        exercise_id: newExercise.id,
      })
    ) {
      setReplaceExerciseParams({ target, newExercise });
      dispatch(setModal(getEditExerciseModalMessage(newExercise.name)));
      return;
    }
    for (let i = target.day; i < newValues.days.length; i++) {
      const day = newValues.days[i];
      for (let j = 0; j < day.workouts.length; j++) {
        const workout = day.workouts[j];
        for (let k = 0; k < workout.exercises.length; k++) {
          const exercise = workout.exercises[k];
          if (
            exercise.id === target.id &&
            (i > target.day ||
              j > target.workout ||
              (j === target.workout && k >= target.exercise))
          ) {
            const updatedExercise = newValues.days[i].workouts[j].exercises[k];
            updatedExercise.id = newExercise.id;
            updatedExercise.name = newExercise.name;
          }
        }
      }
    }
    setValues(newValues, false);
  };

  const isDirty = useMemo(
    () => !isEqual(values, initialValues),
    [values, initialValues]
  );

  usePrompt(isDirty, LEAVE_MESSAGES.UNSAVED);

  return (
    <>
      <ModalWindow
        modal={modal}
        confirm={() => {
          replaceAllExercisesFrom(
            replaceExerciseParams.target,
            replaceExerciseParams.newExercise
          ).then(() => dispatch(setModal(null)));
        }}
        close={() => {
          dispatch(setModal(null));
        }}
        isQuestion={true}
      />
      <Context.Provider
        value={{
          sideBarState,
          setSideBarState,
          isAddExercise,
          setIsAddExercise,
          onExerciseClick,
          setOnExerciseClick,
          isValid,
          setIsValid,
          canDelete,
          isEditingClientProgram,
          replaceAllExercisesFrom,
        }}
      >
        <div className={"content program-template"}>
          <div className={"page-content program-template__page-content"}>
            <div className={"main program-template__main"}>
              <Form>
                <ProgramTemplateMain />
              </Form>
            </div>
            <div className={"program-template__side sticky-side"}>
              <ProgramTemplateSide />
            </div>
          </div>
        </div>
      </Context.Provider>
    </>
  );
};

ProgramTemplateForm.propTypes = {
  isEditingClientProgram: PropTypes.bool,
  canDelete: PropTypes.bool,
};

ProgramTemplateForm.defaultProps = {
  isEditingClientProgram: false,
  canDelete: false,
};

export default ProgramTemplateForm;
