import _ from "lodash";
import { useState } from "react";

/**
 * controlled field that allows only numbers (integers)
 * @param {number} initialValue
 * @return {[number, (e: number) => void]} [value, onChange handler]
 */
export const useNumbersOnlyField = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  /** @param {number} e */
  const handleFieldChange = (e) => {
    const val = e.toString();
    if (/^\d+$/.test(val)) {
      if (_.isSafeInteger(_.toInteger(val))) setValue(_.toInteger(val));
    } else if (val === "") {
      setValue(0);
    }
  };

  return [value, handleFieldChange];
};
