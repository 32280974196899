import { Box } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import "./CompanyProfilePage.scss";
import { CSVLink } from "react-csv/lib";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../components/Button";
import CompanyForm from "../../../components/CompanyForm";
import CompanyInvoices from "../../../components/CompanyInvoces";
import MobileDrawer from "../../../components/MobileDrawer";
import Spinner from "../../../components/Spinner";
import Widget from "../../../components/widget/Widget";
import { DELAY_FOR_AUTO_SAVE } from "../../../constants/common";
import { COMPANY_IDS_WTIH_ACCESS_TO_STRIPE, COMPANY_PROFILE_WIDGET } from "../../../constants/company";
import useGetCompanyInvoice from "../../../hooks/useGetCompanyInvoice";
import useIsSmallScreen from "../../../hooks/useIsSmallScreen";
import useSendUpdateCompany from "../../../hooks/useSendUpdateCompany";
import { loadCSV } from "../../../redux/userSlice";

import ThemeSideBar from "./components/ThemeSideBar";
import PaymentSideBar from "./components/PaymentSideBar";
import {
  clearMessage,
  loadPaymentAccount,
  checkSessionId,
  deletePaymentAccount,
  setSessionIdVerificationAttempt,
  deleteStripePaymentAccount,
  loadStripePaymentAccount,
} from "../../../redux/companiesSlice";
import { useToastr } from "../../../hooks/useToastr";
import { useSearchParam } from "../../../hooks/useSearchParam";
import StripePaymentSidebarContainer from "./components/StripePaymentSidebar/StripePaymentSidebarContainer";

const WIDGETS = {
  [COMPANY_PROFILE_WIDGET.INVOICES]: <CompanyInvoices />,
  [COMPANY_PROFILE_WIDGET.THEME]: <ThemeSideBar />,
  [COMPANY_PROFILE_WIDGET.PAYMENT]: <PaymentSideBar />,
  [COMPANY_PROFILE_WIDGET.STRIPE_PAYMENT]: <StripePaymentSidebarContainer />,
};

const CompanyProfilePage = () => {
  const dispatch = useDispatch();
  useGetCompanyInvoice();
  const csv = useSelector((state) => state.user.csv);
  const loadingCSV = useSelector((state) => state.user.loadingCSV);
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const success = useSelector(
    (state) => state.companies.successfullyGettingCompanyById
  );
  const loading = useSelector((state) => state.companies.loading);
  const message = useSelector((state) => state.companies.message);
  const sessionIdVerificationAttempt = useSelector(
    (state) => state.companies.sessionIdVerificationAttempt
  );
  const errorStatusCode = useSelector(
    (state) => state.companies.errorStatusCode
  );
  const isCardVerified = useSelector((state) => state.companies.isCardVerified);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [formCompanyVal, setFormCompanyVal] = useState({
    body: {},
    oldVal: {},
  });
  const [sideBarParam, setSideBarParam] = useSearchParam("side");
  const [ckoSessionId, setCkoSessionId] = useSearchParam("cko-session-id");
  const [sideBarState, setSideBarState] = useState(
    sideBarParam || COMPANY_PROFILE_WIDGET.THEME
  );
  const [verifyAttempted, setVerifyAtempted] = useState(false);
  useSendUpdateCompany(formCompanyVal, DELAY_FOR_AUTO_SAVE);
  const isSmallScreen = useIsSmallScreen();

  const onChangeSideBar = (sideBarType) => {
    setSideBarState(sideBarType);
    setSideBarParam(sideBarType);
    setIsOpenDrawer(true);
  };

  const checkSessionIdHandler = async () => {
    await dispatch(
      checkSessionId({ id: currentCompany.id, cko_session_id: ckoSessionId })
    );
    const response = await dispatch(
      loadPaymentAccount({ id: currentCompany.id })
    );
    const { cko_verified, last4 } = response.payload;
    if (cko_verified === false && last4 !== null)
      dispatch(
        deletePaymentAccount({ id: currentCompany.id, forceDelete: true })
      );
    if (sessionIdVerificationAttempt !== 2) {
      onChangeSideBar(COMPANY_PROFILE_WIDGET.PAYMENT);
    }
    setVerifyAtempted(true);
  };

  useEffect(() => {
    if (
      !isCardVerified &&
      ckoSessionId &&
      sessionIdVerificationAttempt < 2 &&
      (errorStatusCode === null || errorStatusCode === 400)
    ) {
      checkSessionIdHandler();
      dispatch(setSessionIdVerificationAttempt(1));
    }
  }, [verifyAttempted]);

  useEffect(() => {
    async function checkPaymentAcc() {
      if (
        sideBarState === COMPANY_PROFILE_WIDGET.PAYMENT &&
        Boolean(ckoSessionId) === false
      ) {
        dispatch(loadPaymentAccount({ id: currentCompany.id })).then((e) => {
          const { cko_verified, last4 } = e.payload;
          if (cko_verified === false && last4 !== null) {
            dispatch(
              deletePaymentAccount({ id: currentCompany.id, forceDelete: true })
            );
          }
        });
      }

      if (sideBarState === COMPANY_PROFILE_WIDGET.STRIPE_PAYMENT) {
        const paymentAcc = await dispatch(
          loadStripePaymentAccount({ id: currentCompany.id })
        );
        const { payment_account_verified, last4 } = paymentAcc.payload;
        if (payment_account_verified === false && last4 !== null) {
          await dispatch(
            deleteStripePaymentAccount({
              id: currentCompany.id,
              forceDelete: true,
            })
          );
        }
      }
    }

    checkPaymentAcc();
  }, [sideBarState]);

  useToastr({
    messages: message,
    deps: message,
    cb: () => dispatch(clearMessage()),
  });

  //comment this for dev
  //const isShowStripe = useMemo(() => {
    //return COMPANY_IDS_WTIH_ACCESS_TO_STRIPE.includes(currentCompany.id);
  //}, [currentCompany.id]);

  //uncomment this for dev
  const isShowStripe = true;

  return (
    <div className="content-rhs content">
      <div className="page-content d-flex w-100">
        <div className="body-main">
          <Widget>
            <Widget.Header>
              <Widget.Title>my profile</Widget.Title>
            </Widget.Header>
            <Widget.Body>
              {loading ? (
                <Spinner />
              ) : (
                success && (
                  <CompanyForm
                    onSubmit={(body, oldVal) => {
                      setFormCompanyVal({ body, oldVal });
                    }}
                    isAutoSave
                    isEditPage
                    initialValues={currentCompany}
                    children={
                      <Box
                        display="flex"
                        className="mb-15"
                        justifyContent="flex-end"
                        flexWrap="wrap"
                      >
                        {isShowStripe ? (
                          <Button
                            disabled={
                              ((isSmallScreen && isOpenDrawer) ||
                                !isSmallScreen) &&
                              sideBarState ===
                                COMPANY_PROFILE_WIDGET.STRIPE_PAYMENT
                            }
                            onClick={() =>
                              onChangeSideBar(
                                COMPANY_PROFILE_WIDGET.STRIPE_PAYMENT
                              )
                            }
                            className="default-btn"
                          >
                            stripe payments
                          </Button>
                        ) : (
                          <Button
                            disabled={
                              ((isSmallScreen && isOpenDrawer) ||
                                !isSmallScreen) &&
                              sideBarState === COMPANY_PROFILE_WIDGET.PAYMENT
                            }
                            onClick={() =>
                              onChangeSideBar(COMPANY_PROFILE_WIDGET.PAYMENT)
                            }
                            className="default-btn"
                          >
                            payments
                          </Button>
                        )}
                        <Button
                          disabled={
                            ((isSmallScreen && isOpenDrawer) ||
                              !isSmallScreen) &&
                            sideBarState === COMPANY_PROFILE_WIDGET.INVOICES
                          }
                          onClick={() =>
                            onChangeSideBar(COMPANY_PROFILE_WIDGET.INVOICES)
                          }
                          className="default-btn"
                        >
                          invoices
                        </Button>
                        <Button
                          disabled={
                            ((isSmallScreen && isOpenDrawer) ||
                              !isSmallScreen) &&
                            sideBarState === COMPANY_PROFILE_WIDGET.THEME
                          }
                          onClick={() =>
                            onChangeSideBar(COMPANY_PROFILE_WIDGET.THEME)
                          }
                          className="default-btn"
                        >
                          theme
                        </Button>
                        {!csv?.length && (
                          <Button
                            disabled={loadingCSV}
                            className="btn-primary"
                            onClick={() => dispatch(loadCSV())}
                          >
                            export csv
                          </Button>
                        )}
                        {!!csv?.length && (
                          <CSVLink data={csv}>
                            <Button className="btn-primary">
                              download csv
                            </Button>
                          </CSVLink>
                        )}
                      </Box>
                    }
                  />
                )
              )}
            </Widget.Body>
          </Widget>
        </div>
        <div className="body-side">
          {!loading && success && !isSmallScreen && WIDGETS[sideBarState]}
          {!loading && success && isSmallScreen && (
            <MobileDrawer
              open={isOpenDrawer}
              onClose={() => {
                setIsOpenDrawer(false);
              }}
            >
              {WIDGETS[sideBarState]}
            </MobileDrawer>
          )}
        </div>
      </div>
    </div>
  );
};

CompanyProfilePage.propTypes = {};

export default CompanyProfilePage;
