import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import myFetch from "../api/myFetch";
import * as UserApi from "../api/userApi";
import { baseUrl } from "../env/env";
import * as Sentry from "@sentry/react";

export const loadProfile = createAsyncThunk(
  "user/loadProfile",
  UserApi.loadProfile
);

export const saveProfile = createAsyncThunk(
  "user/saveProfile",
  UserApi.saveProfile
);

export const loadCSV = createAsyncThunk("user/loadCSV", () => {
  return myFetch(`${baseUrl}/api/client/csv`);
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    loadingCSV: false,
    success: false,
    successCSV: false,
    successSave: false,
    successSavePassword: false,
    error: "",
    user: null,
    csv: null,
  },
  reducers: {
    clearError(state) {
      state.error = "";
    },
    clearSuccessSave(state) {
      state.successSave = false;
    },
  },
  extraReducers: {
    [loadProfile.pending]: (state) => {
      state.loading = true;
      state.user = null;
    },
    [loadProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = "";
      const user = action.payload;
      state.user = user;
      Sentry.setUser({
        id: user?.id,
        username: user?.full_name,
        email: user?.email,
      });
    },
    [loadProfile.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      Sentry.setUser({});
    },
    [loadCSV.pending]: (state) => {
      state.loadingCSV = true;
      state.error = "";
    },
    [loadCSV.fulfilled]: (state, action) => {
      state.loadingCSV = false;
      state.successCSV = true;
      state.csv = action.payload.data;
    },
    [loadCSV.rejected]: (state, action) => {
      state.loadingCSV = false;
      state.successCSV = false;
      state.error = action.payload;
    },
    [saveProfile.pending]: (state) => {
      state.successSave = false;
    },
    [saveProfile.fulfilled]: (state, action) => {
      state.error = "";
      state.user = action.payload;
      state.successSave = true;
    },
    [saveProfile.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const { clearError, clearSuccessSave } = userSlice.actions;

export default userSlice.reducer;
