import stringify from "fast-json-stable-stringify";

import { CACHE_TIMEOUT } from "../constants/api";

export const toCleanupCache = [];

/**
 * use function with cache by key generated from arguments
 * @param {function} func function that return some value
 * @param {number} invalidateTimeout in how many seconds invalidate the cache
 * @param {string} cleanupActionType an action to cleanup it after (empty to not use this feature)
 * @returns {function}
 */
const withCache = (
  func,
  {
    invalidateTimeout = CACHE_TIMEOUT,
    cleanupActionType = "auth/logout/fulfilled",
  } = {}
) => {
  let cache = {};

  const invalidate = () => {
    cache = {};
  };

  if (cleanupActionType) {
    toCleanupCache.push({ cleanup: invalidate, actionType: cleanupActionType });
  }

  const invalidateByKey = (key) => {
    delete cache[key];
  };

  const invalidateByArgs = (...args) => {
    const key = stringify(args);
    invalidateByKey(key);
  };

  const loadNewCache = (key, newCache) => {
    let timeoutId = null;
    if (invalidateTimeout) {
      timeoutId = setTimeout(invalidateByKey, invalidateTimeout, key);
    }
    cache[key] = newCache;
    Promise.resolve(cache[key]).catch((error) => {
      delete cache[key];
      console.log(error);
      clearTimeout(timeoutId);
    });
  };

  const updateByKey = (key, newCache) => {
    loadNewCache(key, newCache);
  };

  const updateByArgs = (args, newCache) => {
    const key = stringify([args]);
    updateByKey(key, newCache);
  };

  /**
   * @param {...[*]=} args
   * @returns {*}
   */
  const funcWithCache = (...args) => {
    const key = stringify(args);
    if (!(key in cache)) {
      loadNewCache(key, func(...args));
    }
    return cache[key];
  };
  /**
   * just call it (for backward compatibility)
   * @type {function(...[*]=): *}
   */
  funcWithCache.call = funcWithCache;
  /**
   * invalidate all the cache
   * @type {function(): void}
   */
  funcWithCache.invalidate = invalidate;
  /**
   * invalidate by key of the cache
   * @type {function(key: string): void}
   */
  funcWithCache.invalidateByKey = invalidateByKey;
  /**
   * invalidate by args passed to the cache constructor
   * @type {function(...[*]=): void}
   */
  funcWithCache.invalidateByArgs = invalidateByArgs;
  /**
   * update by key of the cache
   * @type {function(key: string, value: object): void}
   */
  funcWithCache.updateByKey = updateByKey;
  /**
   * update by args passed to the cache constructor
   * @type {function(...[*]=, value: object): void}
   */
  funcWithCache.updateByArgs = updateByArgs;

  return funcWithCache;
};

export default withCache;
