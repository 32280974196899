import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import { CHECKOUT_PUBLIC_KEY } from "../../../../../env/env";
import "../../CompanyProfilePage.scss";
import "../../../../../components/FormLabel/FormLabel.scss";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createPaymentAccount } from "../../../../../redux/companiesSlice";
import cn from "classnames";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask/dist/reactTextMask";
import {
  COMPANY_IDS_WITH_ACCESS_MIDDLE_NAME_FEATURE,
  REGEXP_CARD_HOLDER_NAME,
  REGEXP_CARD_HOLDER_NAME_TEST,
} from "../../../../../constants/regexp";

const CreditCardForm = ({ isHidden }) => {
  const dispatch = useDispatch();
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const [cardHolder, setCardHolder] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPaymentPermissionChecked, setIsPaymentPermissionChecked] =
    useState(false);
  const [isTermsPermissionChecked, setIsTermsPermissionChecked] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCardHolderValid, setIsCardHolderValid] = useState(false);
  const [isCardHolderTouched, setIsCardHolderTouched] = useState(false);

  const isSubmitButtonDisabled = useMemo(
    () =>
      !(
        isFormValid &&
        isCardHolderValid &&
        isPaymentPermissionChecked &&
        isTermsPermissionChecked &&
        !isSubmitting
      ),
    [
      isFormValid,
      cardHolder,
      isPaymentPermissionChecked,
      isTermsPermissionChecked,
      isSubmitting,
    ]
  );
  const currentCompanyId = useSelector(
    (state) => state.companies?.currentCompany?.id
  );
  const isShowMiddleFeature = useMemo(() => {
    return COMPANY_IDS_WITH_ACCESS_MIDDLE_NAME_FEATURE.includes(
      currentCompanyId
    );
  }, [currentCompanyId]);
  return (
    <Frames
      config={{
        publicKey: CHECKOUT_PUBLIC_KEY,
        cardholder: {
          name: cardHolder,
        },
        localization: {
          cardNumberPlaceholder: "Card Number",
          expiryMonthPlaceholder: "MM",
          expiryYearPlaceholder: "YY",
          cvvPlaceholder: "CVV",
        },
        style: {
          base: {
            color: "rgba(255, 255, 255, 0.6)",
            fontSize: "16px",
            fontFamily: '"Roboto", sans-serif',
          },
          invalid: {
            color: "#FF0000",
          },
        },
      }}
      cardValidationChanged={() => setIsFormValid(Frames.isCardValid())}
      cardTokenized={(event) => {
        const body = {
          token: event.token,
        };
        dispatch(createPaymentAccount({ id: currentCompany.id, body })).finally(
          () => Frames.enableSubmitForm()
        );
      }}
    >
      <div
        className={cn("credit-card-form", {
          "credit-card-form--hidden": isHidden,
        })}
      >
        <div className={"credit-card-form__input-wrapper"}>
          <label className={"credit-card-form__label form-label"}>
            card holder
          </label>
          <MaskedInput
            type={"text"}
            placeholder={"Card Holder"}
            className={cn(
              "credit-card-form__input",
              "credit-card-form__input--single",
              "form-input",
              {
                "credit-card-form__input--error":
                  !isCardHolderValid && isCardHolderTouched,
              }
            )}
            value={cardHolder}
            onChange={(event) => {
              const value = event.target.value.toUpperCase();
              setCardHolder(value);
              setIsCardHolderValid(
                isShowMiddleFeature
                  ? REGEXP_CARD_HOLDER_NAME_TEST.test(value)
                  : REGEXP_CARD_HOLDER_NAME.test(value)
              );
            }}
            onBlur={() => setIsCardHolderTouched(true)}
            mask={(s) => Array.from(s).map(() => /[a-z ]/i)}
            guide={false}
          />
        </div>
        <div className={"credit-card-form__input-wrapper"}>
          <label className={"credit-card-form__label form-label"}>
            card number
          </label>
          <CardNumber
            className={
              "credit-card-form__input credit-card-form__input--single form-input"
            }
          />
        </div>
        <div className={"credit-card-form__input-wrapper"}>
          <label className={"credit-card-form__label form-label"}>expiry</label>
          <ExpiryDate className={"credit-card-form__input form-input"} />
          <label className={"credit-card-form__label form-label"}>
            cvc/cvv
          </label>
          <Cvv className={"credit-card-form__input form-input"} />
        </div>
        <div className={"credit-card-form__checkbox-wrapper"}>
          <input
            type={"checkbox"}
            checked={isPaymentPermissionChecked}
            onChange={(event) =>
              setIsPaymentPermissionChecked(event.target.checked)
            }
          />
          <label className={"credit-card-form__checkbox-label form-label"}>
            I agree to recomposer invoices being automatically charged to my
            card above
          </label>
        </div>
        <div className={"credit-card-form__checkbox-wrapper"}>
          <input
            type={"checkbox"}
            checked={isTermsPermissionChecked}
            onChange={(event) =>
              setIsTermsPermissionChecked(event.target.checked)
            }
          />
          <label className={"credit-card-form__checkbox-label form-label"}>
            I agree to the <Link to={"/terms"}>terms and conditions</Link>
          </label>
        </div>
        <div className={"credit-card-form__footer"}>
          <button
            className={"btn default-btn btn--primary"}
            disabled={isSubmitButtonDisabled}
            onClick={() => {
              setIsSubmitting(true);
              Frames.submitCard().finally(() => setIsSubmitting(false));
            }}
          >
            save
          </button>
        </div>
      </div>
    </Frames>
  );
};

CreditCardForm.propTypes = {
  isHidden: PropTypes.bool,
};

CreditCardForm.defaultProps = {
  isHidden: false,
};

export default CreditCardForm;
