import cn from "classnames";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";

import "./SlidersHeader.scss";
import {
  GOAL_FAT_MULTIPLIER,
  GOAL_SLIDER_STATE,
  GOAL_WEIGHT_MULTIPLIER,
} from "../../../../../../../constants/body";
import {
  DELAY_FOR_EDIT_GOAL,
  MAX_MOBILE_SCREEN_WIDTH,
} from "../../../../../../../constants/common";
import { TOASTR_TYPE } from "../../../../../../../constants/toastr";
import { KG } from "../../../../../../../constants/unit";
import { MALE } from "../../../../../../../constants/user";
import {
  prepareDateForBackUTC,
  prepareShortLocalDateForFront,
} from "../../../../../../../helpers";
import { useCallDebouncedCbWithAbort } from "../../../../../../../hooks/useCallDebouncedCb";
import useIsSmallScreen from "../../../../../../../hooks/useIsSmallScreen";
import { usePrevious } from "../../../../../../../hooks/usePrevious";
import { useToastr } from "../../../../../../../hooks/useToastr";
import {
  setGoals,
  setGoalView,
  clearError,
} from "../../../../../../../redux/clientSlice";
import {
  updateClientGoal,
  updateClientGoalDate,
} from "../../../../../../../redux/clientSlice";
import GoalSlider from "../GoalSlider";

import DateButton from "./components/DateButton";

const getGoalRestrictions = (gender, massUnit) => {
  massUnit = massUnit ? massUnit.toLowerCase() : KG;
  gender = gender ? gender.toLowerCase() : MALE;

  const minMaxSliderValues = {
    kg: {
      male: {
        mass: { min: 40, max: 160 },
        fat: { min: 2, max: 25 },
      },
      female: {
        mass: { min: 40, max: 120 },
        fat: { min: 6, max: 30 },
      },
    },
    lb: {
      male: {
        mass: { min: 90, max: 350 },
        fat: { min: 2, max: 25 },
      },
      female: {
        mass: { min: 90, max: 260 },
        fat: { min: 6, max: 30 },
      },
    },
  };

  return minMaxSliderValues[massUnit][gender];
};

const isCanUseLastGoal = ({ goal_weight, goal_body_fat }) =>
  goal_weight && goal_body_fat;

const SlidersHeader = () => {
  const dispatch = useDispatch();
  const body = useSelector((state) => state.client.body);
  const client = useSelector((state) => state.client.client);
  const lastGoal = useSelector((state) => state.client.goal);
  const goalView = useSelector((state) => state.client.goalView);
  const currentGoalRequests = useSelector(
    (state) => state.client.currentGoalRequests
  );
  const currentDateRequests = useSelector(
    (state) => state.client.currentDateRequests
  );
  const error = useSelector((state) => state.client.error);
  const { mass_unit: massUnit, gender } = client;

  const {
    body_weight: currentWeight,
    body_fat: currentFat,
    body_test_type: bodyTestType, //"3-site"
  } = body;

  const [goalWeight, setGoalWeight] = useState(lastGoal?.goal_weight);
  const [goalFat, setGoalFat] = useState(lastGoal?.goal_body_fat);
  const [isGoalDate, setGoalDate] = useState(Boolean(lastGoal?.end_date));
  const prevGoals = {
    goalWeight: usePrevious(goalWeight),
    goalFat: usePrevious(goalFat),
  };

  const [slidersTouched, setSlidersTouched] = useState(false);
  const isFetchingGoal = currentGoalRequests > 0 || slidersTouched;
  const isFetchingDate = currentDateRequests > 0;
  const isFetching = isFetchingGoal || isFetchingDate;

  const [endDate, setEndDate] = useState(
    lastGoal?.end_date
      ? prepareShortLocalDateForFront(lastGoal?.end_date)
      : null
  );

  const goalRestrictions = getGoalRestrictions(gender, massUnit);

  const [showSum9, setShowSum9] = useState(false);
  let sum9 = `Σ9 of ${(
    (goalFat / GOAL_FAT_MULTIPLIER) *
    goalWeight *
    GOAL_WEIGHT_MULTIPLIER
  ).toFixed(0)}mm`;

  const isMobileVersion = useIsSmallScreen(MAX_MOBILE_SCREEN_WIDTH.SLIDER_GOAL);
  useToastr({
    messages: error,
    deps: error,
    type: TOASTR_TYPE.ERROR,
    cb: () => dispatch(clearError()),
  });

  useEffect(() => {
    if (lastGoal && isCanUseLastGoal(lastGoal)) {
      setGoalValues(goalView, lastGoal);
    } else {
      setGoalWeight(currentWeight);
      setGoalFat(currentFat);
    }
  }, [currentFat, currentWeight, goalView, lastGoal]);

  useCallDebouncedCbWithAbort(
    (goalFat, goalWeight, prevGoals, goalView, setSlidersTouched) => {
      if (!(prevGoals?.goalWeight && prevGoals?.goalFat)) return;
      if (goalView !== GOAL_SLIDER_STATE.DATE) return;

      const data = createData();
      data.goal_body_fat = goalFat;
      data.goal_weight = goalWeight;
      dispatch(
        updateClientGoal({
          id: client.id,
          goal: data,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setSlidersTouched(false);
        }
      });
    },
    [goalFat, goalWeight, prevGoals, goalView, setSlidersTouched],
    DELAY_FOR_EDIT_GOAL,
    [goalFat, goalWeight]
  );

  /* По клику на кнопку goal, переключаем отображение: неделя/месяц/день */
  const getNewGoalState = (currentGoalState) => {
    const nextGoalState = {
      [GOAL_SLIDER_STATE.DATE]: GOAL_SLIDER_STATE.WEEK,
      [GOAL_SLIDER_STATE.WEEK]: GOAL_SLIDER_STATE.DAY,
      [GOAL_SLIDER_STATE.DAY]: GOAL_SLIDER_STATE.DATE,
    };

    return nextGoalState[currentGoalState];
  };

  /* При переключении отображения по кнопке 'goal', показываем другие значения goal weight и goal fat */
  const setGoalValues = (goalView, lastGoal) => {
    const actions = {
      [GOAL_SLIDER_STATE.DATE]: () => {
        setGoalWeight(lastGoal.goal_weight);
        setGoalFat(lastGoal.goal_body_fat);
      },
      [GOAL_SLIDER_STATE.WEEK]: () => {
        setGoalWeight(lastGoal.week_body_weight);
        setGoalFat(lastGoal.week_body_fat);
      },
      [GOAL_SLIDER_STATE.DAY]: () => {
        setGoalWeight(lastGoal.today_body_weight);
        setGoalFat(lastGoal.today_body_fat);
      },
    };

    return actions[goalView]?.() ?? null;
  };

  //Вызываем при клике на goal
  const changeGoalState = (goalView) => {
    const newGoalState = getNewGoalState(goalView);

    dispatch(setGoalView(newGoalState));
  };

  const onBeforeSliderChange = () => {
    setSlidersTouched(true);
    if (goalView !== GOAL_SLIDER_STATE.DATE) {
      dispatch(setGoalView(GOAL_SLIDER_STATE.DATE));
    }
  };

  const createData = () => {
    const data = {
      goal_weight: goalWeight,
      goal_body_fat: goalFat,
      start_weight: currentWeight,
      start_fat: currentFat,
    };
    return data;
  };

  const sendDate = (date) => {
    if (date === null) {
      dispatch(setGoalView(GOAL_SLIDER_STATE.DATE));
    }

    setGoalDate(!!date);
    const data = createData();
    data.end_date = date ? prepareDateForBackUTC(date) : null;

    dispatch(
      updateClientGoalDate({
        id: client.id,
        goal: data,
      })
    );
  };

  const editGoalWeight = (val) => {
    setGoalWeight(val);
    dispatch(setGoals({ goalWeight: val }));
  };

  const editGoalFat = (val) => {
    setGoalFat(val);
    dispatch(setGoals({ goalFat: val }));
  };

  const toggle9Site = () => {
    if (bodyTestType === "9-site") {
      setShowSum9(!showSum9);
    }
  };

  const headerRow = cn(
    "goal-slider-wrap-table__row",
    "goal-slider-wrap-table__row-header"
  );

  const row = "goal-slider-wrap-table__row";

  const leftCell = cn(
    "goal-slider-wrap-table__cell",
    "goal-slider-wrap-table__cell-left"
  );

  const middleCell = cn(
    "goal-slider-wrap-table__cell",
    "goal-slider-wrap-table__cell-middle"
  );

  const rightCell = cn(
    "goal-slider-wrap-table__cell",
    "goal-slider-wrap-table__cell-right"
  );

  const handleStyle = {
    marginLeft: "unset",
    transform: "translateX(-50%)",
  };

  return (
    <div className="goal-slider-wrap-table">
      <div className={headerRow}>
        <div
          className={cn(
            "goal-slider-wrap-table__cell",
            "goal-slider-wrap-table__cell-left",
            { "cell-left__title": isMobileVersion }
          )}
        >
          current
        </div>
        <div className={middleCell} />
        {lastGoal && (
          <div
            className={cn(
              "goal-slider-wrap-table__cell",
              "goal-slider-wrap-table__cell-right",
              "cell-right__title"
            )}
          >
            <button
              className="goal-state-change__button"
              onClick={() => changeGoalState(goalView)}
              disabled={isFetching || !isGoalDate}
            >
              goal
            </button>
            <DateButton
              sendDate={sendDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isMobileVersion={isMobileVersion}
              disableButton={isFetching}
              disableInput={isFetchingGoal}
            />
          </div>
        )}
        {!lastGoal && (
          <div className={rightCell}>
            {isMobileVersion && lastGoal ? "" : "goal"}
          </div>
        )}
      </div>
      <div className={row}>
        <div className={leftCell}>{currentWeight + massUnit}</div>
        <div className={middleCell}>
          <GoalSlider
            min={goalRestrictions.mass.min}
            max={goalRestrictions.mass.max}
            leftValue={currentWeight}
            showSlider
            onChange={editGoalWeight}
            onBeforeChange={onBeforeSliderChange}
            value={goalWeight}
            valueFormatter={(val) => `${val}` + massUnit}
            handleStyle={handleStyle}
            disabled={isFetchingDate || (isMobile && isFetchingGoal)}
          />
        </div>
        <div className={rightCell}>{goalWeight + massUnit}</div>
      </div>
      <div className={row}>
        <div className={leftCell}>
          {`${isMobileVersion ? "" : "at "} ${currentFat}% fat`}
        </div>
        <div className={middleCell}>
          <GoalSlider
            min={goalRestrictions.fat.min}
            max={goalRestrictions.fat.max}
            step={lastGoal?.goal_body_fat < 10 ? 0.5 : 1}
            showSlider
            leftValue={currentFat}
            onChange={editGoalFat}
            onBeforeChange={onBeforeSliderChange}
            value={goalFat}
            valueFormatter={(val) => `at ${val}% fat`}
            handleStyle={handleStyle}
            disabled={isFetchingDate || (isMobile && isFetchingGoal)}
          />
        </div>
        <div className={rightCell} onClick={toggle9Site}>
          {showSum9
            ? sum9
            : isMobileVersion
            ? goalFat + "% fat"
            : "at " + goalFat + "% fat"}
        </div>
      </div>
    </div>
  );
};

SlidersHeader.propTypes = {};

export default SlidersHeader;
