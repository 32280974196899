import "./DeleteProgramTemplate.scss";
import { useDispatch, useSelector } from "react-redux";

import { TOASTR_TYPE } from "../../constants/toastr";
import { useToastr } from "../../hooks/useToastr";
import DisabledField from "../../pages/Clients/ClientListPage/components/DisabledField";
import {
  clearError,
  clearSuccessfullyDeleteTemplate,
  deleteProgramTemplate,
} from "../../redux/programTemplatesSlice";
import Button from "../Button";
import FormLabel from "../FormLabel";
import Spinner from "../Spinner";
import Widget from "../widget/Widget";

const DeleteProgramTemplate = ({ closeWidget }) => {
  const dispatch = useDispatch();
  const itemForDelete = useSelector(
    (state) => state.programTemplates.itemForDelete
  );
  const loading = useSelector((state) => state.programTemplates.isDeleting);
  const error = useSelector((state) => state.programTemplates.error);
  const successfullyDeleteTemplate = useSelector(
    (state) => state.programTemplates.successfullyDeleteTemplate
  );

  useToastr({
    messages: "Program template has been deleted!",
    type: TOASTR_TYPE.SUCCESS,
    deps: successfullyDeleteTemplate,
    cb: () => {
      dispatch(clearSuccessfullyDeleteTemplate());
    },
  });

  useToastr({
    messages: error,
    type: TOASTR_TYPE.ERROR,
    deps: error,
    cb: () => {
      dispatch(clearError());
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(deleteProgramTemplate(itemForDelete.id)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        closeWidget();
      }
    });
  };

  const Field = ({ label, name, value }) => (
    <div className="delete-client__field-wrapper">
      <FormLabel htmlFor={name} className="delete-client__label">
        {label}
      </FormLabel>
      <DisabledField
        name={name}
        value={value}
        className="delete-client__input"
      />
    </div>
  );

  return (
    <Widget className="delete-programTemplate">
      <Widget.Header className="delete-programTemplate__title pl-10">
        <Widget.Title>delete record</Widget.Title>
      </Widget.Header>
      <Widget.Body className="delete-programTemplate__form-wrapper">
        {loading && <Spinner />}
        {!loading && (
          <>
            <Field
              label="template name"
              name="name"
              value={itemForDelete?.name}
              disabled={true}
            />
            <Button
              variant="warning"
              type="button"
              onClick={handleSubmit}
              className="delete-programTemplate__btn"
            >
              confirm delete
            </Button>
          </>
        )}
        <div className="delete-programTemplate__footer" />
      </Widget.Body>
    </Widget>
  );
};

export default DeleteProgramTemplate;
