export const REDUCED_EXERCISES_COUNT = 30;
export const REDUCED_PROGRAMS_COUNT = 3;
export const REDUCED_HISTORY_COUNT = 5;

export const PB_DEFAULT_VALUE = 50;

export const MIN_PB_COEFFICIENT = 0.3;
export const MAX_PB_COEFFICIENT = 1.5;

export const PB_STEP_THRESHOLD = 300;
export const STEP_SMALL = 0.5;
export const STEP_BIG = 2.5;

export const HISTORY_ITEM_TYPE = {
  best: "best",
  previous: "previous",
  other: "other",
};
