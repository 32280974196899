import "./PayCurrentDue.scss";
import Button from "../../../../../components/Button";
import convertCentsToDollars from "../../../../../helpers/convertCentsToDollars";
import {
  payCurrentDue,
  setCheckDebt,
  setMessage,
  loadPaymentAccount,
  setError,
  loadStripePaymentAccount,
} from "../../../../../redux/companiesSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  PAYMENT_SUCCESS,
  UNKNOWN_FAIL,
} from "../../../../../constants/payment";

const PayCurrentDue = ({ isStripe }) => {
  const dispatch = useDispatch();
  const debt = useSelector((state) => state.companies.debt);
  const currentCompanyId = useSelector(
    (state) => state.companies?.currentCompany?.id
  );
  const loading = useSelector((state) => state?.companies.loading);
  const checkDebt = useSelector((state) => state.companies.checkDebt);
  const isCardVerified = useSelector((state) => state.companies.isCardVerified);
  const paymentHandler = () => {
    dispatch(payCurrentDue({ id: currentCompanyId, debt }));
  };

  const handleDebtStatus = (is_task_finished, debt) => {
    if (!is_task_finished) {
      checkPaymentStatusHandler();
    } else {
      dispatch(setCheckDebt(false));

      if (+debt === 0) {
        dispatch(setMessage(PAYMENT_SUCCESS));
      } else {
        dispatch(setError(UNKNOWN_FAIL));
      }
    }
  };

  const checkPaymentStatusHandler = async () => {
    const loadAccount = isStripe ? loadStripePaymentAccount : loadPaymentAccount;
    const paymentAcc = await dispatch(loadAccount({ id: currentCompanyId }));
    const { is_task_finished, debt } = paymentAcc.payload;
    handleDebtStatus(is_task_finished, debt);
  };

  useEffect(() => {
    if (checkDebt) {
      checkPaymentStatusHandler();
    }
  }, [checkDebt]);

  return (
    <div className="pay-current-due">
      <div className="pay-current-due__msg-container">
        <p className="pay-current-due__msg">current due:</p>
        <p className="pay-current-due__msg pay-current-due__msg_summ">{`$${convertCentsToDollars(
          debt
        )}`}</p>
      </div>
      {isCardVerified && (
        <Button
          className="pay-current-due__button"
          type="button"
          disabled={loading}
          onClick={paymentHandler}
        >
          pay now
        </Button>
      )}
    </div>
  );
};

export default PayCurrentDue;
