import PropTypes from "prop-types";
import { useCallback, useEffect, useRef } from "react";
import { useVirtual } from "react-virtual";

import "./VirtualList.scss";
import { NO_RESULTS_MESSAGE } from "../../../constants/list";

import { useWindowSize } from "./../../../hooks/useWindowSize";

const VirtualList = ({ list, width, row, size, isDynamic }) => {
  const parentRef = useRef();
  const rowVirtualizer = useVirtual({
    size: list?.length,
    parentRef,
    estimateSize: useCallback(() => size, [size]),
  });
  const { screenWidth } = useWindowSize();

  useEffect(() => {
    if (isDynamic) {
      rowVirtualizer.scrollToIndex(0);
      parentRef.current.scrollTop = parentRef.current.scrollTop + size;
    }
  }, [isDynamic, size, screenWidth]);

  const renderRow = (virtualRow) => {
    return (
      <div
        key={virtualRow.index}
        ref={virtualRow.measureRef}
        className={virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven"}
        style={{
          position: "absolute",
          width: "100%",
          height: !isDynamic ? `${virtualRow.size}px` : "",
          transform: `translateY(${virtualRow.start}px)`,
        }}
      >
        {row(virtualRow)}
      </div>
    );
  };

  return (
    <div
      ref={parentRef}
      className="list__virtual scrollable"
      style={{
        width: width,
      }}
    >
      <div
        className="ListInner "
        style={{
          display: "block",
          height: `${rowVirtualizer.totalSize}px`,
          width: "inherit",
          position: "relative",
        }}
      >
        {Boolean(list?.length) ? (
          rowVirtualizer.virtualItems.map((virtualRow) => renderRow(virtualRow))
        ) : (
          <p className="list__virtual--no-results">{NO_RESULTS_MESSAGE}</p>
        )}
      </div>
    </div>
  );
};

VirtualList.defaultProps = {
  isDynamic: false,
  size: 40,
};

VirtualList.propTypes = {
  list: PropTypes.array,
  width: PropTypes.number,
  row: PropTypes.func,
  isDynamic: PropTypes.bool,
};

export default VirtualList;
