import PropTypes from "prop-types";

import HistoryRow from "./HistoryRow/HistoryRow";
import "./HistoryItem.scss";

const HistoryItem = ({ data, title }) => {
  return (
    <div className="history-item">
      {title && <div className="history-item__title">{title}</div>}
      {data.map((set) =>
        set && typeof set === "object" ? (
          <HistoryRow {...set} key={set.id} />
        ) : typeof set === "string" ? (
          <div className="history-item--empty">{set}</div>
        ) : (
          set === null && ""
        )
      )}
    </div>
  );
};

HistoryItem.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default HistoryItem;
