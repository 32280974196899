import "./BodyCompInfo.scss";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import useIsSmallScreen from "../../../../../../../hooks/useIsSmallScreen";

const BodyCompInfo = ({ bodyParams }) => {
  const massUnit = useSelector((state) => state.client.client.mass_unit);
  const isSmallScreen = useIsSmallScreen();
  return (
    <div className="body-comp-graph-info">
      <div className="text-centered">
        <h2>
          {bodyParams.goalWeight >= bodyParams.currentWeight
            ? "eat on"
            : "diet off"}
        </h2>
        <div className="text-description">
          {`${bodyParams.needWeight} ${massUnit}`} weight
        </div>
      </div>
      <div className="text-centered">
        <h2>{bodyParams.needMuscle >= 0 ? "train on" : "cannibalise"}</h2>
        <div className="text-description">
          {`${Math.abs(bodyParams.needMuscle)} ${massUnit}`} muscle
        </div>
      </div>
      <div className="text-centered">
        <h2>
          for{isSmallScreen ? <br /> : ""} net fat{" "}
          {bodyParams.needFat >= 0 ? "gain" : "loss"} of{" "}
          {!isSmallScreen ? <br /> : null}{" "}
          <div className="text-description">
            {`${Math.abs(bodyParams.needFat)} ${massUnit}`}
          </div>
        </h2>
      </div>
      {bodyParams.needMuscle >= 0 ? (
        <div className="flex-column">
          <h2>strength gain of</h2>
          <div className="flex-column">
            <div className="text-description">
              squat +{`${Math.round(bodyParams.diffSquat)} ${massUnit}`}
            </div>
            <div className="text-description">
              bench +{`${Math.round(bodyParams.diffBench)} ${massUnit}`}
            </div>
            <div className="text-description">
              deadlift +{`${Math.round(bodyParams.diffDeadlift)} ${massUnit}`}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

BodyCompInfo.propTypes = {
  bodyParams: PropTypes.object,
};

export default BodyCompInfo;
