import "./LeanGraphContent.scss";
import PropTypes from "prop-types";

import { MAX_MOBILE_SCREEN_WIDTH } from "../../../../../../../constants/common";
import useIsSmallScreen from "../../../../../../../hooks/useIsSmallScreen";

const HEIGHT_FOR_SHOW_FULL_INFO = 50;

const LeanGraphContent = ({ val, name, unit, height, heightSlider }) => {
  const isMobileVersion = useIsSmallScreen(MAX_MOBILE_SCREEN_WIDTH.SLIDER_GOAL);
  const isShowFullInfo =
    (height * heightSlider) / 100 > HEIGHT_FOR_SHOW_FULL_INFO;
  return (
    <div className={`lean-content lean-content__${name}`}>
      <span>
        {val > 0 && val}
        {!isMobileVersion && val > 0 && unit}
      </span>
      {isShowFullInfo && val > 0 && <span>{name}</span>}
    </div>
  );
};

LeanGraphContent.propTypes = {
  val: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  heightSlider: PropTypes.number.isRequired,
};

export default LeanGraphContent;
