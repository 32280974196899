import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "../../../../../components/Button";
import Spinner from "../../../../../components/Spinner";
import Switch from "../../../../../components/Switch";
import VirtualList from "../../../../../components/list/VirtualList";
import Widget from "../../../../../components/widget/Widget";
import { USER_TYPES } from "../../../../../constants/common";
import { EXERCISE_TYPES } from "../../../../../constants/exercises";
import { SIDEBAR_MODE } from "../../../../../constants/sidebar";
import { createPermittedIdList } from "../../../../../helpers";
import useFilteredList from "../../../../../hooks/useFilteredList";
import { useToastr } from "../../../../../hooks/useToastr";
import {
  clearSuccessfullyExercisesAdded,
  clearSuccessfullyExercisesDelete,
  clearSuccessfullyExercisesEdited,
  loadExercises,
} from "../../../../../redux/exercisesSlice";
import "./ExercisesWidget.scss";
import ExerciseEditor from "../../../../Exercises/ExercisesPage/components/ExerciseEditor";

import ExerciseRow from "./ExerciseRow/ExerciseRow";

const ExercisesWidget = ({
  edit,
  onBack,
  onExerciseClick,
  changeAllExercise,
  setChangeAllExercise,
}) => {
  const dispatch = useDispatch();
  const exercises = useSelector((state) => state.exercises.exercises);
  const availableExercises = useMemo(
    () => exercises?.filter((exercise) => !exercise.is_deleted),
    [exercises]
  );
  const loading = useSelector((state) => state.exercises.loading);
  const successfullyExercisesAdded = useSelector(
    (state) => state.exercises.successfullyExercisesAdded
  );
  const successfullyExercisesEdited = useSelector(
    (state) => state.exercises.successfullyExercisesEdited
  );
  const successfullyExercisesDelete = useSelector(
    (state) => state.exercises.successfullyExercisesDelete
  );
  const favs = useSelector((state) => state.user.user?.fav_exercises);

  const userType = useSelector((state) => state.user.user?.type);
  const isSuper = userType === USER_TYPES.SUPERUSER;
  const isClient = userType === USER_TYPES.CLIENT;

  const userId = useSelector((state) => state.user.user?.id);
  const userCompanyId = useSelector((state) => state.user.user?.company);

  const [searchValue, setSearchValue] = useState("");
  const [editorMode, setEditorMode] = useState(null);

  const filteredList = useFilteredList(availableExercises, searchValue);
  const currentFavs = useMemo(
    () => availableExercises?.filter((e) => favs?.includes(e.id)),
    [availableExercises, favs]
  );
  const hasFavsEmptySearch = currentFavs?.length > 0 && searchValue === "";

  const list = useMemo(() => {
    const exercises = hasFavsEmptySearch ? currentFavs : filteredList;
    return isSuper
      ? exercises.filter(
          (ex) =>
            ex.type === EXERCISE_TYPES.GLOBAL ||
            EXERCISE_TYPES.SYSTEM_REG.test(ex.type)
        )
      : exercises;
  }, [availableExercises, favs, filteredList, searchValue]);

  useEffect(() => {
    isEmpty(exercises) && dispatch(loadExercises());
  }, []);

  useEffect(() => {
    setEditorMode(null);
  }, [
    successfullyExercisesAdded,
    successfullyExercisesEdited,
    successfullyExercisesDelete,
  ]);

  useToastr({
    messages: "The exercise has been added",
    deps: successfullyExercisesAdded,
    cb: () => dispatch(clearSuccessfullyExercisesAdded()),
  });
  useToastr({
    messages: "The exercise has been edited",
    deps: successfullyExercisesEdited,
    cb: () => dispatch(clearSuccessfullyExercisesEdited()),
  });
  useToastr({
    messages: "The exercise has been deleted",
    deps: successfullyExercisesDelete,
    cb: () => dispatch(clearSuccessfullyExercisesDelete()),
  });

  const perms = createPermittedIdList(
    availableExercises,
    userType,
    userId,
    userCompanyId
  );

  const onSearchChange = (e) => setSearchValue(e.target.value);

  const handleNewExerciseClick = () => {
    setEditorMode(SIDEBAR_MODE.ADD);
  };

  return editorMode ? (
    <ExerciseEditor
      sidebarMode={editorMode}
      onBack={() => setEditorMode(null)}
      onDelete={
        editorMode === SIDEBAR_MODE.EDIT
          ? () => setEditorMode(SIDEBAR_MODE.DELETE)
          : null
      }
    />
  ) : (
    <Widget className="program-template-side">
      <Widget.Header className="pl-10">
        <Widget.Title>{`${edit ? "edit" : "add"} exercise`}</Widget.Title>
        {onBack && (
          <Button type="button" onClick={onBack}>
            back
          </Button>
        )}
      </Widget.Header>
      <Widget.Body>
        <div className="add-exercise-block">
          <div className="add-exercise-block__input-wrapper">
            <FontAwesomeIcon
              icon={faSearch}
              className="add-exercise-block__search-icon"
            />
            <input
              type="text"
              className="add-exercise-block__input"
              placeholder="exercise's title"
              onChange={onSearchChange}
              value={searchValue}
            />
            {!!setChangeAllExercise && edit && (
              <span className="add-exercise-block__change-all">
                all
                <Switch
                  checked={changeAllExercise}
                  onChange={(e) => setChangeAllExercise(e.target.checked)}
                  ariaLabel="edit all same exercises"
                />
              </span>
            )}
          </div>
          <div className="add-exercise-block__list">
            {!isClient && (
              <button
                className="add-exercise-block__btn"
                type="button"
                onClick={handleNewExerciseClick}
              >
                add new exercise...
              </button>
            )}
            {hasFavsEmptySearch && (
              <div className="add-exercise-block__fav-header">
                favourite exercises
              </div>
            )}
            {loading ? (
              <Spinner />
            ) : (
              <VirtualList
                list={list}
                row={(virtualRow) => {
                  const item = list[virtualRow.index];
                  return (
                    <ExerciseRow
                      key={item.id}
                      data={item}
                      onClick={onExerciseClick}
                      setEditMode={() => setEditorMode(SIDEBAR_MODE.EDIT)}
                      perms={perms}
                    />
                  );
                }}
              />
            )}
          </div>
        </div>
      </Widget.Body>
    </Widget>
  );
};

ExercisesWidget.propTypes = {
  edit: PropTypes.string,
  onBack: PropTypes.func,
  onExerciseClick: PropTypes.func.isRequired,
  changeAllExercise: PropTypes.bool,
  setChangeAllExercise: PropTypes.func,
};

ExercisesWidget.defaultProps = {
  edit: true,
  onBack: null,
  setChangeAllExercise: null,
};

export default ExercisesWidget;
