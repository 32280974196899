import cn from "classnames";
import { useRef } from "react";
import "./ProgressValues.scss";
import { useSelector } from "react-redux";

import { NOT_APPLICABLE } from "../../../constants/progress";
import {
  prepareShortDateDotsForFrontFromLong,
  prepareShortLocalDateDotsForFront,
} from "../../../helpers";
import { dietPercentageToString } from "../../../helpers/diet";
import TableTooltip from "../../TableTooltip";

import DoubleDietTooltips from "./components/DoubleDietTooltips";

const defaultDiffs = {
  body: {
    weight: null,
    fat: null,
    lean: null,
    fatMass: null,
  },
  strength: {
    squat: null,
    bench: null,
    deadlift: null,
  },
  training: {
    squat: null,
    bench: null,
    deadlift: null,
  },
};

const ProgressValues = ({
  progress,
  diffs = defaultDiffs,
  isLatest = false,
  fullPage = false,
}) => {
  const skinfoldRef = useRef();
  const notesRef = useRef();

  const massUnit = useSelector((state) => state.client.client.mass_unit);

  let {
    body,
    strength,
    training,
    change_date,
    first_diet,
    second_diet,
    diets_average,
  } = progress;

  const isEmptyTraining =
    training?.squat === NOT_APPLICABLE &&
    training?.bench === NOT_APPLICABLE &&
    training?.deadlift === NOT_APPLICABLE &&
    !training?.days_per_week &&
    !training?.pbs_per_workout &&
    !training?.sets_per_workout;

  const skinFoldParsed = body?.skin_fold?.includes("/")
    ? body?.skin_fold
        ?.split("/")
        .map((item) => Math.round(item))
        .join("/")
    : body?.skin_fold;

  const percentage =
    first_diet?.percentage &&
    Object.values(first_diet.percentage).some((i) => i !== 0)
      ? first_diet.percentage
      : null;

  const skinNotesParsed = body?.skin_notes
    ?.replaceAll(".0,", ",")
    .replace(/.0$/, "");

  const strengthUnit =
    strength?.strength_type === "estimate" ? `${massUnit}*` : massUnit;

  const replaceTrainingNull = (val) =>
    val === "n/a" ? "n/a" : `${val}${massUnit}`;

  const progressDateClass = cn("progress--latest__date", { hidden: !isLatest });

  return (
    <table
      className={cn("progress__table-content", "progress", {
        "progress--latest": isLatest,
        "progress--paginated": !isLatest,
        "progress--widget-latest": isLatest && !fullPage,
        "progress--widget-paginated": !isLatest && !fullPage,
        "progress--full-page": fullPage,
      })}
    >
      <thead>
        <tr>
          <td className="progress__date">
            <div>
              {(isLatest && "latest") ||
                prepareShortLocalDateDotsForFront(change_date)}
            </div>
          </td>
        </tr>
        <tr className={progressDateClass}>
          <td>
            <div>
              {body?.date && prepareShortLocalDateDotsForFront(body?.date)}
            </div>
          </td>
        </tr>
      </thead>
      <tbody className={cn({ hidden: !body })}>
        <tr className="progress__tr">
          <td hidden={!diffs?.body?.weight} className="progress__diff">
            <div>{`${diffs?.body?.weight}${massUnit}`}</div>
          </td>
          <td className="progress__value">
            <div>
              {body?.body_weight &&
                `${body.body_weight?.toFixed(1)}${massUnit}`}
            </div>
          </td>
        </tr>
        <tr className="progress__tr">
          <td hidden={!diffs?.body?.fat} className="progress__diff">
            <div>{`${diffs?.body?.fat}`}%</div>
          </td>
          <td className="progress__value">
            <div>{body?.body_fat?.toFixed(1)}%</div>
          </td>
        </tr>
        <tr className="progress__tr">
          <td hidden={!diffs?.body?.lean} className="progress__diff">
            <div>{`${diffs?.body?.lean}${massUnit}`}</div>
          </td>
          <td className="progress__value">
            <div>
              {body?.lean_mass && `${body.lean_mass?.toFixed(1)}${massUnit}`}
            </div>
          </td>
        </tr>
        <tr className="progress__tr">
          <td hidden={!diffs?.body?.fatMass} className="progress__diff">
            <div>{`${diffs?.body?.fatMass}${massUnit}`}</div>
          </td>
          <td className="progress__value">
            <div>{body && `${body.fat_mass?.toFixed(1)}${massUnit}`}</div>
          </td>
        </tr>
        <tr className="progress__tr">
          <td className="progress__value">
            <TableTooltip
              ref={skinfoldRef}
              overlay={<span>{skinNotesParsed}</span>}
              content={skinFoldParsed}
            />
          </td>
        </tr>
      </tbody>
      <thead>
        <tr
          className={cn("progress__tr progress--latest__date", {
            hidden: (!first_diet?.calories && !percentage) || !isLatest,
          })}
        >
          <td>
            <div>
              {first_diet?.start_date &&
                prepareShortLocalDateDotsForFront(first_diet?.start_date)}
            </div>
          </td>
        </tr>
      </thead>
      <tbody
        className={cn({
          hidden: !first_diet || (!first_diet?.calories && !percentage),
        })}
      >
        {diets_average && first_diet && second_diet ? (
          <DoubleDietTooltips
            firstDiet={first_diet}
            secondDiet={second_diet}
            avg={diets_average}
          />
        ) : (
          <>
            <tr key={1} className="progress__tr">
              <td>
                <div>
                  {+first_diet?.calories !== 0 &&
                    first_diet?.calories?.toFixed(0)}
                </div>
              </td>
            </tr>
            <tr key={2} className="progress__tr">
              <td>
                <div>{dietPercentageToString(percentage)}</div>
              </td>
            </tr>
          </>
        )}
        <tr className="progress__tr">
          <td>
            <div>{body?.compliance && `${body?.compliance}%`}</div>
          </td>
        </tr>
        <tr className="progress__tr">
          <td>
            <div>{body && body.alcohol}</div>
          </td>
        </tr>
      </tbody>
      <thead>
        <tr className={progressDateClass}>
          <td>
            <div>
              {strength?.date &&
                prepareShortLocalDateDotsForFront(strength?.date)}
            </div>
          </td>
        </tr>
      </thead>
      <tbody className={cn({ hidden: !strength })}>
        <tr className="progress__tr">
          {!isLatest && (
            <td hidden={!diffs?.strength?.squat} className="progress__diff">
              <div>{`${diffs?.strength?.squat}${strengthUnit}`}</div>
            </td>
          )}
          <td className="progress__value">
            <div>
              {strength?.squat &&
                `${strength.squat?.toFixed(0)}${strengthUnit}`}
            </div>
          </td>
        </tr>
        <tr className="progress__tr">
          {!isLatest && (
            <td hidden={!diffs?.strength?.bench} className="progress__diff">
              <div>{`${diffs?.strength?.bench}${strengthUnit}`}</div>
            </td>
          )}
          <td className="progress__value">
            <div>
              {strength?.bench &&
                `${strength.bench?.toFixed(0)}${strengthUnit}`}
            </div>
          </td>
        </tr>
        <tr className="progress__tr">
          {!isLatest && (
            <td hidden={!diffs?.strength?.deadlift} className="progress__diff">
              <div>{`${diffs?.strength?.deadlift}${strengthUnit}`}</div>
            </td>
          )}
          <td className="progress__value">
            <div>
              {strength?.deadlift &&
                `${strength.deadlift?.toFixed(0)}${strengthUnit}`}
            </div>
          </td>
        </tr>
      </tbody>
      <thead>
        <tr
          className={cn("progress--latest__date progress__tr", {
            hidden: !isLatest || !training?.date,
          })}
        >
          <td>
            <div>
              {training?.date &&
                prepareShortDateDotsForFrontFromLong(training?.date)}
            </div>
          </td>
        </tr>
      </thead>
      <tbody className={cn({ hidden: isEmptyTraining })}>
        <tr className="progress__tr">
          {!isLatest && (
            <td hidden={!diffs?.training?.squat} className="progress__diff">
              <div>{replaceTrainingNull(diffs?.training?.squat)}</div>
            </td>
          )}
          <td className="progress__value">
            <div>{replaceTrainingNull(training?.squat)}</div>
          </td>
        </tr>
        <tr className="progress__tr">
          {!isLatest && (
            <td hidden={!diffs?.training?.bench} className="progress__diff">
              <div>{replaceTrainingNull(diffs?.training?.bench)}</div>
            </td>
          )}
          <td className="progress__value">
            <div>{replaceTrainingNull(training?.bench)}</div>
          </td>
        </tr>
        <tr className="progress__tr">
          {!isLatest && (
            <td hidden={!diffs?.training?.deadlift} className="progress__diff">
              <div>{replaceTrainingNull(diffs?.training?.deadlift)}</div>
            </td>
          )}
          <td className="progress__value">
            <div>{replaceTrainingNull(training?.deadlift)}</div>
          </td>
        </tr>
        <tr className="progress__tr">
          <td className="progress__value">
            <div>{training?.days_per_week}</div>
          </td>
        </tr>
        <tr className="progress__tr">
          <td className="progress__value">
            <div>{training?.sets_per_workout}</div>
          </td>
        </tr>
        <tr className="progress__tr">
          <td className="progress__value">
            <div>{training?.pbs_per_workout}</div>
          </td>
        </tr>
      </tbody>
      <thead>
        <tr className="progress__tr">
          <td className={cn({ hidden: !body?.notes })}>
            <TableTooltip
              ref={notesRef}
              overlay={<span>{body?.notes}</span>}
              contentWrapperClassName="ellipsis"
              content={body?.notes}
              hasOverflowingContent={true}
            />
          </td>
        </tr>
      </thead>
    </table>
  );
};

export default ProgressValues;
