import "./FormCheckbox.scss";
import { Box } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";

import FormLabel from "../FormLabel";

const FormCheckbox = ({ label, isShowLabelFirst, submitOnBlur, ...props }) => {
  const { handleSubmit } = useFormikContext();

  const handleBlur = () => {
    if (submitOnBlur) {
      handleSubmit();
    }
  };

  const handleClick = (event) => {
    event.target.focus();
  };

  return (
    <FormLabel>
      <Box
        display="flex"
        alignItems="center"
        mt="10px"
        flexDirection={isShowLabelFirst ? "row-reverse" : "row"}
      >
        <Field
          type="checkbox"
          className={`checkbox ${props.className}`}
          onBlur={handleBlur}
          onClick={handleClick}
          {...props}
        />
        {label}
      </Box>
    </FormLabel>
  );
};

FormCheckbox.propTypes = {
  label: PropTypes.string,
  isShowLabelFirst: PropTypes.bool,
  submitOnBlur: PropTypes.bool,
  className: PropTypes.string,
};

FormCheckbox.defaultProps = {
  isShowLabelFirst: false,
  submitOnBlur: false,
};

export default FormCheckbox;
