import cn from "classnames";
import React from "react";

import { EDITED_TYPES } from "../../../../../../../constants/templates";

const ReplaceListElement = ({ type, oldValue, newValue, handleChange }) => {
  const isWeight = type === EDITED_TYPES.WEIGHT;
  const sizeName = isWeight ? "%" : "reps";
  return (
    <div className="tools-block__change-section-line">
      <span className="tools-block__change-section-number">
        {oldValue + sizeName}
      </span>
      <span className="tools-block__change-section-text">to</span>
      <input
        type="text"
        className={cn("tools-block__input tools-block__input--sm", {
          "field-error": newValue === "",
        })}
        value={newValue}
        onChange={handleChange}
      />
      <span className="tools-block__change-section-percent">{sizeName}</span>
    </div>
  );
};

export default ReplaceListElement;
