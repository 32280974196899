import lodash from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { GRAPH_TYPE } from "../../../../../../../constants/body";
import { UTC_FORMAT_ON_BACKEND } from "../../../../../../../constants/common";
import { BasicHelper } from "../../../../../../../helpers";
import Graph from "../Graph";
import LeanGraphContent from "../LeanGraphContent";
import StrengthGraphContent from "../StrengthGraphContent";
import "./BodyStrengthGraph.scss";

const calculateHeightBody = (bodyWeight, goalWeight, type) => {
  let current = bodyWeight;
  let goal = goalWeight;

  let ratio = BasicHelper.roundTo(
    current > goal ? goal / current : current / goal,
    2
  );

  if (type === GRAPH_TYPE.CURRENT) {
    return `${(current > goal ? 1 : ratio) * 100}%`;
  }
  return `${(current > goal ? ratio : 1) * 100}%`;
};

const calculateStrengthSize = (
  strengthTest,
  goalStrengthTest,
  height,
  type
) => {
  let maxGoalStrengthTest =
    goalStrengthTest?.deadlift +
    goalStrengthTest?.squat +
    goalStrengthTest?.bench;
  let maxStrengthTest =
    strengthTest?.deadlift + strengthTest?.squat + strengthTest?.bench;
  let ratio = BasicHelper.roundTo(
    maxGoalStrengthTest > maxStrengthTest
      ? maxStrengthTest / maxGoalStrengthTest
      : maxGoalStrengthTest / maxStrengthTest,
    2
  );
  if (type === GRAPH_TYPE.CURRENT) {
    return `${
      height * 0.8 * (maxGoalStrengthTest > maxStrengthTest ? ratio : 1)
    }px`;
  }
  return `${
    height * 0.8 * (maxGoalStrengthTest > maxStrengthTest ? 1 : ratio)
  }px`;
};

const BodyStrengthGraph = ({ height, type, bodyParams }) => {
  const strength = useSelector((state) => state.client.strength);
  const client = useSelector((state) => state.client.client);
  const progress = useSelector((state) => state.client.latest);
  const body = useSelector((state) => state.client.body);

  const [strengthTest, setStrengthTest] = useState(null);

  useEffect(() => {
    let test = null;
    if (!lodash.isEmpty(strength) && !lodash.isEmpty(body)) {
      test = lodash.cloneDeep(strength);
      if (
        !lodash.isEmpty(progress) &&
        moment(strength.date, UTC_FORMAT_ON_BACKEND).unix() <
          moment(body.date, UTC_FORMAT_ON_BACKEND).unix()
      ) {
        test = lodash.cloneDeep(progress.strength);
      }
    }
    setStrengthTest(test);
  }, [strength, body, progress]);

  // высота блока, в котором находятся графики.
  let percentHeightWrap = calculateHeightBody(
    bodyParams.currentWeight,
    bodyParams.goalWeight,
    type
  );
  // высота графика с жиром/весом
  let heightSlider =
    (Number(percentHeightWrap.substr(0, percentHeightWrap.length - 1)) / 100) *
    height;
  // высота части графика с весом в графике c жиром/весом
  let percentHeightLean = BasicHelper.roundTo(
    Number(bodyParams[`${type}PercentMuscleMass`]),
    1
  );
  // высота части графика с жиром в графике c жиром/весом
  const percentHeightFat = 100 - percentHeightLean;

  let goalStrengthTest = { ...strengthTest };

  for (let i in goalStrengthTest) {
    goalStrengthTest[i] +=
      bodyParams["diff" + i[0].toUpperCase() + i.substr(1)];
    if (goalStrengthTest[i] < 0) goalStrengthTest[i] = 0;
  }
  // высота блока с стресс тестами
  let strengthSize = calculateStrengthSize(
    strengthTest,
    goalStrengthTest,
    height,
    type
  );

  const lean = BasicHelper.roundTo(Number(bodyParams[`${type}MuscleMass`]), 1);
  const fat = BasicHelper.roundTo(Number(bodyParams[`${type}FatMass`]), 1);

  const testForShow =
    type === GRAPH_TYPE.GOAL ? goalStrengthTest : strengthTest;
  return (
    <div
      className={`body-comp-graph-col-wrap body-comp-graph-col-wrap-${type}`}
      style={{ height }}
    >
      <div className="body-slider" style={{ height: percentHeightWrap }}>
        <Graph
          height={heightSlider}
          percentHeightMain={percentHeightLean}
          percentHeightSub={percentHeightFat}
          mainContent={
            <LeanGraphContent
              heightSlider={heightSlider}
              val={lean}
              name="lean"
              unit={client.mass_unit}
              height={percentHeightLean}
            />
          }
          subContent={
            <LeanGraphContent
              heightSlider={heightSlider}
              val={fat}
              name="fat"
              unit={client.mass_unit}
              height={percentHeightFat}
            />
          }
        />
      </div>
      <div className="body-slider strength-slider">
        {!lodash.isEmpty(testForShow) && (
          <Graph
            height={strengthSize}
            mainContent={
              <StrengthGraphContent
                strengthTest={testForShow}
                unit={client.mass_unit}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

BodyStrengthGraph.propTypes = {
  height: PropTypes.any,
  type: PropTypes.string,
  bodyParams: PropTypes.object,
};

export default BodyStrengthGraph;
