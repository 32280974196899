import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";

import "../../../assets/styles-old/pages/clients/clients.scss";
import "./List.scss";

import { ReactComponent as NextPageArrow } from "../../../assets/svg/next-page-arrow.svg";
import { ReactComponent as PrevPageArrow } from "../../../assets/svg/prev-page-arrow.svg";
import { ITEMS_PER_PAGE } from "../../../constants/common";
import { NO_RESULTS_MESSAGE } from "../../../constants/list";
import Spinner from "../../Spinner";
import ListHeader from "../ListHeader";
import ListItem from "../ListItem";
import PageLabel from "../PageLabel";
import VirtualList from "../VirtualList";

const List = ({
  header,
  list,
  page,
  totalCount,
  loadPrevPage,
  loadNextPage,
  hideEditBtn = false,
  deleteItemHandler,
  itemClickHandler,
  searchValue,
  onSearchChange,
  isShowPagination,
  rightCellsProps,
  isLoading,
  customRows = null,
  customAmountPerPage,
  onClick,
  zeroRound,
  size,
  center,
  nonRemovableItems = [],
  currentItem,
  isEdit,
  isDelete,
  ...props
}) => {
  let prevPageExists = page > 1;
  let nextPageExists = totalCount > page * ITEMS_PER_PAGE;
  const headerRef = useRef();
  const [tableWidth, setTableWidth] = useState(null);

  useEffect(() => {
    const onTransitionEnd = () => setTableWidth(headerRef.current.offsetWidth);
    document.addEventListener("transitionend", onTransitionEnd);
    return () => document.removeEventListener("transitionend", onTransitionEnd);
  }, []);

  useEffect(() => {
    // Если поведение сломается https://medium.com/welldone-software/usecallback-might-be-what-you-meant-by-useref-useeffect-773bc0278ae
    setTableWidth(headerRef.current.offsetWidth);
  }, [headerRef.current?.offsetWidth]);

  const row = (virtualRow) => {
    return (
      <ListItem
        className="list-item"
        key={list[virtualRow.index].id}
        item={list[virtualRow.index]}
        editDisabled={currentItem === list[virtualRow.index].id && isEdit}
        hideEditBtn={hideEditBtn}
        deleteDisabled={currentItem === list[virtualRow.index].id && isDelete}
        deleteItemHandler={() => deleteItemHandler(list[virtualRow.index])}
        itemClickHandler={() => itemClickHandler(list[virtualRow.index].id)}
        rightCellsProps={rightCellsProps}
        onClick={onClick}
        header={header}
        zeroRound={zeroRound}
        center={center}
        isCanRemove={
          !nonRemovableItems.find((i) => i.id === list[virtualRow.index].id) &&
          (list[virtualRow.index].isRemovable ?? true)
        }
        {...props}
      />
    );
  };

  return (
    <>
      <div
        ref={headerRef}
        className="list"
        style={{ overflowX: isMobile && "auto" }}
      >
        <table className="table list__table">
          <ListHeader
            header={header}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            cellCount={Object.keys(header).length}
          />
          {!isLoading && (
            <tbody>
              {isShowPagination &&
                (Boolean(list?.length || customRows) ? (
                  <>
                    {customRows
                      ? customRows
                      : list?.map((item) => (
                          <ListItem
                            key={item.id}
                            item={item}
                            deleteDisabled={currentItem === item.id && isDelete}
                            editDisabled={currentItem === item.id && isEdit}
                            isCanRemove={
                              !nonRemovableItems.find(
                                (i) => i.id === item.id
                              ) &&
                              (item.isRemovable ?? true)
                            }
                            deleteItemHandler={() => deleteItemHandler(item)}
                            itemClickHandler={() => itemClickHandler(item.id)}
                            rightCellsProps={rightCellsProps}
                            onClick={onClick}
                            useEllipsis={false}
                            {...props}
                          />
                        ))}
                    <tr>
                      <td colSpan={4}>
                        <div className="list-arrow-wrapper">
                          <PrevPageArrow
                            width="25px"
                            heigth="25px"
                            className={cn("body-arrow", {
                              "body-arrow--disabled": !prevPageExists,
                            })}
                            onClick={loadPrevPage}
                          />
                          <PageLabel
                            page={page}
                            itemsCount={
                              customAmountPerPage
                                ? customAmountPerPage
                                : list?.length
                            }
                            totalCount={totalCount}
                            perPage={ITEMS_PER_PAGE}
                          />
                          <NextPageArrow
                            width="25px"
                            heigth="25px"
                            className={cn("body-arrow", {
                              "body-arrow--disabled": !nextPageExists,
                            })}
                            onClick={loadNextPage}
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td />
                    <td>
                      <h1>{NO_RESULTS_MESSAGE}</h1>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </table>
        {!isLoading && !isShowPagination && (
          <tbody className="list__virtual">
            {customRows ? (
              customRows
            ) : (
              <VirtualList
                width={tableWidth}
                list={list}
                row={row}
                size={size}
              />
            )}
          </tbody>
        )}
      </div>
      {isLoading && <Spinner />}
    </>
  );
};

List.defaultProps = {
  isEdit: true,
  isDelete: true,
};

export default List;
