import "./HomePage.scss";
import React from "react";
import { useSelector } from "react-redux";

import Spinner from "../../../components/Spinner";

const HomePage = () => {
  const user = useSelector((state) => state.user.user);

  return (
    <div className="home__wrapper">
      <div className="content-rhs content">
        {!user && <Spinner />}
        {user && (
          <h1 className="home__text">
            Welcome back, {user?.first_name || "user"}!
          </h1>
        )}
      </div>
    </div>
  );
};

HomePage.propTypes = {};

export default HomePage;
