import React from "react";

import Widget from "../../components/widget/Widget";
import useHideMenu from "../../hooks/useHideMenu";
import "./Terms.scss";
import { PRIVACY } from "../../constants/router";

import { Link } from "react-router-dom";

const TermsPage = () => {
  useHideMenu();

  return (
    <div className="content-rhs content content--terms-privacy-form">
      <div className="page-content d-flex">
        <div className="body-main left-widget__wrapper">
          <Widget>
            <div className="terms-privacy-form">
              <b className="terms-privacy__title">Terms of use</b>
              <div>
                Last Updated: June 2021
                <br />
                <br />
                PLEASE READ THESE TERMS OF USE CAREFULLY. BY ACCESSING OR USING
                ANY RECOMPOSER WEBSITE OR MOBILE APPLICATION (OR ANY CONTENT,
                PRODUCT, SERVICE, OR FEATURE AVAILABLE THROUGH THE WEBSITE OR
                MOBILE APPLICATION) (COLLECTIVELY, THE “SITE”), YOU AGREE TO
                ABIDE BY AND BE BOUND BY THE TERMS DESCRIBED HEREIN AND BY ALL
                TERMS, POLICIES AND GUIDELINES INCORPORATED BY REFERENCE, AS
                WELL AS ANY ADDITIONAL TERMS AND RESTRICTIONS PRESENTED IN
                RELATION TO SPECIFIC CONTENT OR A SPECIFIC PRODUCT, SERVICE OR
                FEATURE (COLLECTIVELY, THE “SITE TERMS”). IF YOU DO NOT AGREE TO
                ALL OF THESE TERMS, DO NOT USE THE SITE.
                <br />
                <br />
                By using the Site, you represent and warrant that you are over
                the age of 18 and are lawfully able to accept these Site Terms.
                If you are using the Site on behalf of any entity, you further
                represent and warrant that you are authorised to accept these
                Site Terms on such entity’s behalf, and that such entity agrees
                to indemnify Recomposer for violations of these Site Terms.
              </div>
              <span>Privacy policy</span>
              <div>
                Recomposer is committed to protecting your privacy. Please refer
                to our{" "}
                <Link className="terms-link" to={PRIVACY}>
                  privacy policy
                </Link>{" "}
                for information on how we collect, use and disclose personal
                information.
              </div>
              <span>Ownership of the site and its contents</span>
              <div>
                The Site is owned by Recomposer FZE, a company registered in the
                United Arab Emirates (UAE) with its registered office at P.O.
                Box 4422, Creative Tower Fujairah, Fujairah, UAE (“We” or “Us”
                as the case may be). For the purposes of these Terms &amp;
                Conditions, the terms “We” and “Us” refer to Recomposer FZE.
                Unless otherwise indicated, all of the content featured or
                displayed on the Site, including, but not limited to, text,
                graphics, data, photographic images, moving images, sound,
                illustrations, software, and the selection and arrangement
                thereof (“Recomposer Content”), is owned by Recomposer, its
                licensors, or its third-party partners. All elements of the
                Site, including the Recomposer Content, are protected by
                copyright, trade dress, moral rights, trademark and other laws
                relating to the protection of intellectual property. We also
                appreciate any feedback or other suggestions about our services
                that you may submit to us, but you understand that we may use
                such feedback or suggestions without any obligation to
                compensate you for them.
              </div>
              <span>Use of the site</span>
              <div>
                The Site and the Recomposer Content are intended for customers
                of Recomposer. You may not use the Site or the Recomposer
                Content for any purpose not related to your business with
                Recomposer. You are specifically prohibited from:
                <ol>
                  <li>
                    downloading, copying, or re-transmitting any or all of the
                    Site or the Recomposer Content without, or in violation of,
                    a written license or agreement with Recomposer FZE;
                  </li>
                  <li>
                    using any data mining, robots or similar data gathering or
                    extraction methods;
                  </li>
                  <li>
                    manipulating or otherwise displaying the Site or the
                    Recomposer Content by using framing or similar navigational
                    technology;
                  </li>
                  <li>
                    registering, subscribing, unsubscribing, or attempting to
                    register, subscribe, or unsubscribe any party for any
                    Recomposer product or service if you are not expressly
                    authorised by such party to do so;
                  </li>
                  <li>
                    reverse engineering, altering or modifying any part of the
                    Site or the Recomposer Content;
                  </li>
                  <li>
                    circumventing, disabling or otherwise interfering with
                    security-related features of the Site or any system
                    resources, services or networks connected to or accessible
                    through the Site;
                  </li>
                  <li>
                    selling, licensing, leasing, or in any way commercialising
                    the Site or the Recomposer Content without specific written
                    authorisation from Recomposer; and
                  </li>
                  <li>
                    using the Site or the Recomposer Content other than for its
                    intended purpose.
                  </li>
                </ol>
                Such unauthorised use may also violate applicable laws including
                without limitation copyright and trademark laws, the laws of
                privacy and publicity, and applicable communications regulations
                and statutes.
                <br />
                <br />
                You represent and warrant that you will comply with all
                applicable laws and regulations, including, without limitation,
                those relating to the Internet, data, e-mail, privacy, and the
                transmission of technical data.
                <br />
                <br />
                Recomposer will not be responsible to pay you or any Third
                Parties fees in connection with billing or collecting any
                licensing fees from you. You are responsible for all applicable
                taxes and any applicable Third Party fees (including but not
                limited to credit card fees, foreign exchange fees and cross
                border fees.)
              </div>
              <span>Copyright infringement policy</span>
              <div>
                Recomposer reserves the right to terminate, in appropriate
                circumstances and at Recomposer FZE’s sole discretion, account
                holders who infringe the intellectual property rights of
                Recomposer FZE or any third party.
              </div>
              <span>Applicable Law</span>
              <div>
                By visiting this mobile application, you agree that the laws of
                the Australia, without regard to principles of conflict laws,
                will govern these terms and conditions, or any dispute of any
                sort that might come between National Recomposition Institute
                and you, or its business partners and associates.
              </div>
              <span>Copyright complaints</span>
              <div>
                If you believe that any material on the Site infringes upon any
                copyright that you own or control, you may file a notification
                of such infringement with our Designated Agent as set forth
                below:
                <br />
                <ul className="terms-list">
                  <li>Copyright</li>
                  <li>Recomposer FZE</li>
                  <li>PO Box 4422</li>
                  <li>Creative Tower Fujairah</li>
                  <li>United Arab Emirates</li>
                </ul>
                <ul className="terms-list">
                  <li>E-mail: legal@recomposer.com</li>
                </ul>
              </div>
              <span>User information sharing</span>
              <div>
                This site enables the communication and processing of
                information between users including, but not limited to,
                personal identification information, body composition data, food
                intake data, exercise data, general notes and comments (“User
                Content”). You are solely responsible for your use of such User
                Content and use it at your own risk.
                <br />
                <br />
                You agree not to input, upload, transmit, distribute, store,
                create or otherwise publish through the Site any of the
                following:
                <ol>
                  <li>
                    Any User Content that is unlawful, libellous, defamatory,
                    harassing, threatening, invasive of privacy or publicity
                    rights, abusive, inflammatory, fraudulent or otherwise
                    objectionable;
                  </li>
                  <li>
                    User Content that would constitute, encourage or provide
                    instructions for a criminal offence, violate the rights of
                    any party, or that would otherwise create liability or
                    violate any local, state, national or international law;
                  </li>
                  <li>
                    User Content that may infringe any patent, trademark, trade
                    secret, copyright or other intellectual property or contract
                    right of any party. By inputing any User Content, you
                    represent and warrant that you have the lawful right to
                    transmit, distribute and reproduce such User Content;
                  </li>
                  <li>
                    User Content that impersonates any person or entity or
                    otherwise misrepresents your affiliation with a person or
                    entity;
                  </li>
                  <li>
                    Private information of any third party, including, without
                    limitation, addresses, phone numbers, email addresses,
                    identification numbers and credit card numbers;
                  </li>
                  <li>
                    Viruses, corrupted data or other harmful, disruptive or
                    destructive files; and
                  </li>
                  <li>
                    User Content that, in the sole judgment of Recomposer, is
                    objectionable or which restricts or inhibits any other
                    person from using or enjoying the User Content or the Site,
                    or which adversely affects the availability of its resources
                    to other users.
                  </li>
                </ol>
                Recomposer takes no responsibility and assumes no liability for
                any User Content inputed, stored or uploaded by you or any third
                party, or for any loss or damage thereto, nor is Recomposer
                liable for any advice, recommendation, threat, defamation,
                slander, libel, omissions, falsehoods or profanity you may
                encounter. Your use of User Content is at your own risk. As a
                provider of User Content, Recomposer FZE is not liable for any
                statements, representations or User Content provided by its
                users in any public forum, personal home page or other media.
                Although Recomposer has no obligation to screen, edit or monitor
                any of the User Content, Recomposer reserves the right, and has
                absolute discretion, to remove, screen or edit any User Content
                inputed or stored on the Site at any time and for any reason
                without notice. You are solely responsible for creating backup
                copies of and replacing any User Content you input or store on
                the Site at your sole cost and expense. Any use of the User
                Content or other portions of the Site in violation of the
                foregoing violates these Site Terms and may result in, among
                other things, termination or suspension of your rights to use
                the Site. You acknowledge and agree that Recomposer may access,
                use or disclose any information about you or your use of this
                Site, including without limitation any User Content to comply
                with the law or any legal process; protect and defend the rights
                or property of Recomposer FZE; or to protect the safety of our
                company, employees, customers or the public. You represent and
                warrant that
                <ol>
                  <li>
                    you own and control all of the rights to the User Content
                    that you input or you otherwise have the right to input such
                    User Content to the Site;
                  </li>
                  <li>the User Content is accurate and not misleading; and</li>
                  <li>
                    Use and input of the User Content you supply does not
                    violate these Site Terms and will not violate any rights of
                    or cause injury to any person or entity.
                  </li>
                </ol>
              </div>
              <span>Registration data and account security</span>
              <div>
                In consideration of your use of the Site, you agree to:
                <ol>
                  <li>
                    maintain the security of your password and User Content;
                  </li>
                  <li>
                    notify Recomposer immediately of any unauthorised use of
                    your account or other breach of security;
                  </li>
                  <li>
                    accept all responsibility for any and all activities that
                    occur under your account, and
                  </li>
                  <li>
                    accept all risks of unauthorised access to the User Content
                    and any other information you provide.
                  </li>
                </ol>
              </div>
              <span>Indemnification</span>
              <div>
                You agree to defend, indemnify and hold harmless Recomposer, its
                subsidiaries, affiliates, licensors, employees, agents, third
                party information providers and independent contractors against
                any claims, damages, costs, liabilities and expenses (including,
                but not limited to, reasonable medical fees and attorneys’ fees)
                arising out of, or related to, any content that you or another
                user inputs, stores or otherwise transmits on or through the
                Site, your conduct, your application or interpretation of
                Content within Recomposer, your use or inability to use the
                Site, your breach or alleged breach of the Site Terms or of any
                representation or warranty contained herein, your improper or
                unauthorised use of the Recomposer Content, or your violation of
                any rights of another.
              </div>
              <span>Disclaimer</span>
              <div>
                THE SITE, INCLUDING WITHOUT LIMITATION THE RECOMPOSER MOBILE APP
                AND THE RECOMPOSER CONTENT, ARE PROVIDED “AS IS” AND RECOMPOSER
                FZE AND ITS DIRECTORS, EMPLOYEES, CONTENT PROVIDERS, AGENTS AND
                AFFILIATES EXCLUDE, TO THE FULLEST EXTENT PERMITTED BY
                APPLICABLE LAW, ANY WARRANTY, EXPRESS OR IMPLIED, INCLUDING,
                WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR PURPOSE.
                RECOMPOSER FZE WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND
                ARISING FROM THE USE OF THE SITE OR THE RECOMPOSER CONTENT, OR
                THE UNAVAILABILITY OF THE SAME, INCLUDING, BUT NOT LIMITED TO
                LOST PROFITS, AND DIRECT, INDIRECT, INCIDENTAL, PUNITIVE AND
                CONSEQUENTIAL DAMAGES. THE FUNCTIONS EMBODIED ON OR IN THE
                MATERIALS OF THIS SITE ARE NOT WARRANTED TO BE UNINTERRUPTED OR
                WITHOUT ERROR. YOU, NOT RECOMPOSER FZE, ASSUME THE ENTIRE COST
                OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION DUE TO YOUR USE
                OF THIS SITE OR THE RECOMPOSER CONTENT. WE MAKE NO WARRANTY THAT
                THE SITE OR THE RECOMPOSER CONTENT IS FREE FROM INFECTION BY
                VIRUSES OR ANYTHING ELSE THAT HAS CONTAMINATING OR DESTRUCTIVE
                PROPERTIES.
                <br />
                <br />
                Recomposer uses reasonable efforts to ensure the accuracy,
                correctness and reliability of the Recomposer Content, but we
                make no representations or warranties as to the Recomposer
                Content’s accuracy, correctness or reliability.
              </div>
              <span>Limitation of liability</span>
              <div>
                IN NO EVENT SHALL RECOMPOSER FZE, ITS DIRECTORS, MEMBERS,
                EMPLOYEES OR AGENTS BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT
                OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND,
                INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR
                LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING
                BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR
                IN ANY WAY CONNECTED WITH THE USE OF THE SITE, THE SERVICES, THE
                RECOMPOSER CONTENT OR THE MATERIALS CONTAINED IN OR ACCESSED
                THROUGH THE SITE, INCLUDING WITHOUT LIMITATION ANY DAMAGES
                CAUSED BY OR RESULTING FROM RELIANCE BY USER ON ANY INFORMATION
                OBTAINED FROM RECOMPOSER, OR THAT RESULT FROM MISTAKES,
                OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS,
                DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY
                FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF
                GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORISED
                ACCESS TO RECOMPOSER’S RECORDS, PROGRAMS OR SERVICES. IN NO
                EVENT SHALL THE AGGREGATE LIABILITY OF RECOMPOSER FZE, WHETHER
                IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER
                ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY
                OR OTHER THEORY, ARISING OUT OF OR RELATING TO THE USE OF THE
                SITE EXCEED ANY COMPENSATION YOU PAY, IF ANY, TO RECOMPOSER FOR
                ACCESS TO OR USE OF THE SITE.
              </div>
              <span>Applicable law and venue</span>
              <div>
                Any dispute relating in any way to your use of the Site or the
                Recomposer Content shall be submitted to confidential
                arbitration in the United Arab Emirates (UAE), except that, to
                the extent you have in any manner violated or threatened to
                violate Recomposer’s intellectual property rights, Recomposer
                may seek injunctive or other appropriate relief in the UAE, and
                you consent to exclusive jurisdiction and venue in such courts.
                Arbitration under this provision shall be conducted by a single
                arbitrator under the rules then prevailing of the UAE. The
                arbitrator’s award shall be binding and may be entered as a
                judgment in any court of competent jurisdiction. To the fullest
                extent permitted by applicable law, no arbitration under this
                Agreement shall be joined to an arbitration involving any other
                party subject to the Site Terms, whether through class
                arbitration proceedings or otherwise.
              </div>
              <span>Termination</span>
              <div>
                Notwithstanding any of these Site Terms, Recomposer FZE reserves
                the right, without notice and in its sole discretion, to
                terminate your account and/or to block your use of the Site.
              </div>
              <span>Cancellations</span>
              <div>
                You are able to cancel our services at any time by pressing the
                ‘deactivate’ button on the ‘client info’ or ‘profile’ page of
                any accounts you control. Deactivating an account blocks access
                to the User Content and ceases the accrual of any further fees
                for that account.
                <br />
                <br />
                You can also delete accounts under your control, and all of the
                associated User Content, by pressing the ‘delete’ button and
                confirming the deletion. If you wish to delete all backup copies
                of your data, please email your request to legal@recomposer.com.
              </div>
              <span>Miscellaneous provisions</span>
              <div>
                Any waiver of any provision of the Site Terms will be effective
                only if in writing and signed by Recomposer FZE. If any clause
                in these Site Terms is found to be unenforceable, wherever
                possible this will not affect any other clause and each will
                remain in full force and effect. Any rights not expressly
                granted herein are reserved.
              </div>
              <span>User Account Transfers</span>
              <div>
                We will honour user requests to transfer their “Client” account
                to a different “Coach” (or “Company”) account. Transfer requests
                must be made by email to us, from the Client account login
                email. “Coach” or “Company” users may delete user accounts under
                their control. Requests to transfer deleted user accounts will
                be declined. We will not transfer any “Coach” account to any
                other “Company”.
              </div>
              <span>Personal Injury</span>
              <div>
                Information presented by Recomposer is based on data input by
                users. Recomposer presents the information that users have
                programmed it to present, without discrimination or prejudice.
                We accept no responsibility for damages suffered by users who
                use or apply that information.
              </div>
              <span>Fees</span>
              <div>
                The fees for the use of our Site are based on the number of days
                that the account(s) that you control have an ‘active’ status
                (total ‘activated days’). You will be charged for each
                ‘activated day’ irrespective of the portion of the day that the
                account is in the active state. It is your responsibility to
                ‘deactivate’ the account(s) under your control to prevent
                unwanted charges. Accounts can be deactivated on the associated
                ‘profile’ or ‘client info’ page. The fees are disclosed on the
                same page that you enter your payment details, and at
                recomposer.com on the ‘Pricing’ page. All fees are
                non-refundable.
                <br />
                <br />
                Invoices are Should your payment be rejected, we reserve the
                right to suspend your User Account until the outstanding payment
                is settled. We will send you an email requesting that you settle
                all outstanding payments. If such amounts are not settled within
                the requested timelines Recomposer may terminate your App
                Agreement and take any legal actions that it deems necessary in
                order to recover the outstanding payment including seeking
                compensation for any damages you caused.
              </div>
              <span>Payments</span>
              <div>
                Invoices for fees may be charged weekly or monthly, depending on
                your account type and agreement with us. Payment may be made by
                the credit or debit card you enter into the payment form on the
                Site. An email will be sent to your account email address
                notifying you of a new invoice 7 days prior to your card being
                charged. Detailed invoices are available on your Profile page by
                selecting “invoices”. If you believe an error has been made with
                an invoice you should contact us via email at
                accounts@recomposer.com.
              </div>
              <span>Refunds</span>
              <div>
                All fees are non-refundable except at our discretion or
                otherwise required by law or the terms of this Agreement.
              </div>
              <span>Changes to site terms</span>
              <div>
                Recomposer FZE reserves the right to change any of the terms and
                conditions contained in the Site Terms or any policy or
                guideline of the Site, at any time and in its sole discretion.
                When we make changes, we will revise the “last updated” date at
                the top of the Site Terms. Any changes will be effective
                immediately upon inputing on the Site. Your continued use of the
                Site following the inputing of changes will constitute your
                acceptance of such changes. We encourage you to review the Site
                Terms whenever you visit one of our websites.
              </div>
              <span>Questions and contact information</span>
              <div>
                Please contact us at{" "}
                <a className="terms-link" href="mailto:legal@recomposer.com">
                  legal@recomposer.com
                </a>{" "}
                or via the contact page at recomposer.com if you have any
                questions or comments about the Site or Site Terms.
                <br />
                <br />© 2020-2021 Recomposer FZE. All rights reserved. Terms and
                Conditions of Use.
              </div>
            </div>
          </Widget>
        </div>
      </div>
    </div>
  );
};

TermsPage.propTypes = {};

export default TermsPage;
