import Widget from "../../../../../components/widget/Widget";
import React, { useEffect, useMemo } from "react";
import CreditCardForm from "../CreditCardForm";
import MonthlyChargesInfo from "../MonthlyChargesInfo";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../../components/Spinner";
import "./PaymentSideBar.scss";
import { useToastr } from "../../../../../hooks/useToastr";
import { TOASTR_TYPE } from "../../../../../constants/toastr";
import DeletePaymentAccount from "../DeletePaymentAccount";
import PayCurrentDue from "../PayCurrentDue";
import {
  clearError,
  loadPaymentAccount,
  verifyPaymentAccount,
} from "../../../../../redux/companiesSlice";
import { useCallDebouncedCb } from "../../../../../hooks/useCallDebouncedCb";
import {
  PAYMENT_ACCOUNT_REQUEST_DELAY,
  COMPANY_IDS_WITH_ACCESS_TO_PAY_NOW_FEATURE,
} from "../../../../../constants/payment";

const PaymentSideBar = () => {
  const dispatch = useDispatch();
  const last4 = useSelector((state) => state.companies.last4);
  const cardHolderName = useSelector((state) => state.companies.cardHolderName);
  const isPaymentRequestSuccessful = useSelector(
    (state) => state.companies.isPaymentRequestSuccessful
  );
  const currentCompany = useSelector((state) => state.companies.currentCompany);
  const error = useSelector((state) => state.companies.error);
  const isTaskFinished = useSelector((state) => state.companies.isTaskFinished);
  const debt = useSelector((state) => state.companies.debt);

  useCallDebouncedCb(
    (isTaskFinished, companyId) => {
      if (!isTaskFinished)
        dispatch(loadPaymentAccount({ id: companyId })).then((e) => {
          if (e.payload.last4)
            dispatch(verifyPaymentAccount({ id: companyId }));
        });
    },
    [isTaskFinished, currentCompany.id],
    PAYMENT_ACCOUNT_REQUEST_DELAY,
    [isPaymentRequestSuccessful, isTaskFinished]
  );

  //checkout frames are in a race condition with react state
  const isHidingCreditCardForm = useMemo(
    () =>
      !(
        isPaymentRequestSuccessful &&
        isTaskFinished &&
        (last4 === null || cardHolderName === null)
      ),
    [isPaymentRequestSuccessful, isTaskFinished, last4, cardHolderName]
  );

  const isShowPayNowContent = useMemo(() => {
    return debt > 0;
  }, [debt]);

  useToastr({
    messages: error,
    deps: error,
    type: TOASTR_TYPE.ERROR,
    cb: () => dispatch(clearError()),
  });

  return (
    <div className={"widget-wrapper"}>
      <Widget>
        <Widget.Header>
          <Widget.Title>payment details</Widget.Title>
        </Widget.Header>
        <Widget.Body>
          {isPaymentRequestSuccessful && isTaskFinished && (
            <div className={"payment-description__wrapper"}>
              {isHidingCreditCardForm ? (
                <span className={"payment-description"}>
                  Your recomposer invoices will be emailed to you on the
                  <span>{currentCompany.billing_day}th</span>
                  of each month and will be charged to your saved card 7 days
                  later.
                </span>
              ) : (
                <span className={"payment-description"}>
                  You have no saved credit card for payments. Your invoices are
                  currently emailed to
                  <span>{currentCompany.company_admin?.email}</span>
                  for payment by bank transfer. Enter a card below to switch to
                  automated payments:
                </span>
              )}
            </div>
          )}
          <CreditCardForm isHidden={isHidingCreditCardForm} />
          {isPaymentRequestSuccessful && isTaskFinished ? (
            isHidingCreditCardForm && (
              <>
                {isShowPayNowContent && <PayCurrentDue />}
                <DeletePaymentAccount />
              </>
            )
          ) : (
            <div className={"spinner-wrapper"}>
              <Spinner />
            </div>
          )}
        </Widget.Body>
      </Widget>
      <Widget>
        <Widget.Header>
          <Widget.Title>monthly charges</Widget.Title>
        </Widget.Header>
        <Widget.Body>
          <MonthlyChargesInfo />
        </Widget.Body>
      </Widget>
    </div>
  );
};

export default PaymentSideBar;
