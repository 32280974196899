import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { PUBLIC_STRIPE_SECRET_KEY } from "../../../../../env/env";
import StripePaymentSidebar from "./StripePaymentSidebar";

const getPublicKey = async () => {
  const stripePromise = await loadStripe(PUBLIC_STRIPE_SECRET_KEY);
  return stripePromise;
}

const StripePaymentSidebarContainer = () => {
  return (
    <Elements stripe={getPublicKey()}>
      <StripePaymentSidebar />
    </Elements>
  );
};

export default StripePaymentSidebarContainer;
